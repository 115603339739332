<template>
    <v-dialog fullscreen v-model="openTemplateEmailDialog">
        <v-card>
            <v-toolbar tile flat dark  :style="'background-image:url('+publicPath+'img/blue-background.jpg); background-size:cover;'">
                <v-toolbar-title>{{$t('dialogTemplateTitle')}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn outlined depressed small class="mr-2" @click="$emit('closeTemplateDialog')">{{$t('cancelLabel')}}</v-btn>
            </v-toolbar>
            <v-card-text>
                <TemplateCreateEmail :templateId="templateId" :templateDuplicatedId="templateDuplicatedId"  @refreshTemplates="confirm"/>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import TemplateCreateEmail from '@/modules/templates/views/TemplateCreateEmail'
export default {
    name:"dialogTemplateEmail",
    props:["openTemplateEmailDialog", "templateId", "templateDuplicatedId"],
    components: {TemplateCreateEmail},
    data() {
        return {
            publicPath: process.env.BASE_URL,
        }
    },
    methods: {
        confirm(){
            this.$emit('recordTemplateDialog')
        }
    },
}
</script>
<style lang="scss">
    
</style>