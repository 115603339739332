<template>
  <div class="col col-12 pa-0 input-form">
    <span v-if="field.title" class="label"><b>{{field.title_not_to_trad ? field.title : $t(field.title)}}</b><span v-if="field.props && field.props.required" style="color:red">&nbsp;*</span></span>
    <v-text-field
      :label="field.title ? $t(field.title) : ''"
      v-model="modelSearch"
      v-bind="field.props"
      @input="launchSearch"
      :rules=[validate]
      ></v-text-field>
  </div>
</template>

<script>
import validate from '../functions/validations'

export default {
  props: ["model", "fieldName", "field"],
  mixins: [validate],
  data() {
    return {
      modelSearch : "",
      timeOut: null
    };
  },
  destroyed() {
    this.modelSearch = ""
  },
  methods: {
    launchSearch(){
      this.timeOut = setTimeout(() => {
          //this.model[this.fieldName] = this.modelSearch
          this.$set(this.model, this.fieldName, this.modelSearch)
      }, 750); 
    }
  },
};
</script>

<style lang="scss">
.input-form {
  .v-input__slot {
    background-color: var(--v-lightgrey-base) !important;
  }
}
</style>