<template>
    <div class="col col-12 pa-0 v-file-input">
        <v-row>
            <v-col cols="9" xl="8" v-if="schema && references" class="segmentation pa-3">
                <v-row class="pb-3">
                    <v-col cols="4">
                        <v-btn dense depressed color="primary" @click="openSegmentDialogNew()">{{$t('useExistingSegmentation')}}</v-btn>
                    </v-col>
                </v-row>
                <div v-for="(or, orIndex) in data" :key="'or_'+orIndex" class="or">
                    <v-chip small class="lightgrey operatorChip">
                        <span class="text-uppercase" v-if="orIndex == 0">{{$t('if')}}</span>
                        <span class="text-uppercase" v-else>{{$t('or')}}</span>
                    </v-chip>
                    <div class="divider">
                        <v-divider class="my-3"></v-divider>
                    </div>
                    <div v-for="(and, andIndex) in or" :key="'or_'+orIndex+'and_'+andIndex" v-add-class-hover="'active'" class="andDiv my-3 input-form">
                        <v-btn small text class="square removeBtn" @click="removeAnd(orIndex, andIndex)">
                            <v-icon small>
                                $minus_circle_l
                            </v-icon>
                        </v-btn>
                        <v-autocomplete
                            solo flat
                            hide-details
                            dense
                            :label="$t('field')"
                            @change="resetValues(orIndex, andIndex)"
                            v-model="data[orIndex][andIndex].field"
                            :items="firstAutocomplete"
                            item-value="value"
                            item-text="name"
                            class="field mx-3"
                            :no-data-text="$t('no-data-text')"
                        >
                        </v-autocomplete>

                        <v-select
                            solo flat
                            hide-details
                            dense
                            :label="$t('operator')"
                            @change="changeOperator(orIndex, andIndex)"
                            :disabled="data[orIndex][andIndex].field == ''"
                            v-model="data[orIndex][andIndex].operator"
                            :items="schema[data[orIndex][andIndex].field] ? schema[data[orIndex][andIndex].field].operators : []"
                            item-text="value"
                            item-value="value"
                            return-object
                            class="field mx-3"
                            :no-data-text="$t('no-data-text')"
                        >
                            <template slot="selection" slot-scope="data">
                                {{ $t(data.item.operatorLabel ? data.item.operatorLabel : data.item.value).toLowerCase() }}
                            </template>
                            <template slot="item" slot-scope="data">
                                {{ $t(data.item.operatorLabel ? data.item.operatorLabel : data.item.value).toLowerCase() }}
                            </template>
                        </v-select>

                        <v-text-field
                            :label="$t('value')"
                            solo flat
                            class="field mx-3"
                            hide-details
                            dense
                            disabled
                            v-if="data[orIndex][andIndex].field == '' || (data[orIndex][andIndex].operator == '' || Object.keys(data[orIndex][andIndex].operator).length == 0)"
                        ></v-text-field>
                        <div v-else class="field mx-3">
                            <v-select
                                solo flat
                                hide-details
                                dense
                                :label="$t(data[orIndex][andIndex].operator.label)"
                                @change="getDetailledStats"
                                :disabled="data[orIndex][andIndex].operator == ''"
                                v-model="data[orIndex][andIndex].value"
                                :multiple="references[data[orIndex][andIndex].operator.ref].multiple"
                                v-if="data[orIndex][andIndex].field != '' && data[orIndex][andIndex].operator != '' && references[data[orIndex][andIndex].operator.ref] && references[data[orIndex][andIndex].operator.ref].type == 'select'"
                                :items="references[data[orIndex][andIndex].operator.ref].content"
                                item-text="text"
                                item-value="value"
                                :no-data-text="$t('no-data-text')"
                            >
                            </v-select>
                            <v-autocomplete
                                solo flat
                                hide-details
                                dense
                                :label="$t(data[orIndex][andIndex].operator.label)"
                                :disabled="data[orIndex][andIndex].operator == ''"
                                v-model="data[orIndex][andIndex].value"
                                :multiple="references[data[orIndex][andIndex].operator.ref].multiple"
                                v-if="data[orIndex][andIndex].field != '' && data[orIndex][andIndex].operator != '' && references[data[orIndex][andIndex].operator.ref] && references[data[orIndex][andIndex].operator.ref].type == 'autocomplete'"
                                :items="references[data[orIndex][andIndex].operator.ref].content"
                                item-text="text"
                                :item-value="data[orIndex][andIndex].category.includes('contactItems') ? 'text' : 'value'"
                                :no-data-text="$t('no-data-text')"
                            >
                            </v-autocomplete>
                            <v-combobox
                                solo flat
                                hide-details
                                dense
                                :label="$t(data[orIndex][andIndex].operator.label)"
                                @focus="loadComboboxEntries(data[orIndex][andIndex].value, data[orIndex][andIndex].field)"
                                @update:search-input="loadComboboxEntries($event, data[orIndex][andIndex].field)"
                                :disabled="data[orIndex][andIndex].operator == ''"
                                v-model="data[orIndex][andIndex].value"
                                :multiple="references[data[orIndex][andIndex].operator.ref].multiple"
                                v-if="data[orIndex][andIndex].field != '' && data[orIndex][andIndex].operator != '' && references[data[orIndex][andIndex].operator.ref] && references[data[orIndex][andIndex].operator.ref].type == 'combobox'"
                                :items="references[data[orIndex][andIndex].operator.ref].content"
                                item-text="text"
                                :item-value="data[orIndex][andIndex].category.includes('contactItems') ? 'text' : 'value'"
                                :no-data-text="$t('no-data-text')"
                            >
                            </v-combobox>
                            <v-text-field
                                solo flat
                                hide-details
                                dense
                                type="number"
                                min="0"
                                @keydown="validateNumber"
                                :label="$t(data[orIndex][andIndex].operator.label)"
                                required
                                :disabled="data[orIndex][andIndex].operator == ''"
                                v-model="data[orIndex][andIndex].value"
                                v-if="data[orIndex][andIndex].field != '' && data[orIndex][andIndex].operator != '' && references[data[orIndex][andIndex].operator.ref] && references[data[orIndex][andIndex].operator.ref].type == 'number'"
                            >
                            </v-text-field>
                            <v-text-field
                                solo flat
                                hide-details
                                dense
                                type="text"
                                :label="$t(data[orIndex][andIndex].operator.label)"
                                required
                                :disabled="data[orIndex][andIndex].operator == ''"
                                v-model="data[orIndex][andIndex].value"
                                v-if="data[orIndex][andIndex].field != '' && data[orIndex][andIndex].operator != '' && references[data[orIndex][andIndex].operator.ref] && references[data[orIndex][andIndex].operator.ref].type == 'text'"
                            ></v-text-field>
                            <v-menu
                            v-model="data[orIndex][andIndex].menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                            v-if="data[orIndex][andIndex].field != '' && data[orIndex][andIndex].operator != '' && references[data[orIndex][andIndex].operator.ref] && references[data[orIndex][andIndex].operator.ref].type == 'date'">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        solo flat
                                        hide-details
                                        dense
                                        @input="getDetailledStats"
                                        :label="$t(data[orIndex][andIndex].operator.label)"
                                        required
                                        readonly
                                        :disabled="data[orIndex][andIndex].operator == ''"
                                        v-model="data[orIndex][andIndex].value"
                                        append-icon="$calendar_alt_l"
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                    </v-text-field>
                                </template>
                                <v-date-picker v-model="data[orIndex][andIndex].value" no-title :first-day-of-week="1" :locale="$i18n.locale" @input="data[orIndex][andIndex].menu = false"></v-date-picker>
                            </v-menu>
                        </div>

                        <div v-if="/rdv|demand|Items/.test(data[orIndex][andIndex].category) && schema[data[orIndex][andIndex].field] && schema[data[orIndex][andIndex].field].type === 'date'">
                            <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                        <v-checkbox v-model="data[orIndex][andIndex].strict" @change="strictIsChanged(orIndex, andIndex)" hide-details class="strictCheckbox mt-0 pt-0"></v-checkbox>
                                    </span>
                                </template>
                                <span>{{ $t('segmentStrictCheckboxText') }}</span>
                            </v-tooltip>
                        </div>
                        <div v-else class="blank"></div>
                        
                        <v-chip v-if="andIndex < (or.length - 1)" small class="lightgrey operatorChip text-uppercase">
                            {{$t('and')}}
                        </v-chip>
                        <div v-else class="blank"></div>
                    </div>
                    <div class="addButton">
                        <div class="blank"></div>
                        <div class="field mx-3">
                            <v-btn depressed class="square" color="primary" @click="addAnd(orIndex)">
                                <v-icon>$plus_l</v-icon>
                            </v-btn>
                        </div>
                        <div class="field mx-3"></div>
                        <div class="blank"></div>
                        <div class="field mx-3 text-right">
                            <v-btn depressed text color="primary" @click="duplicate(orIndex)">
                                <v-icon small class="mr-2" >$copy_l</v-icon> {{ $t('duplicate') }}
                            </v-btn>
                        </div>
                        <div class="blank"></div>
                    </div>
                </div>
                <div class="or">
                    <v-chip small class="lightgrey operatorChip">
                        <span class="text-uppercase" v-if="data.length > 0">{{$t('or')}}</span>
                        <span class="text-uppercase" v-else>{{$t('if')}}</span>
                    </v-chip>
                    <div class="divider">
                        <v-divider class="my-3"></v-divider>
                    </div>
                    <div class="addButton my-3">
                        <v-btn depressed class="square  mx-3" color="primary" @click="addOr">
                            <v-icon>$plus_l</v-icon>
                        </v-btn>
                    </div>
                </div>
            </v-col>
            <v-col cols="3" class="pa-3 px-6">
                <v-card color="primary" rounded="lg">
                    <div v-if="realTime && Object.keys(realTime).length > 0" class="white--text realTimeNumbers">
                        <span class="number">{{realTime.number}}</span>
                        <span class="text-uppercase"><b>{{$t('contacts')}}</b>/{{totalNumber}}</span>
                    </div>
                    <div class="white--text realTimeNumbers" v-else>
                        {{$t('noRulesDefined')}}
                    </div>
                </v-card>
                <div v-if="realTime && Object.keys(realTime).length > 0">
                    <div v-for="(value, index) in realTime.detail" :key="'detail_'+index" class="mt-4">
                        <div class="headingDetail">
                            <span class="primary--text">{{$t(value.label)}}</span>
                            <span class="primary--text">{{value.value}}/{{realTime.number}}</span>
                        </div>
                        <v-progress-linear :value="getValue(value.value, realTime.number)"></v-progress-linear>
                    </div>
                    <div style="height: 300px;" key="detail_entities" class="mt-4" v-if="realTime.aggregations.entities.length != 0">
                        <div class="headingDetail">
                            <span class="primary--text">{{$t('repartitionByEntity')}} :</span>
                        </div>
                        <div style="height:80%; display:flex; align-items:center; justify-content:space-between;">
                            <DonutComponent :key="donutKey" :dataSet="graphData" :legend="false" class="px-4" style="height:100%; width:50%;"/>
                            <div class="legend mr-6">
                                <div v-for="(legend, index) in labels('entities')" :key="'legend_' + index">
                                    <span :style="'width: 20px; height:10px; display:inline-block; background-color:' + backgroundRefs[index]"></span> {{legend}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-col> 
        </v-row>

        <LibraryDialogComponent 
            v-if="formLib" 
            :form="formLib" 
            :libType="libType" 
            :pickerForm="true"
            @cancel="formLib = null;" 
            @selectModel="selectModel"
            :showLibraryDialog="showLibraryDialog" 
            @hideLibraryDialog="showLibraryDialog = false;"
            :isFromLibraryPage="isFromLibraryPage" />
    </div>
</template>

<script>
import validate from "../functions/validations";
import GenericDataService from '@/services/GenericDataService';
import DonutComponent from '@/components/graphs/DonutComponent'

export default {
    components: { },
    props: ["model", "fieldName", "field", "isFromLibraryPage"],
    components:{ 
        DonutComponent,
        LibraryDialogComponent: () => import("@/components/dialogs/LibraryDialogComponent"),
        LibraryPreviewComponent: () => import("@/components/LibraryPreviewComponent"),
    },
    mixins: [validate],
    data() {
        return {
            segmentSegment: '0', 
            formSegments: [], 
            data: [],
            default_pool: [],
            schema: {},
            schemaTmp: {},
            references: {},
            firstAutocomplete: [],
            realTime: {},
            backgroundRefs: ["#FFEBCD","#0000FF","#8A2BE2","#A52A2A","#DEB887","#5F9EA0","#7FFF00","#D2691E","#FF7F50","#6495ED","#FFF8DC","#DC143C","#00FFFF","#00008B","#008B8B","#B8860B","#A9A9A9","#006400","#BDB76B","#8B008B","#556B2F","#FF8C00","#9932CC","#8B0000","#E9967A","#8FBC8F","#483D8B","#2F4F4F","#00CED1","#9400D3","#FF1493","#00BFFF","#696969","#1E90FF","#B22222","#FFFAF0","#228B22","#FF00FF","#DCDCDC","#F8F8FF","#FFD700","#DAA520","#ADFF2F","#F0FFF0","#FF69B4","#CD5C5C","#4B0082","#FFFFF0","#F0E68C","#E6E6FA","#FFF0F5","#7CFC00","#FFFACD","#ADD8E6","#F08080","#E0FFFF","#FAFAD2","#90EE90","#D3D3D3","#FFB6C1","#FFA07A","#20B2AA","#87CEFA","#778899","#B0C4DE","#FFFFE0","#00FF00","#32CD32","#FAF0E6","#FF00FF","#66CDAA","#0000CD","#BA55D3","#9370DB","#3CB371","#7B68EE","#00FA9A","#48D1CC","#C71585","#191970","#F5FFFA","#FFE4E1","#FFE4B5","#FFDEAD","#000080","#FDF5E6","#808000","#6B8E23","#FFA500","#FF4500","#DA70D6","#EEE8AA","#98FB98","#AFEEEE","#DB7093","#FFEFD5","#FFDAB9","#CD853F","#FFC0CB","#DDA0DD","#B0E0E6","#FF0000","#BC8F8F","#4169E1","#8B4513","#FA8072","#F4A460","#2E8B57","#FFF5EE","#A0522D","#C0C0C0","#87CEEB","#6A5ACD","#708090","#FFFAFA","#00FF7F","#4682B4","#D2B48C","#008080","#D8BFD8","#FF6347","#40E0D0","#EE82EE","#F5DEB3","#FFFFFF","#F5F5F5","#FFFF00","#9ACD32","#F0F8FF","#FAEBD7","#00FFFF","#7FFFD4","#F0FFFF","#F5F5DC","#FFE4C4","#000000"],
            donutKey:0,
            totalNumber: 0,
            entity_id: null,
            forNoInitAtFirst: 0, // Dono ==> Rajouté ça car sinon à l'ouverture lors d'un edit liste de contacts ça appel 2 fois et si le premier répond après le deuxième alors les résultats sont faussés !
            showLibraryDialog: false,
            libType: "SEGMENT",
            formLib: null,
        }
    },
    created() {
        this.segmentSegment = this.model['id'] ? this.model['id'] : '0';
        this.data = this.model[this.fieldName] ? this.model[this.fieldName] : [];
        this.loadSegment();
    },
    watch: {
        data: {
            handler(val) {
                this.model[this.fieldName] = val;
                if(this.forNoInitAtFirst === 0)
                    this.forNoInitAtFirst ++;
                else
                    this.getDetailledStats(false)
            },
            deep:true
        },
        graphData(val){
            this.donutKey++
        }
    },
    computed: {
        graphData() {
            let graphData = {}
            if(this.realTime && this.realTime.aggregations && this.realTime.aggregations['entities']){
                graphData.datasets = [];
                let dataset = {};
                dataset.label = "";
                dataset.data = Object.values(this.realTime.aggregations['entities']).map(function (obj) { return obj.value });
                dataset.backgroundColor =  this.backgroundRefs;
                graphData.datasets.push(dataset);
                graphData.labels = this.labels('entities');
            }
            
            return graphData;
        },
    },
    methods: {
        changeLabel() {
            let segment = this.formSegments.find((e) => e.value == this.segmentSegment)
        },
        loadSegment(forceSegmentUrl = false) {
            if(this.segmentSegment !== '0'){
                let url = "";
                if(!forceSegmentUrl && this.field.contactlist)
                    url = "/contactlist/" + this.segmentSegment + "/get";
                else
                    url = "/segment/" + this.segmentSegment + "/get";
                GenericDataService.getData(url).then((response) => {
                    if(!forceSegmentUrl && this.field.contactlist) {
                        this.data = response.data.data.config;
                        this.default_pool = response.data.data.default_pool;
                        this.entity_id = response.data.data.entity_id.id;
                    } else
                        this.data = response.data.data.data;
                    this.segmentSegment = response.data.data.id
                    this.initSchema();
                })
            } else {
                this.initSchema();
            }    
            this.initSelects();
            this.openSegmentDialog = false;
            GenericDataService.postData("/segment/try").then((response) => {
                this.totalNumber = response.data.data.totalNumber;
            });
        },
        changeOperator : function(orIndex, andIndex){
            if(this.schema[this.data[orIndex][andIndex].field].type === 'date') {
                let value = this.data[orIndex][andIndex].value;
                let operator = this.data[orIndex][andIndex].operator;

                if((operator.ref == "dateField" && !value.includes("-")) || (operator.ref == "numberField" && value.includes("-")))
                    this.data[orIndex][andIndex].value = "";
            }
            if((this.data[orIndex][andIndex].value != '' && this.data[orIndex][andIndex].value.length > 0) || ['empty', 'notEmpty', 'valid', 'notValid', 'true', 'false', 'dateBeforeToday', 'dateAfterToday', 'dateEqualsToday'].includes(this.data[orIndex][andIndex].operator.value)){
                this.changeLabel()
            }
        },
        resetValues : function(orIndex, andIndex){
            for(let cat in this.schemaTmp) {
                if(this.schemaTmp[cat].hasOwnProperty(this.data[orIndex][andIndex].field)) {
                    this.data[orIndex][andIndex].category = cat;
                    break;
                }
            }

            this.data[orIndex][andIndex].operator = {};
            this.data[orIndex][andIndex].value = "";
            this.data[orIndex][andIndex].strict = false;
            this.changeLabel();

            for(let index in this.data[orIndex]) {
                if(this.data[orIndex][index].category == this.data[orIndex][andIndex].category && this.schema[this.data[orIndex][index].field] && this.schema[this.data[orIndex][index].field].type === 'date') {
                    this.data[orIndex][andIndex].strict = this.data[orIndex][index].strict;
                }
            }
        },
        validateNumber(e) {
            if(['-', 'e', 'E', 'Dead', ',', '+', '.'].includes(e.key))
                e.preventDefault();
        },
        getDetailledStats: function(changeLabelParam = true){
            let dataToSend =  this._.cloneDeep(this.data)
            let filteredArray = [];
            dataToSend.forEach((orArray) => {
                let filteredOr = orArray.filter((e) => (e.value && e.value != "" && e.value.length != 0) || ['empty', 'notEmpty', 'valid', 'notValid', 'true', 'false', 'dateBeforeToday', 'dateAfterToday', 'dateEqualsToday'].includes(e.operator.value))
                if(filteredOr.length > 0){
                    filteredArray.push(filteredOr)
                }
            });
            if(filteredArray.length) {
                if(this.field.contactlist){
                    let pool = this._.cloneDeep(this.default_pool);
                    pool.push(filteredArray);
                    GenericDataService.postData("/segment/try?postDataIsPool=true&aggregations[]=entities", pool).then((response) => {
                        this.realTime = response.data.data;
                    });
                } else {
                    GenericDataService.postData("/segment/try?aggregations[]=entities", filteredArray).then((response) => {
                        this.realTime = response.data.data;
                    });
                }
            } else {
                this.realTime = {};
            }
            if(changeLabelParam) {
                this.changeLabel()
            }
        },
        initSelects: function() {
            GenericDataService.getData("/segment/_selectList").then((response) => {
                this.formSegments = [].concat.apply([{'value': '0', 'text': this.$t('newCustomSegment')}], response.data.data);
            });
        },
        async loadComboboxEntries(value, field) {
            let url = this.references['comboboxField'].url.replace('{{field}}', field).replace('{{value}}', value ? value : '');
            if(this.entity_id)
                url += "&entity_id=" + this.entity_id;
            await GenericDataService.getData(url).then((response) => {
                this.references['comboboxField'].content = response.data.data;
            });
        },
        initSchema: function(){
            GenericDataService.getData("/segment/getSchema").then( async (response) => {
                let schemaTmp = response.data.data.schema;
                let references = response.data.data.references;
                for (var property in references) {
                    if(references[property].url && references[property].url !== "" && references[property].type !== "combobox") {
                        await GenericDataService.getData(references[property].url).then((response) => {
                            references[property].content = response.data.data;
                        });
                    }
                    if(references[property].to_trad && references[property].content) {
                        for (var content in references[property].content) {
                            references[property].content[content].text = this.$t(references[property].content[content].text);
                        }
                    }
                }

                // Permet d'afficher les OptGroups sur le premier autocomplete
                this.firstAutocomplete = [];
                this.schema = {};
                for (var groupName in schemaTmp) {
                    this.firstAutocomplete.push({ header: this.$t('schema_' + groupName) });
                    for (var value in schemaTmp[groupName]) {
                        let name = value.includes('customUser') ? ('Perso : ' + value.split('.')[value.split('.').length - 1]) : this.$t('schema_' + groupName + '_' + value);
                        this.firstAutocomplete.push({ name: name, value: value, group: groupName });

                        for(var operator in schemaTmp[groupName][value]) {
                            if(schemaTmp[groupName][value][operator]['header'])
                                schemaTmp[groupName][value][operator]['header'] = this.$t(schemaTmp[groupName][value][operator]['header']);
                        }
                    }
                    this.schema = Object.assign(this.schema, schemaTmp[groupName]);
                }

                this.schemaTmp = schemaTmp;
                this.references = references;
            });
        },
        getValue: function(number, total){
            return number === 0 ? 0 : Math.round((number * 100)/ total);
        },
        addOr: function(){
            let item = { field: "", operator: "", value: "", menu: 0, strict: false };
            this.data.push([item]);
            this.changeLabel();
        },
        addAnd: function(orIndex){
            let item = { field: "", operator: "", value: "", menu: 0, strict: false };
            this.data[orIndex].push(item);
            this.changeLabel();
        },
        removeAnd: function(orIndex, andIndex){
            let orElement = this.data[orIndex];
            orElement.splice(andIndex, 1);
            if(orElement.length == 0) {
                this.data.splice(orIndex, 1);
            }
            this.changeLabel();
        },
        labels(type) {
            return Object.values(this.realTime.aggregations[type]).map(function (obj) { return obj.to_trad ? this.$t(obj.label) : obj.label; }, this);
        },
        duplicate(orIndex) {
            let duplicateData = this._.cloneDeep(this.data[orIndex]);
            this.data.push(duplicateData);
        },
        openSegmentDialogNew() {
            GenericDataService.getData("/library/getFilters?libType=" + this.libType).then((response) => {
                this.formLib = response.data.data;
                this.showLibraryDialog = true;
            });
        },
        selectModel(model){
            this.segmentSegment = model.id;
            this.loadSegment(true);
        },
        strictIsChanged(orIndex, andIndex) {
            for(let index in this.data[orIndex]) {
                if(this.data[orIndex][index].category == this.data[orIndex][andIndex].category && this.schema[this.data[orIndex][index].field].type === 'date') {
                    this.data[orIndex][index].strict = this.data[orIndex][andIndex].strict;
                }
            }
        }
    },
};
</script>

<style lang="scss">
        .segmentation {
            border-right:1px solid var(--v-mediumgrey-base) !important;
        }
        .or {
            position:relative;

        }
        .field {
            max-width:28%;
            min-width:28%;
        }
        .divider {
            position:absolute;
            top:0;
            width:100%;
            z-index:0;
            padding: 0 45px;
        }
        .operatorChip {
            border:1px solid var(--v-mediumgrey-base) !important;
            z-index:1;
            width:45px;
            display:flex;
            justify-content: center;
        }
        .blank {
            width:45px;
        }
        .removeBtn {
            min-width:45px !important;
        }
        .addButton {
            //margin-left:45px;
            display:flex;
            justify-content:space-between;
            align-items: center;
        }
        .andDiv {
            display:flex;
            justify-content:space-between;
            align-items: center;
            .removeBtn {
                opacity:0;
            }
            &.active {
                .removeBtn {
                    opacity:1;
                }
            }
        }
        .realTimeNumbers {
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items:center;
            padding:20px;
            .number {
                font-size:55px;
                line-height:55px;
                font-weight:bold;
            }
        }
        .headingDetail {
            display:flex;
            justify-content: space-between;
            align-items: center;
        }
        .strictCheckbox {
            max-width: 45px;
            min-width: 45px;
            .v-input__slot {
                background-color: #FFF !important;
            }
        }
</style>