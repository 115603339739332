<template>
<div class="lpIframe" style="height:100%;">
    
    <v-container fluid style="height:100%;">
        <v-row  style="height:100%;">
            <v-col cols="3" class="white pa-0" style="height:100%; border-left:1px solid rgba(0,0,0,0.1)" ref="colMenu">
                
                <v-list class="pa-0">
                    <v-list-item-group v-model="model">
                        <v-list-item @click="colorDrawer = true" class="listItemEditor darkgrey--text">
                            <div class="titleDrawer" style="text-transform:uppercase; font-size:20px;">
                                {{$t("colors")}}
                                <v-icon color='darkgrey'>
                                    $next
                                </v-icon>
                            </div>
                        </v-list-item>
                        <v-list-item @click="fontsDrawer = true" class="listItemEditor darkgrey--text">
                            <div class="titleDrawer" style="text-transform:uppercase; font-size:20px;">
                                {{$t("fonts")}}
                                <v-icon color='darkgrey'>
                                    $next
                                </v-icon>
                            </div>
                        </v-list-item>
                        <v-list-item @click="btnsDrawer = true" class="listItemEditor darkgrey--text">
                            <div class="titleDrawer" style="text-transform:uppercase; font-size:20px;">
                                {{$t("btns")}}
                                <v-icon color='darkgrey'>
                                    $next
                                </v-icon>
                            </div>
                        </v-list-item>
                        <v-list-item @click="faviconDrawer = true" class="listItemEditor darkgrey--text">
                            <div class="titleDrawer" style="text-transform:uppercase; font-size:20px;">
                                {{$t("favicon")}}
                                <v-icon color='darkgrey'>
                                    $next
                                </v-icon>
                            </div>
                        </v-list-item>
                        <v-list-item
                        v-for="(hook, i) in currentLanding.hooks"
                         @click="hook.drawer = true"
                        :key="i"
                        class="listItemEditor darkgrey--text"
                        >
                            <div class="titleDrawer" style="text-transform:uppercase; font-size:20px;">
                                {{$t(i)}}
                                <v-icon color='darkgrey'>
                                    $next
                                </v-icon>
                            </div>
                        </v-list-item>
                        
                    </v-list-item-group>
                </v-list>
                <!-- colors -->
                <v-navigation-drawer
                :width="drawerWidth+'px'"
                v-model="colorDrawer"
                temporary
                stateless
                absolute
                hide-overlay
                >
                    <v-toolbar tile flat dark  class="primary">
                        <v-toolbar-title style="display:flex; justify-content: space-between; width:100%;">{{$t('colorDrawer')}} <v-btn @click.stop="colorDrawer = false" text color="white" class="square"><v-icon >$close</v-icon></v-btn></v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <div v-for='(colors, index) in css.colors' :key='index' class="mb-3">
                             <colorPicker @change="refreshFrame" v-model="css.colors[index]" :label="$t(index)" />
                        </div>
                    </v-card-text>
                    
                </v-navigation-drawer>
                <!-- colors -->
                <v-navigation-drawer
                :width="drawerWidth+'px'"
                v-model="fontsDrawer"
                temporary
                stateless
                absolute
                hide-overlay
                >
                    <v-toolbar tile flat dark  class="primary">
                        <v-toolbar-title style="display:flex; justify-content: space-between; width:100%;">{{$t('fontDrawer')}} <v-btn @click.stop="fontsDrawer = false" text color="white" class="square"><v-icon >$close</v-icon></v-btn></v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                    <div v-for="(inputFont, fontName) in css.fonts" :key="fontName" class="mb-4" style="width:49%;">
                        <p class="mb-1">{{$t(fontName+'FontLabel')}}</p>
                        <vFileInputDirect @change="refreshFrame" :field="{props:{meta:{brand:[], type:['fonts']}, accept:'.woff, .woff2, .ttf'}}" :model="css.fonts" :fieldName="fontName"/>
                    </div>
                    </v-card-text>
                </v-navigation-drawer>
                <!-- colors -->
                <v-navigation-drawer
                :width="drawerWidth+'px'"
                v-model="btnsDrawer"
                temporary
                stateless
                absolute
                hide-overlay
                >
                    <v-toolbar tile flat dark class="primary">
                        <v-toolbar-title style="display:flex; justify-content: space-between; width:100%;">{{$t('btnsDrawer')}} <v-btn @click.stop="btnsDrawer = false" text color="white" class="square"><v-icon >$close</v-icon></v-btn></v-toolbar-title>
                    </v-toolbar>
                    <v-card-text v-if="css && css.btns">
                        <div v-for="(btn, index) in css.btns" :key="'btn_'+index">
                            <h4 class="mb-2">
                                <b>{{$t(index+"Label")}}</b>
                                <p class="mb-0" style="text-transform:none">{{$t(index+"Explanation")}}</p>
                            </h4>
                            <v-container fluid class="mb-4">
                                
                                <v-select @change="refreshFrame" filled dense hide-details tile :items="selectFontStyle" class="mb-2" :label="$t('btnStyleLabel')" v-model="btn.style" :no-data-text="$t('no-data-text')"></v-select>
                                <v-select @change="refreshFrame" filled dense hide-details tile :items="selectColor" v-model="btn.color1" :label="$t('btnColor1Label')" class="mb-2" :no-data-text="$t('no-data-text')">
                                    <template v-slot:selection="{ item }">
                                    <div style="display:flex; align-items:center; height:100%; position:absolute;">
                                            <v-icon icon :color="item.color">
                                                $square_s
                                            </v-icon>
                                            <p class="mb-0 ml-2">{{item.text}}</p>
                                        </div>
                                    </template>
                                    <template v-slot:item="{ item }">
                                        <div style="display:flex; align-items:center; height:100%; position:absolute;">
                                            <v-icon icon :color="item.color">
                                                $square_s
                                            </v-icon>
                                            <p class="mb-0 ml-2">{{item.text}}</p>
                                        </div>
                                    </template>
                                </v-select>
                                <v-select filled dense hide-details tile @change="refreshFrame" :items="selectBrightnessStyle" :label="$t('btnBrightnessColor1Label')" class="mb-2" v-model="btn.brightness_color1" :no-data-text="$t('no-data-text')"></v-select>


                                <v-select filled dense hide-details @change="refreshFrame" tile v-if="btn.style != 'outlined'" :items="selectColor" :label="$t('btnColor2Label')" v-model="btn.color2" class="mb-2" :no-data-text="$t('no-data-text')">
                                    <template v-slot:selection="{ item }">
                                    <div style="display:flex; align-items:center; height:100%; position:absolute;">
                                            <v-icon icon :color="item.color">
                                                $square_s
                                            </v-icon>
                                            <p class="mb-0 ml-2">{{item.text}}</p>
                                        </div>
                                    </template>
                                    <template v-slot:item="{ item }">
                                        <div style="display:flex; align-items:center; height:100%; position:absolute;">
                                            <v-icon icon :color="item.color">
                                                $square_s
                                            </v-icon>
                                            <p class="mb-0 ml-2">{{item.text}}</p>
                                        </div>
                                    </template>
                                </v-select>
                                <v-select @change="refreshFrame" filled dense hide-details tile v-if="btn.style != 'outlined'" :items="selectBrightnessStyle" :label="$t('btnBrightnessColor2Label')" class="mb-2" v-model="btn.brightness_color2" :no-data-text="$t('no-data-text')"></v-select>
                                <v-select @change="refreshFrame" filled dense hide-details tile :items="selectBtnRounded" :label="$t('btnRoundedLabel')" class="mb-2" v-model="btn.rounded" :no-data-text="$t('no-data-text')"></v-select>
                                <v-select @change="refreshFrame" filled dense hide-details tile :items="selectBtnCase" :label="$t('btnCaseLabel')" class="mb-2" v-model="btn.case" :no-data-text="$t('no-data-text')"></v-select>
                                <v-select @change="refreshFrame" filled dense hide-details tile :items="selectFontWeight" :label="$t('btnWeightLabel')" v-model="btn.weight" :no-data-text="$t('no-data-text')"></v-select>
                                    
                            </v-container>
                        </div>
                    </v-card-text>
                </v-navigation-drawer>
                <!-- favicon -->
                <v-navigation-drawer
                :width="drawerWidth+'px'"
                v-model="faviconDrawer"
                temporary
                stateless
                absolute
                hide-overlay
                >
                    <v-toolbar tile flat dark  class="primary">
                        <v-toolbar-title style="display:flex; justify-content: space-between; width:100%;">{{$t('faviconDrawer')}} <v-btn @click.stop="faviconDrawer = false" text color="white" class="square"><v-icon >$close</v-icon></v-btn></v-toolbar-title>
                    </v-toolbar>
                    <div class="pa-4 input-form">
                        <p class="mb-1">{{$t('pageTitleLabel')}}</p>
                        <v-text-field :label="$t('pageTitle')" hide-details @keyup="refreshFrame" v-model="pageTitle" solo flat filled dense></v-text-field>
                        <p class="mt-2 mb-1">{{$t('faviconLabel')}}</p>
                        <vFileInputDirect  @change="refreshFrame" :field="{props:{meta:{brand:[], type:['favicon']}}}" :model="css" fieldName="favicon"/>
                    </div>
                </v-navigation-drawer>
                <!-- HOOKS -->
                <v-navigation-drawer
                v-for="(hook, i) in currentLanding.hooks"
                :key="'drawer_'+i"
                :width="drawerWidth+'px'"
                v-model="hook.drawer"
                @input="drawerInput($event, hook)"
                stateless
                temporary
                absolute
                hide-overlay
                >
                    <v-toolbar tile flat dark  class="primary">
                        <v-toolbar-title style="display:flex; justify-content: space-between; width:100%;">{{$t(i)}} <v-btn @click.stop="hookCloseDrawer(hook)" text color="white" class="square"><v-icon >$close</v-icon></v-btn></v-toolbar-title>
                    </v-toolbar>

                    <div v-if="hook.elements && (hook.elements.length > 0 || Object.keys(hook.elements).length > 0)" class="pa-4">
                        <div v-for="(element) in hook.elements" :key="element.label" class="mb-4">
                            <v-checkbox @change="refreshFrame" v-if="element.hasOwnProperty('display')" v-model="element.display" :label="$t(element.label+'Display')" class="mb-1"></v-checkbox>
                            <div v-if="!element.hasOwnProperty('display') || (element.hasOwnProperty('display') && element.display)">
                                <p class="mb-1" v-if="element.type == 'textarea'">{{$t(element.label)}}</p>
                                <Editor
                                    v-if="active && element.type == 'textarea'"
                                    :api-key="apiKey"
                                    v-model="element.value"
                                    :init="TINY_OPTIONS"
                                    @input="wysiwygRefresh"
                                />
                                <v-text-field v-if="(element.type == 'text' || element.type == 'video')" :label="$t(element.label)" hide-details @keyup.native="refreshFrame" v-model="element.value" filled dense></v-text-field>
                                <div v-if="element.type == 'img'">
                                    <p class="mb-1">{{$t(element.label)}}</p>
                                    <vFileInputDirect  @change="refreshFrame" :field="{props:{meta:{brand:[], type:[]}}}" :model="element" :fieldName="'value'"/>
                                </div>
                                <div v-if="element.type == 'background'">
                                    <p class="mb-1">{{$t(element.label)}}</p>
                                    <v-switch v-model="element.color"  @change="refreshFrame" :label="$t('useColorLabel')"></v-switch>
                                    <colorPicker v-if="element.color"  @change="refreshFrame" v-model="element.color_value" :label="$t('backgroundColorLabel')" />
                                    <vFileInputDirect v-if="!element.color"  @change="refreshFrame" :field="{props:{meta:{brand:[], type:[]}}}" :model="element" :fieldName="'value'"/>
                                </div>
                                <div v-if="element.type == 'color'">
                                    <p class="mb-1">{{$t(element.label)}}</p>
                                    <colorPicker @change="refreshFrame" v-model="element.color_value" :label="$t('backgroundColorLabel')" />
                                </div>
                            </div>
                            <v-divider class="mt-4"></v-divider>
                        </div>
                    </div>
                    <div v-else>
                        <div v-if="hook.type == 'img' && i == 'lp_logo'" class="pa-4">
                            <p class="mb-1">{{$t(i)}}</p>
                            <vFileInputDirect  @change="refreshFrame" :field="{props:{meta:{brand:[], type:['logo']}}}" :model="hook" :fieldName="'value'"/>
                        </div>
                        <div v-if="hook.type == 'countdown'" class="pa-4">
                            <v-checkbox @change="refreshFrame" v-if="hook.hasOwnProperty('display')" v-model="hook.display" :label="$t(hook.type+'Display')" class="mb-1"></v-checkbox>
                            <div v-for="(color, index) in hook.colors" :key="'color_'+index" class="mb-2">
                                <v-select filled dense hide-details @change="refreshFrame" tile :items="selectColor" :label="$t(index+'ColorLabel')" v-model="hook.colors[index]" class="mb-2" :no-data-text="$t('no-data-text')">
                                    <template v-slot:selection="{ item }">
                                    <div style="display:flex; align-items:center; height:100%; position:absolute;">
                                            <v-icon icon :color="item.color">
                                                $square_s
                                            </v-icon>
                                            <p class="mb-0 ml-2">{{item.text}}</p>
                                        </div>
                                    </template>
                                    <template v-slot:item="{ item }">
                                        <div style="display:flex; align-items:center; height:100%; position:absolute;">
                                            <v-icon icon :color="item.color">
                                                $square_s
                                            </v-icon>
                                            <p class="mb-0 ml-2">{{item.text}}</p>
                                        </div>
                                    </template>
                                </v-select>
                            </div>
                            <div v-for="(value, index) in hook.values" :key="index">
                                <v-text-field :label="$t(index)" @keyup.native="refreshFrame" v-model="hook.values[index]" hide-details filled dense class="mb-2"></v-text-field>
                            </div>
                        </div>
                        <div v-if="hook.type=='form'" class="mt-2">
                            <v-expansion-panels v-model="stepForm" accordion class="no-shadow">
                                <v-expansion-panel
                                    v-for="(item) in computedSteps"
                                    :key="item"
                                >
                                    <v-expansion-panel-header>{{$t(item+"Title")}}</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <div v-if="hook.steps[item].title">
                                            <v-text-field :label="$t('titleLabel')" v-model="hook.steps[item].title"  @keyup.native="refreshFrame" hide-details filled dense class="mb-2"></v-text-field>
                                        </div>
                                        <div v-if="hook.steps[item].text">
                                            <p class="mb-1">{{$t('textLabel')}}</p>
                                            <Editor
                                                v-if="active"
                                                :api-key="apiKey"
                                                v-model="hook.steps[item].text"
                                                :init="TINY_OPTIONS"
                                                ref="editor"
                                                @input="wysiwygRefresh"
                                            />
                                        </div>
                                        <div v-if="hook.steps[item].fields" class="mt-4">
                                            <p class="mb-1">{{$t('fieldsModificationLabel')}}</p>
                                            <div style="display:flex; align-items:center;" v-for="(field, index) in hook.steps[item].fields" :key="'field_'+index">
                                                <v-text-field hide-details :label="$t(field.name+'Label')" @keyup.native="refreshFrame" v-model="field.label" filled dense class="mb-2"></v-text-field>
                                                <v-checkbox hide-details :label="$t('requiredLabel')" @change="refreshFrame" class="ma-0 mb-2 ml-4" v-model="field.required"></v-checkbox>
                                            </div>
                                        </div>
                                        
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </div>
                    </div>
                </v-navigation-drawer>
            </v-col>
            <v-col cols="9" style="height:100%;">
                <v-row class="white">
                    <v-col>
                        <v-btn-toggle
                            v-model="isMobile"
                            tile
                            group
                            mandatory
                        >
                            <v-btn :value="false">
                                <v-icon color="darkgrey">$desktop_r</v-icon>
                            </v-btn>
                            <v-btn :value="true">
                                <v-icon color="darkgrey">$mobile_alt_s</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </v-col>
                </v-row>
                <v-row class="editorPreview" style="height:calc(100% - 80px);">
                    <v-col cols="12" class="pa-5 d-flex justify-center">
                        <iframe id="landingPageIFrame" name="landingPageIFrame" frameborder="0" :height="height - 10" :class="isMobile ? 'iframeHtml mobile' : 'iframeHtml'" ref="iframeHtml" :key="lpKey"></iframe>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
    
</div>
    
</template>
<script>
import MediathequeDialogComponent from '@/components/dialogs/MediathequeDialogComponent'
import Editor from "@tinymce/tinymce-vue";
import colorPicker from '@/components/colorPicker'
import vFileInputDirect from '@/components/forms/inputs/v-file-input-direct'
import GenericDataService from '@/services/GenericDataService';
const TINY_OPTIONS = {
    selector: "textarea#custom-menu-item",
    height: 200,
    toolbar: false,
    forced_root_block : false,
    menubar: "custom format",
    encoding: "xml",
    menu: {
        custom: {
            title: "Insérer une variable",
            items: "",
        },
    },
}
export default {
    name:"htmlEditorComponent",
    props:['height', 'dialog', 'css', 'currentLanding', 'idLanding'],
    components: {MediathequeDialogComponent, Editor, colorPicker,vFileInputDirect},
    data() {
        return {
            publicPath: process.env.BASE_URL,
            apiKey: process.env.VUE_APP_TINY_MCE,
            colorDrawer:false,
            stepForm:-1,
            fontsDrawer:false,
            btnsDrawer:false,
            faviconDrawer:false,
            displayDrawer: false,
            landingString: "",
            fieldSelected:{},
            fieldPossible: [ { "label": "Nom", "name": "name", "type": "text", "required": false }, { "label": "Prénom", "name": "firstname", "type": "text", "required": true }, { "label": "Adresse email", "name": "email", "type": "email", "required": true }, { "label": "Téléphone mobile", "name": "mobile_phone", "type": "mobile_phone", "required": true } ],
            selectBtnRounded: [{text: "Sans", value: "none"}, {text: "Léger", value: "soft"}, {text: "Grand", value: "large"}],
            selectBtnCase: [{text: "Normal", value: "none"}, { text: "Capitales", value: "uppercase"},{text: "Minuscules", value: "lowercase"}],
            selectFontStyle: [{text: "Classique", value: "classic"}, {text: "Outlined", value: "outlined"}],
            selectBrightnessStyle: [{text: "Plus sombre", value: "darker"},{text: "Plus clair", value: "lighter"},{text: "Normal",value: "normal"}],
            model:0,
            isMobile: false,
            drawer: false,
            tab:null,
            code: "",
            TINY_OPTIONS,
            drawerWidth: 0,
            active: false,
            timer:"",
            lpKey:0,
            pageTitle: ""
        }
    },
    created() {
        GenericDataService.getData("/template/getAvailableVariables?landing_id=" + this.idLanding).then((response) => {
            let vm = this;
            let variables = response.data.data;
            vm.variables = variables;

            let menuItemsText = "";
            for (var i in variables) {
                menuItemsText += i + " ";
            }
            vm.TINY_OPTIONS.menu.custom.items = menuItemsText;

            vm.TINY_OPTIONS.setup = function (editor) {
                editor.on('ExecCommand', function(e) {
                      //if(e.command == "mceFocus"){
                        e.preventDefault();
                        
                          e.stopPropagation();
                         e.stopImmediatePropagation()
                      //}
                    });
            let variables = vm.getTinyVariables(vm.variables);
                for (let id in variables){
                    if(variables[id].hasOwnProperty('list')) {
                        editor.ui.registry.addNestedMenuItem(id, { text: variables[id].text, getSubmenuItems: function () { return variables[id].list; } });
                    } else if(variables[id].hasOwnProperty('code')) {
                        editor.ui.registry.addMenuItem(id, { text: variables[id].text, onAction: function () { editor.insertContent(variables[id].code); } });
                    }
                }
            };
            if(this.$refs["colMenu"]){
                this.drawerWidth = this.$refs["colMenu"].offsetWidth 
            }
            setTimeout(() => {
                this.drawerWidth = this.$refs["colMenu"].offsetWidth
            }, 100);

            this.active = true;
        });
        
        for(const property in this.currentLanding.hooks) {
            if (Object.hasOwnProperty.call(this.currentLanding.hooks, property)) {
                const hook = this.currentLanding.hooks[property];
                if(hook.elements){
                    for (const key in hook.elements) {
                        if (Object.hasOwnProperty.call(hook.elements, key)) {
                            const element = hook.elements[key];
                            if(element.type == 'textarea'){
                                element.value = this.decodeHtml(element.value)
                            }
                        }
                    }
                }
            }
        }

        if(this.currentLanding.settings && this.currentLanding.settings.layout_id){
            if(this.currentLanding.settings.layout_id == 2)
                this.stepForm = -1
            else
                this.stepForm = 0
        }

        this.computedSteps.forEach(element => {
            if(this.currentLanding.hooks.lp_form.steps[element].text){
                this.currentLanding.hooks.lp_form.steps[element].text = this.decodeHtml(this.currentLanding.hooks.lp_form.steps[element].text)
            }
        });

        this.refreshFrame();
       
    },
    mounted () {
        setTimeout(() => {
             this.drawerWidth = this.$refs["colMenu"].offsetWidth
        }, 100);       
    },
    watch: {
        dialog(val){
            if(val){
                this.drawerWidth = this.$refs["colMenu"].offsetWidth
                this.refreshFrame()
            }
        }
    },
    computed :{
        computedSteps(){
            let steps = this._.cloneDeep(this.currentLanding.hooks.lp_form.steps)

            if(this.currentLanding.settings.places.length < 2){
                delete(steps.places)
            }
            if(!this.currentLanding.settings.options.resources){
                delete(steps.resources)
            }
            if(this.currentLanding.settings.options.meeting_type != "RDV"){
                delete(steps.slots)
            }
            if(this.currentLanding.settings.number_of_days < 2){
                delete(steps.calendar)
            }

            return Object.keys(steps);
        },
        urlLanding(){
            return 'https://testuyb.evt-auto.fr/?edition_mode=1&step='+this.stepForm //http://phpstorm/landing_page/
        }, 
        selectColor(){
            let colors = []
            for (const colorName in this.css.colors) {
                    const element = this.css.colors[colorName];
                    const colorObject = {
                        text: colorName,
                        value : colorName, 
                        color: element
                    }
                    colors.push(colorObject)
            }

            return colors
        },
        selectFontWeight(){
            let fontWeights = []
            for (const weightName in this.css.fonts) {
                fontWeights.push(weightName)
            }

            return fontWeights
        }
    },
    methods: {
        drawerInput($evt, hook){
            if(hook.type == "form" && !$evt && this.currentLanding.settings.layout_id == 2)
                this.stepForm = -1
        },
        wysiwygRefresh(){
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.refreshFrame()
            }, 500);
        },
        testEncoding(e, hookIndex, elementIndex){
            if(hookIndex != "lp_form"){
                this.currentLanding.hooks[hookIndex].elements[elementIndex].value = this.encodeHTML(e);
            } else {
                this.currentLanding.hooks[hookIndex].steps[elementIndex].text = this.encodeHTML(e);
            }
            this.refreshFrame()
        },
        encodeHTML(string){
            return string.replace(/&/g, '&amp;')
               .replace(/</g, '&lt;')
               .replace(/>/g, '&gt;')
               .replace(/"/g, '&quot;')
               .replace(/'/g, '&apos;')
               .replace(/[\n\r]/g,' ');
        },
        decodeHtml(string){
            return string.replace(/&apos;/g, "'")
               .replace(/&quot;/g, '"')
               .replace(/&gt;/g, '>')
               .replace(/&lt;/g, '<')
               .replace(/&amp;/g, '&');
        },
        hookCloseDrawer(hook){
            hook.drawer = false
            this.$forceUpdate();
        },
        shadeColor(color, mode) {
            let percent = 0;
            switch (mode) {
                case 'lighter':
                    percent = 20
                    break;
                case 'darker':
                    percent = -20
                    break;
            }
            var R = parseInt(color.substring(1,3),16);
            var G = parseInt(color.substring(3,5),16);
            var B = parseInt(color.substring(5,7),16);

            R = parseInt(R * (100 + percent) / 100);
            G = parseInt(G * (100 + percent) / 100);
            B = parseInt(B * (100 + percent) / 100);

            R = (R<255)?R:255;  
            G = (G<255)?G:255;  
            B = (B<255)?B:255;  

            var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
            var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
            var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

            return "#"+RR+GG+BB;
        },
        refreshFrame(){
            this.currentLanding.default_css = "";
            
            if(this.css.fonts.black && this.css.fonts.black.url){
                this.currentLanding.default_css +="\
                    @font-face {\
                        font-family: 'black_police';\
                        src: url('" + this.css.fonts.black.url + "');\
                    }\
                "
                this.currentLanding.default_css += ".black {font-family:black_police; font-weight:inherit;}"
            }
            
            if(this.css.fonts.bold && this.css.fonts.bold.url){
                this.currentLanding.default_css +="\
                    @font-face {\
                        font-family: 'bold_police';\
                        src: url('" + this.css.fonts.bold.url + "');\
                    }\
                ";
                this.currentLanding.default_css += ".bold {font-family:bold_police; font-weight:inherit;}"
            }
             
            if(this.css.fonts.regular && this.css.fonts.regular.url){
                 this.currentLanding.default_css +="\
                    @font-face {\
                        font-family: 'regular_police';\
                        src: url('" + this.css.fonts.regular.url + "');\
                    }\
                ";
                this.currentLanding.default_css += "html {font-family:regular_police; font-weight:inherit;}"
            } else {
                this.currentLanding.default_css += "html {font-family:'OpenSans'; font-weight:inherit;}"
            }
            
            if(this.css.fonts.light && this.css.fonts.light.url){
                this.currentLanding.default_css +="\
                    @font-face {\
                        font-family: 'light_police';\
                        src: url('" + this.css.fonts.light.url + "');\
                    }\
                ";
                this.currentLanding.default_css += ".light {font-family:light_police; font-weight:inherit;}"
            }
             
            this.currentLanding.default_css += '.lp_btn { '
            if(this.css.btns.btn1.style == 'classic'){
                this.currentLanding.default_css += 'background-color : '+this.shadeColor(this.css.colors[this.css.btns.btn1.color1], this.css.btns.btn1.brightness_color1)+'; '
                this.currentLanding.default_css += 'color : '+this.shadeColor(this.css.colors[this.css.btns.btn1.color2], this.css.btns.btn1.brightness_color2)+'; '
            } else {
                this.currentLanding.default_css += 'border : 1px solid '+this.shadeColor(this.css.colors[this.css.btns.btn1.color1], this.css.btns.btn1.brightness_color1)+'; color: '+this.shadeColor(this.css.colors[this.css.btns.btn1.color1], this.css.btns.btn1.brightness_color1)+'; background-color:transparent;'
            }
            switch(this.css.btns.btn1.rounded){
                case "soft":
                    this.currentLanding.default_css += 'border-radius : 4px; '
                    break;
                case "large":
                    this.currentLanding.default_css += 'border-radius : 10px; '
                    break;
                default:
                    this.currentLanding.default_css += 'border-radius : 0px; '
                    break;
            }
            this.currentLanding.default_css += 'font-weight : '+this.css.btns.btn1.weight+'; font-family:'+this.css.btns.btn1.weight+'_police; text-transform : '+this.css.btns.btn1.case+'; padding: 15px 10px;}'
            //fin btn1

            //btn1 active / hover
            this.currentLanding.default_css += '.lp_btn.active, .lp_btn:hover { '
            if(this.css.btns.btn1_hover.style == 'classic'){
                this.currentLanding.default_css += 'background-color : '+this.shadeColor(this.css.colors[this.css.btns.btn1_hover.color1], this.css.btns.btn1_hover.brightness_color1)+'; '
                this.currentLanding.default_css += 'color : '+this.shadeColor(this.css.colors[this.css.btns.btn1_hover.color2], this.css.btns.btn1_hover.brightness_color2)+'; '
            } else {
                this.currentLanding.default_css += 'border : 1px solid '+this.shadeColor(this.css.colors[this.css.btns.btn1_hover.color1], this.css.btns.btn1_hover.brightness_color1)+'; color: '+this.shadeColor(this.css.colors[this.css.btns.btn1_hover.color1], this.css.btns.btn1_hover.brightness_color1)+'; background-color:transparent;'
            }
            switch(this.css.btns.btn1_hover.rounded){
                case "soft":
                    this.currentLanding.default_css += 'border-radius : 4px; '
                    break;
                case "large":
                    this.currentLanding.default_css += 'border-radius : 10px; '
                    break;
                default:
                    this.currentLanding.default_css += 'border-radius : 0px; '
                    break;
            }
            this.currentLanding.default_css += 'font-weight : '+this.css.btns.btn1_hover.weight+'; font-family:'+this.css.btns.btn1_hover.weight+'_police; text-transform : '+this.css.btns.btn1_hover.case+'; padding: 15px 10px;}'
            // fin btn1 active / hover


            //btn slot
            this.currentLanding.default_css += '.lp_slot { '
            if(this.css.btns.btn2.style == 'classic'){
                this.currentLanding.default_css += 'background-color : '+this.shadeColor(this.css.colors[this.css.btns.btn2.color1], this.css.btns.btn2.brightness_color1)+'; '
                this.currentLanding.default_css += 'color : '+this.shadeColor(this.css.colors[this.css.btns.btn2.color2], this.css.btns.btn2.brightness_color2)+'; '
            } else {
                this.currentLanding.default_css += 'border : 1px solid '+this.shadeColor(this.css.colors[this.css.btns.btn2.color1], this.css.btns.btn2.brightness_color1)+'; color: '+this.shadeColor(this.css.colors[this.css.btns.btn2.color1], this.css.btns.btn2.brightness_color1)+'; background-color:transparent;'
            }
            switch(this.css.btns.btn2.rounded){
                case "soft":
                    this.currentLanding.default_css += 'border-radius : 4px; '
                    break;
                case "large":
                    this.currentLanding.default_css += 'border-radius : 10px; '
                    break;
                default:
                    this.currentLanding.default_css += 'border-radius : 0px; '
                    break;
            }
            this.currentLanding.default_css += 'font-weight : '+this.css.btns.btn2.weight+'; font-family:'+this.css.btns.btn2.weight+'_police; text-transform : '+this.css.btns.btn2.case+'; padding: 15px 10px;}'
            //fin btn1

            //btn1 active / hover
            this.currentLanding.default_css += '.lp_slot:hover { '
            if(this.css.btns.btn2_hover.style == 'classic'){
                this.currentLanding.default_css += 'background-color : '+this.shadeColor(this.css.colors[this.css.btns.btn2_hover.color1], this.css.btns.btn2_hover.brightness_color1)+'; '
                this.currentLanding.default_css += 'color : '+this.shadeColor(this.css.colors[this.css.btns.btn2_hover.color2], this.css.btns.btn2_hover.brightness_color2)+'; '
            } else {
                this.currentLanding.default_css += 'border : 1px solid '+this.shadeColor(this.css.colors[this.css.btns.btn2_hover.color1], this.css.btns.btn2_hover.brightness_color1)+'; color: '+this.shadeColor(this.css.colors[this.css.btns.btn2_hover.color1], this.css.btns.btn2_hover.brightness_color1)+'; background-color:transparent;'
            }
            switch(this.css.btns.btn2_hover.rounded){
                case "soft":
                    this.currentLanding.default_css += 'border-radius : 4px; '
                    break;
                case "large":
                    this.currentLanding.default_css += 'border-radius : 10px; '
                    break;
                default:
                    this.currentLanding.default_css += 'border-radius : 0px; '
                    break;
            }
            this.currentLanding.default_css += 'font-weight : '+this.css.btns.btn2_hover.weight+'; font-family:'+this.css.btns.btn2_hover.weight+'_police; text-transform : '+this.css.btns.btn2_hover.case+'; padding: 15px 10px;}'
            // fin btn1 active / hover

            //countdown colors
            if(this.currentLanding.hooks.lp_countdown)
                this.currentLanding.default_css += '#lp_countdown {color:'+this.css.colors[this.currentLanding.hooks.lp_countdown.colors.font]+' !important; background-color: '+this.css.colors[this.currentLanding.hooks.lp_countdown.colors.background]+';} ';

            if(this.currentLanding.hooks.lp_countdown)
                this.currentLanding.default_css += '#lp_countdown table {color:'+this.css.colors[this.currentLanding.hooks.lp_countdown.colors.font]+' !important; background-color: '+this.css.colors[this.currentLanding.hooks.lp_countdown.colors.background]+';} ';


            if(this.currentLanding.hooks.lp_header.elements.lp_header_textcolor && this.currentLanding.hooks.lp_header.elements.lp_header_textcolor.color_value)
                this.currentLanding.default_css += '#lp_header_background {color:'+this.currentLanding.hooks.lp_header.elements.lp_header_textcolor.color_value+' !important; } ';

            //
            this.currentLanding.default_css += '#stepper .step.actif {color:#333333;} ';
            this.currentLanding.default_css += '.pignose-calendar .pignose-calendar-unit.pignose-calendar-unit-active a{background-color:#333333 !important; color:#ffffff !important;} ';

            //gestion background header
            if(this.css.background_header && this.css.background_header.length > 0){
                this.currentLanding.default_css += '#lp_header_background {background-image : url('+this.css.background_header[0].url+');}'
            } else {
                if(this.currentLanding.hooks.lp_header.elements.lp_header_background.color){
                    this.currentLanding.default_css += '#lp_header_background {background-color : '+this.currentLanding.hooks.lp_header.elements.lp_header_background.color_value+';}'
                } else {
                    this.currentLanding.default_css += '#lp_header_background {background-image : url('+this.currentLanding.hooks.lp_header.elements.lp_header_background.value.url+');}'
                }
            }           
            
            //gestion favicon
            if(this.css.favicon && this.css.favicon.hasOwnProperty('url')){
                this.currentLanding.settings.favicon = this.css.favicon.url
            }

            if(this.pageTitle != ""){
                this.currentLanding.settings.title = this.pageTitle
            }

            let landingToSend = this._.cloneDeep(this.currentLanding);

            //reencode les text
            for(const property in landingToSend.hooks) {
                if (Object.hasOwnProperty.call(landingToSend.hooks, property)) {
                    const hook = landingToSend.hooks[property];
                    if(hook.elements){
                        for (const key in hook.elements) {
                            if (Object.hasOwnProperty.call(hook.elements, key)) {
                                const element = hook.elements[key];
                                if(element.type == 'textarea'){
                                    element.value = this.encodeHTML(element.value)
                                }
                            }
                        }
                    }
                }
            }
            this.computedSteps.forEach(element => {
                if(landingToSend.hooks.lp_form.steps[element].text){
                    landingToSend.hooks.lp_form.steps[element].text = this.decodeHtml(landingToSend.hooks.lp_form.steps[element].text)
                }
            });

            // //precharge les variables pour l'edition
            let url = "/landingpage/getVariablesLP?"
            if(this.idLanding) {
                url += "landing_id=" + this.idLanding
            }

            GenericDataService.postData(url, {landing:landingToSend}).then((response) => {
                this.landingString = (response.data.data)
                GenericDataService.postData(this.urlLanding, {css : this.landingString}).then((response) => {
                    if(!this.$refs.iframeHtml.contentDocument){
                        this.lpKey++
                        setTimeout(() => {
                            this.$refs.iframeHtml.contentDocument.open()
                            this.$refs.iframeHtml.contentDocument.write(response.data);
                            this.$refs.iframeHtml.contentDocument.close()
                        }, 200);
                    } else {
                        this.$refs.iframeHtml.contentDocument.open()
                        this.$refs.iframeHtml.contentDocument.write(response.data);
                        this.$refs.iframeHtml.contentDocument.close()
                    }
                        

                    
                    
                })
            })



            //document.getElementById('iframeHtml').src = this.urlLanding;
            

            
            
        },
        getTinyVariables(variables) { 
            let tinyVariables = [];

            const callback_func = function(index, variables) {
                if(typeof variables === 'object' && variables.hasOwnProperty('items')) {
                    for (var i in variables.items) {
                        let id = index + "_" + i;
                        tinyVariables[index]['list'].push(id);
                        if(typeof variables.items[i] === 'object' && variables.items[i].hasOwnProperty('items')) {
                            tinyVariables[id] = { "text": variables.items[i].name, "list": [] };
                            callback_func(id, variables.items[i]);
                        } else {
                            tinyVariables[id] = { "text": variables.items[i].label, "code": variables.items[i].value };
                        }
                    }
                }
            }

            for (var i in variables) {
                tinyVariables[i] = { "text": variables[i].name, "list": [] };
                callback_func(i, variables[i]);
            }

            return tinyVariables;
        },
        switchSize: function(){
            this.isMobile = !this.isMobile
        },
    },
}
</script>
<style lang="scss">
.v-expansion-panels.no-shadow .v-expansion-panel::before {
    box-shadow: none !important;
}
.listItemEditor {
    border-bottom: 1px solid rgba(0,0,0,0.1);
    .titleDrawer{
        width:100%;
        display:flex;
        justify-content: space-between;
        align-items:center;
    }
}
    .lpIframe {
        .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
            color: var(--v-darkgrey-base) !important;
        }
        .lineItems {
            display:flex;
            align-items:center;
            .v-checkbox {
                width:40px;
            }
            .inputSlot {
                width:100%;
            }
        }
        .expansionBlocks {
            .headingExp {
                .fa-caret-down {
                    color:white !important;
                }
            }
            
            .panelExp {
                margin-bottom:4px !important;
                .header {
                    display:flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }
            .v-expansion-panel-header--active {
                min-height:48px !important
            }
            .contentGlobal .v-expansion-panel-content__wrap {
                padding:0;
            }
            .contentnormal .v-expansion-panel-content__wrap {
                padding:0 10px 10px 10px;
            }
        }

        .v-navigation-drawer__content::-webkit-scrollbar {
            display: none;
        }
        .v-navigation-drawer__content {
            scrollbar-width: none;
        }
        
        width:100%;
        //min-height:calc(100% - 63px);
        .v-tabs-slider-wrapper {
            top:0;
        }
        .v-slide-group__content {
            width:100% !important;
            .v-tab {
                width:50%;
                background-color: #EEE;
                &.v-tab--active {
                    background-color: transparent;
                }
            }
        }
        .editorPreview {
            background-color: #fff;
            background-image: linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%), linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%);
            background-size: 15px 15px;
            background-position: 0 0, 7.5px 7.5px;
            background-repeat: repeat;
        }
        .v-item--active {
            &:before {
                opacity:0 !important;
            }
            .v-btn__content {
                color: var(--v-primary-base) !important;
                span {
                    color: var(--v-primary-base) !important;
                    svg {
                        color: var(--v-primary-base) !important;
                        fill:var(--v-primary-base) !important;
                    }
                }
            }
        }
        .tox.tox-tinymce {
            border-radius: 4px 4px 0 0;
            border: none;
            border-bottom: 1.25px solid rgba(0, 0, 0, 0.42);
            .tox-edit-area__iframe, .tox-menubar, .tox-statusbar {
            background-color: rgba(0, 0, 0, 0.06);
            }
            &:hover {
            border-bottom: 1.25px solid rgba(0, 0, 0, 0.87);
            .tox-edit-area__iframe, .tox-menubar, .tox-statusbar {
                background-color: rgba(0, 0, 0, 0.12);
            }
            }
            .tox-statusbar {
            display: none;
            }
        }
        .CodeMirror {
            height:200px;
        }
        .logo-upload {
            .switcher-bg {
                display:flex;
                align-items: center;
                justify-content: center;
            }
            .logo-input {
                &.menu {
                height: 150px;
                width: 100%;
                }
                &.favicon {
                height: 70px;
                width: 70px;
                }
                display: block;
                cursor: pointer;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
                border-radius: 4px;
                border:1px solid #cccccc;
                padding:15px;
                background-origin: content-box;
            }
            .placeholder {
                background: #F0F0F0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 18px;
                border-radius: 4px;
            }
            .placeholder:hover {
                background: #E0E0E0;
            }
            .file-input {
                display: none
            }
        }
    }
    .iframeHtml {
        width:100%;
        transition: width 0.3s ease-in;
        //height:calc(100% - 63px);
        &.mobile {
            width:320px;
        }
    }
    
    
</style>