<template>
  <div class="col col-12 pa-0 input-form">
    <span v-if="field.title" class="label"><b>{{$t(field.title)}}</b><span v-if="field.props && field.props.required" style="color:red">&nbsp;*</span></span>
    <v-select
      :label="field.title ? $t(field.title) : ''"
      v-model="model[fieldName]"
      v-bind="field.props"
      :clearable="field.props.clearable == false ? false : true"
      :item-text="text"
      :rules=[validate]
      :no-data-text="$t('no-data-text')"
    >
        <template v-slot:selection="data" v-if="field.props.multiple">
          <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            small
            outlined
            color="primary"
            close
            close-icon="$close"
            @click:close="remove(data.item)"
          >
            {{ field['to_trad'] ? (data.item.text ?  $t(data.item.text) : $t(data.item)) : (data.item.text ?  data.item.text : data.item) }}
          </v-chip>
        </template>
        <template v-slot:selection="data" v-else>
            {{ field['to_trad'] ? (data.item.text ?  $t(data.item.text) : $t(data.item)) : (data.item.text ?  data.item.text : data.item) }}
        </template>
    </v-select>
  </div>
</template>

<script>
import validate from '../functions/validations'

export default {
  props: ["model", "fieldName", "field"],
  mixins: [validate],
  data() {
    return {};
  },
  mounted() {
    if(this.field.props.multiple && typeof this.model[this.fieldName] === "string" && this.model[this.fieldName] !== "") {
      this.model[this.fieldName] = JSON.parse(this.model[this.fieldName]);
    }
    
    if(this.field.props.items && this.field.props.items.length == 1 && !this.fieldName.includes('filters')){
      if(this.field.props.items[0].text && !this.field.props['return-object']) {
        this.model[this.fieldName] = this.field.props.multiple ? [this.field.props.items[0].value] : this.field.props.items[0].value
      } else {
        this.model[this.fieldName] = this.field.props.multiple ? [this.field.props.items[0]] : this.field.props.items[0]
      }
      if(this.fieldName == "prestataire" || this.fieldName == "config.prestataire")
        this.field.hide = true
    }
  },
  created(){
     if(this.field.props.items && this.field.props.items.length == 1 && !this.fieldName.includes('filters')){
      if(this.field.props.items[0].text && !this.field.props['return-object']) {
        this.model[this.fieldName] = this.field.props.multiple ? [this.field.props.items[0].value] : this.field.props.items[0].value
      } else {
        this.model[this.fieldName] = this.field.props.multiple ? [this.field.props.items[0]] : this.field.props.items[0]
      }
      if(this.fieldName == "prestataire" || this.fieldName == "config.prestataire")
        this.field.hide = true
    }
  },
  methods: {
    remove(item){
      let index = -1 
      if(this.isObject(this.model[this.fieldName])){
        index = this.model[this.fieldName].findIndex((e) => e.value == item.value)
      } else {
        index = this.model[this.fieldName].findIndex((e) => e == item.value)
      }
      if(index >=0) 
        this.model[this.fieldName].splice(index, 1)
    },
    isObject(array){
      array.some(element => {
        if (element.text) {
          return true;
        }

        return false;
      });
    },
    text (item){
      return this.field['to_trad'] ? this.$t(item.text) : item.text;
    },
  },
};
</script>

<style lang="scss">
.input-form {
  .v-input__slot {
    background-color: var(--v-lightgrey-base) !important;
  }
}
</style>