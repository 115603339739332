<template>
    <div>
        <div v-if="displayConfigBranch">
            <v-form v-model="validBranch" ref="branchForm">
                <div class="primary--text mb-2" v-if="branchNumber > 0" style="cursor:pointer;" @click="changeDisplayAndDeleteCurrentBranch">&lt; Retour</div>
                <label class="label theme--light"><b>{{$t('branch_name')}} </b></label>
                <v-text-field v-model="element.children[branchNumber].branchName" :label="$t('branch_name')" :rules="[v => (v != '') ? true : $t('mustChooseNameError')]" required solo flat dense ></v-text-field>
                <!-- définition de la branche -->
                <h2 class="mb-1">{{$t('if')}}</h2>
                <div v-for="(orValues, orIndex) in element.children[branchNumber].values" :key="'orValue_'+orIndex">
                    <div v-if="orIndex > 0" class="d-flex mt-3 mb-1" style="align-items:center; justify-content:space-between;">
                        <h2 class="mb-0" style="line-height:normal; min-height:auto;">{{$t('or')}}</h2>
                        <v-icon small @click="removeOr(orIndex)" color="primary">$trashalt_d</v-icon>
                    </div>
                    <div class="branchDefinition pa-2 ">
                        <div v-for="(andValues, andIndex) in orValues" :key="'andValue_'+andIndex">
                            <div v-if="andIndex > 0" class="d-flex mt-3 mb-1" style="align-items:center; justify-content:space-between;">
                                <h2 class="mb-0" style="line-height:normal; min-height:auto;">{{$t('and')}}</h2>
                                <v-icon small @click="removeAnd(orIndex, andIndex)" color="primary">$trashalt_d</v-icon>
                            </div>
                            <label class="label theme--light"><b>{{$t('actionWorkflowLabel')}} </b></label>
                            <v-autocomplete solo flat dense required @change="changeAction(andValues)"
                            :rules="[v => (v != '') ? true : $t('mustChooseError')]" :label="$t('actionWorkflowLabel')"  
                            return-object item-text="text" item-value="text" :items="actions" v-model="andValues.action" class="required" :no-data-text="$t('no-data-text')">
                                <template v-slot:selection="data">
                                    <span>
                                        {{ $t(data.item.text) }}
                                    </span> 
                                </template>
                                <template v-slot:item="{item, attrs, on}">
                                    <v-list-item v-on="on" v-bind="attrs">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <v-row no-gutters align="center">
                                                    {{ $t(item.text) }}
                                                </v-row>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>    
                            </v-autocomplete>
                            <div v-if="andValues.action && andValues.action.hasOwnProperty('text')">
                                <div v-for="select in andValues.action.selectFields" :key="'select_'+select">
                                    <v-autocomplete class="required"    
                                        :rules="[v => v && v.hasOwnProperty('value') ? true : $t('mustChooseError')]" 
                                        v-model="andValues.action_values[select]" 
                                        v-if="selectField[select].selectUrl"
                                        @change="clearOperatorAndValue(andValues)"
                                        :disabled="selectField[select] && selectField[select].hasOwnProperty('depends_of') && andValues.action_values[selectField[select].depends_of] && andValues.action_values[selectField[select].depends_of].hasOwnProperty('value') && andValues.action_values[selectField[select].depends_of].value == ''"
                                        required solo flat dense :label="$t('choose'+select)" return-object
                                        :items="items[select]" @focus="getItems(selectField[select].selectUrl, select, andValues)" :no-data-text="$t('no-data-text')">
                                        
                                        <template v-slot:selection="data">
                                            <span>
                                                {{ data.item['to_trad'] ? $t(data.item.text) : data.item.text }}
                                            </span>
                                        </template>
                                        <template v-slot:item="{item, attrs, on}">
                                            <v-list-item v-on="on" v-bind="attrs">
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        <v-row no-gutters align="center">
                                                            {{ item['to_trad'] ? $t(item.text) : item.text }}
                                                        </v-row>
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>    
                                    </v-autocomplete>
                                    <v-autocomplete
                                        v-model="andValues.action_values[select].subField.value"
                                        v-if="andValues.action_values[select] && andValues.action_values[select].subField"
                                        :items="items[andValues.action_values[select].subField.name]"
                                        required solo flat dense :label="$t('choose'+andValues.action_values[select].subField.name)" return-object
                                        @focus="getItems(andValues.action_values[select].subField.selectFieldUrl, andValues.action_values[select].subField.name, andValues)" 
                                        :no-data-text="$t('no-data-text')"
                                        >

                                    </v-autocomplete>
                                    <v-autocomplete class="required"    
                                        :rules="[v => (v != '') ? true : $t('mustChooseError')]" 
                                        v-model="andValues.operator" 
                                        v-if="select == 'operators'"
                                        @change="changeOperator(andValues)"
                                        required solo flat dense :label="$t('choose'+select)" return-object
                                        :items="andValues.action_values[andValues.action.operators_depends_of] ? selectField.operators[andValues.action_values[andValues.action.operators_depends_of].type] : []" 
                                        :no-data-text="$t('no-data-text')">
                                        
                                        <template v-slot:selection="data">
                                            <span>
                                                {{ $t(data.item.text) }}
                                            </span>
                                        </template>
                                        <template v-slot:item="{item, attrs, on}">
                                            <v-list-item v-on="on" v-bind="attrs">
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        <v-row no-gutters align="center">
                                                            {{ $t(item.text) }}
                                                        </v-row>
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>    
                                        
                                    </v-autocomplete>
                                    
                                </div>
                                <div v-if="andValues.operator != ''">
                                    <v-text-field v-model="andValues.values" :rules="[v => (v != '') ? true : $t('mustFillError')]" required solo flat dense :label="$t('value')" v-if="andValues.operator.valueField && andValues.operator.valueField == 'text'"></v-text-field>
                                    <v-text-field type="number" :rules="[v => (v != '') ? true : $t('mustFillError')]" v-model="andValues.values" required solo flat dense :label="$t('value')" v-if="andValues.operator.valueField && andValues.operator.valueField == 'number'"></v-text-field>
                                    <v-autocomplete v-model="andValues.values" v-if="andValues.operator.valueField && andValues.operator.valueField == 'select'" :items="items[andValues.action_values[andValues.action.selectFields[0]].text]" required solo flat dense  @focus="getItems(andValues.action_values[andValues.action.selectFields[0]].selectFieldUrl, andValues.action_values[andValues.action.selectFields[0]].text, andValues)" :no-data-text="$t('no-data-text')">
                                        <template v-slot:selection="data">
                                            <span>
                                                {{ data.item['to_trad'] ? $t(data.item.text) : data.item.text }}
                                            </span>
                                        </template>
                                        <template v-slot:item="{item, attrs, on}">
                                            <v-list-item v-on="on" v-bind="attrs">
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        <v-row no-gutters align="center">
                                                            {{ item['to_trad'] ? $t(item.text) : item.text }}
                                                        </v-row>
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>    
                                    </v-autocomplete>
                                    <v-menu
                                        ref="menu"
                                        v-model="menu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        v-if="andValues.operator.valueField && andValues.operator.valueField == 'date'"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="andValues.values"
                                                :rules="[v => (v != '') ? true : $t('mustChooseDateError')]"
                                                :label="$t('chooseDate')"
                                                readonly
                                                solo flat dense
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                        dateFormat="dd/MM/yyyy" 
                                        v-model="andValues.values"
                                        
                                        locale="fr"
                                        first-day-of-week="1"
                                        @input="menu = false"
                                        >
                                        </v-date-picker>
                                    </v-menu>
                                    
                                </div>
                            </div>
                            <!-- <v-select v-model="andValues.operator" :label="$t('operator')" filled dense hide-details class="mb-2"></v-select>
                            <v-text-field v-model="andValues.values" :label="$t('value')" filled dense hide-details></v-text-field> -->
                        </div>
                        <v-btn depressed small color="primary" class="mt-3" @click="addAnd(orIndex)">ET</v-btn>
                    </div>
                </div>
                <v-btn depressed small color="primary" class="mt-3" @click="addOr">OU</v-btn>
                <div class="mt-3">
                    <v-btn small depressed dark color="grey" @click="$emit('closeDrawer')">Annuler</v-btn>
                    <v-btn small depressed color="primary" @click="validateForm" class="ml-2">Enregistrer</v-btn>
                </div>
            </v-form>
        </div>
        <div v-if="displayBranch">
            <h2 class="mb-1">{{$t('if')}}</h2>
            <div v-for="(branch, branchIndex) in element.children" :key="'branch_'+branchIndex" v-show="branch.rule != 'else'" class="mb-1">
                <h2 class="mb-1 icon-title" v-if="branchIndex > 0">
                    {{$t('elseif')}}
                    <v-icon small @click="removeBranch(branchIndex)" color="primary">$trashalt_d</v-icon>    
                </h2>
                <div class="branchDefinition pa-4" style="cursor:pointer;" @click="goConfigBranch(branchIndex)">
                    {{branch.branchName}}
                </div>
            </div>
            <v-btn depressed small color="primary" class="mt-2" @click="addBranch">
                <v-icon small class="mr-2">$plus_l</v-icon> {{$t('addBranch')}}
            </v-btn>
            <div class="mt-3">
                <v-btn small depressed dark color="grey" @click="$emit('closeDrawer')">Annuler</v-btn>
                <v-btn small depressed color="primary" v-if="!edit" @click="$emit('addChild')" class="ml-2">Enregistrer</v-btn>
                <v-btn small depressed color="primary" v-else @click="$emit('updateChild')" class="ml-2">Mettre à jour</v-btn>
            </div>
        </div>
    </div>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
export default {
    name: "WorkflowElseIfConfigComponent",
    props:['element', 'edit', 'workflow'],
    data() {
        return {
            branchNumber: 0,
            displayBranch: false,
            displayConfigBranch: true,
            menu:false,
            validBranch: false,
            newBranch:{
                id:0,
                type:"condition_ifelse_child",
                branchName:"",
                icon: "$leads_d",
                rule: "elseif",
                values: [
                    [
                        {
                            action: "",
                            action_values: {},
                            operator: "",
                            values:""
                        }
                    ]
                ],
                children: []
            },
            andObject: {
                action: "",
                action_values: {},
                operator: "",
                values:""
            },
            items:{},
            actions:[],
            selectField:{
                contact_list: {
                    selectUrl: "/workflow/{{workflow_id}}/getSelectListContactList"
                },
                email: {
                    selectUrl: "/workflow/{{workflow_id}}/getSelectListRPElement?type=EMAIL"
                },
                link_email: {
                    selectUrl : "/workflow/getLinkList?rp_id={{depends_id}}",
                    depends_of: "email"
                },
                lp: {
                    selectUrl : "/workflow/{{workflow_id}}/getSelectListLP",
                },
                sms: {
                    selectUrl : "/workflow/{{workflow_id}}/getSelectListRPElement?type=SMS",
                },
                mms: {
                    selectUrl : "/workflow/{{workflow_id}}/getSelectListRPElement?type=MMS",
                },
                segment: {
                    selectUrl : "/segment/_selectList",
                },
                contact_properties: {
                    selectUrl : "/hard_value/getValuesByType?type=wf_elseif_contact_properties",
                },
                rdv_properties: {
                    selectUrl : "/hard_value/getValuesByType?type=wf_elseif_rdv_properties",
                },
                demand_properties: {
                    selectUrl : "/hard_value/getValuesByType?type=wf_elseif_demand_properties",
                },
                subscription_item_properties: {
                    selectUrl : "/hard_value/getValuesByType?type=wf_elseif_subscription_item_properties",
                },
                appeal_status: {
                    selectUrl : "/appeal/getStatusSelect",
                },
                operators: {}
            }
        }
    },
    created() {
        GenericDataService.getData("/workflow/getActionElseAvailable").then((response) => {
            this.actions = response.data.data.filter((e) => !e.disabled)
        })
        GenericDataService.getData("/hard_value/getValuesByType?type=wf_elseif_operators").then((response) => {
            this.selectField.operators = response.data.data
        })
        
    },
    watch: {
        displayConfigBranch(val){
            if(val){
                this.element.children[this.branchNumber].values.forEach(orValues => {
                    orValues.forEach(andValues => {
                        if(andValues.action && andValues.action.selectFields){
                            andValues.action.selectFields.forEach((actionValue) => {
                                if(andValues.action_values[actionValue] && andValues.action_values[actionValue].value){
                                    this.items[actionValue] = []
                                    this.items[actionValue].push(andValues.action_values[actionValue])
                                }
                                if(andValues.values && andValues.values.value){
                                    this.items["value"] = []
                                    this.items["value"].push(andValues.values)
                                }
                            })
                            
                        }
                    })
                })
            }
        }
    },
    mounted(){
        if(this.element.id != 0){
            this.displayBranch = true
            this.displayConfigBranch = false
        }
    },
    methods: {
        removeBranch(branchIndex) {
            this.element.children.splice(branchIndex, 1)
        },
        changeAction(element){
            element.action_values = {}
            element.operator = ""
            element.values = ""
        },
        clearOperatorAndValue(element){
            element.operator = ""
            element.values = ""
        },
        changeOperator(element){
            element.values = ""
        },
        getItems(url, index, element){
            //test si {{workflow_id}}
            if(this.selectField[index] && this.selectField[index].hasOwnProperty('depends_of')){
                url = url.replace('{{depends_id}}', element.action_values[this.selectField[index].depends_of].value)
            }
            if(url.includes('{{workflow_id}}')){
                url = url.replace('{{workflow_id}}', this.workflow.id)
            } 
            this.loading = 'primary'
            if(element.action.text == 'item_property'){
                GenericDataService.getData('/hard_value/getValuesByType?type=items').then((response) => {
                    let itemDispo = Object.keys(response.data.data)
                    GenericDataService.getData(url).then((response) => {
                        this.items[index] = response.data.data.filter((e) => itemDispo.includes(e.itemType))
                        
                        this.$forceUpdate()
                        this.loading = false
                    })
                })
            } else {
                GenericDataService.getData(url).then((response) => {
                    this.items[index] = response.data.data
                
                    this.$forceUpdate()
                    this.loading = false
                })
            }
            
        },
        goConfigBranch(branchIndex){
            this.branchNumber = branchIndex
            this.changeDisplay();
        },
        addBranch(){
            let lastIndex = this.element.children.length-1;
            let newB = this._.cloneDeep(this.newBranch);
            this.element.children.splice(lastIndex, 0, newB)
            this.branchNumber = lastIndex;
            this.changeDisplay();
        },
        addAnd(orIndex){
            let newAnd = this._.cloneDeep(this.andObject);
            this.element.children[this.branchNumber].values[orIndex].push(newAnd)
        },
        removeAnd(orIndex, andIndex){
            this.element.children[this.branchNumber].values[orIndex].splice(andIndex, 1)
        },
        addOr(){
            let newAnd = this._.cloneDeep(this.andObject);
            this.element.children[this.branchNumber].values.push([newAnd])
        },
        removeOr(orIndex) {
            this.element.children[this.branchNumber].values.splice(orIndex, 1)
        },
         changeDisplay(){
            this.displayBranch = !this.displayBranch
            this.displayConfigBranch = !this.displayConfigBranch
        },
        validateForm(){
            if(this.$refs.branchForm.validate()){
               this.changeDisplay()
            }
        },
        changeDisplayAndDeleteCurrentBranch(){
            if(this.element.children[this.branchNumber].branchName == ""){
                this.element.children.splice(this.branchNumber, 1)
            }
            this.changeDisplay();
        }
    },
}
</script>
<style lang="scss">
    .branchDefinition {
        border-radius:5px;
        border: 1px solid rgba(0,0,0,0.1)
    }
</style>