<template>
  <div class="col col-12 pa-0 input-form">
    <span v-if="field.title" class="label"><b>{{$t(field.title)}}</b><span v-if="field.props && field.props.required" style="color:red">&nbsp;*</span></span>
    <v-combobox 
      :label="field.title ? $t(field.title) : ''"
      v-model="model[fieldName]"
      v-bind="field.props"
      :rules=[validate]
    ></v-combobox>
  </div>
</template>

<script>
import validate from '../functions/validations'

export default {
  props: ["model", "fieldName", "field"],
  mixins: [validate],
  data() {
    return {};
  },
};
</script>

<style lang="scss">
</style>