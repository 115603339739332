<template>
  <div class="col col-12 pa-0 input-form">
    <span v-if="field.title" class="label"><b>{{$t(field.title)}}</b><span v-if="field.props && field.props.required" style="color:red">&nbsp;*</span></span>
      <v-autocomplete :items="hours" dense v-model="model[fieldName]" required  clearable solo flat :label="$t('hourLabel')" :no-data-text="$t('no-data-text')">
        <template v-slot:prepend-inner>
            <v-icon small color="primary">
                $alarm_d
            </v-icon>
        </template>
      </v-autocomplete>
  </div>
</template>

<script>
import validate from '../functions/validations'

export default {
  props: ["model", "fieldName", "field"],
  mixins: [validate],
  data() {
    return {
      modal2: false,
      hours: [],
      startHour: 8,
      endHour: 20,
      step: 30
    };
  },
  created() {
    if(this.field.props && this.field.props.startHour && this.field.props.endHour && this.field.props.step){
      this.startHour = this.field.props.startHour
      this.endHour = this.field.props.endHour
      this.step = this.field.props.step
    }
    this.createHours();
  },
  methods: {
    createHours(){
      for (let index = this.startHour; index < this.endHour; index++) {
        let minutesStep = 60 / this.step;
        for (let multiplicateur = 0; multiplicateur < minutesStep; multiplicateur++) {
          let newHour = (index < 10 ? '0' : '')+index+":"+((multiplicateur*this.step < 10) ? "0" : "")+multiplicateur*this.step;
          this.hours.push(newHour);
        }
      }
      this.hours.push((this.endHour < 10 ? '0' : '')+this.endHour+":00");
    },
  },
};
</script>

<style lang="scss">
</style>