<template>
    <div class="pa-4">
        <h2 style="display:flex; align-items:center;">{{$t('configElement')}}</h2>
        <p><b>{{$t('type')}} :</b> {{$t(elementConfig.type)}}</p>
        <div v-if="elementConfig.type == 'notification_email'">
            <label class="label theme--light"><b>{{$t('name')}}</b></label>
            <v-text-field v-model="elementConfig.name" hide-details solo flat dense :label="$t('name')" class="mb-0 input-form"></v-text-field>
            <div class="mb-0 d-flex" style="align-items:center;">
                <v-radio-group v-model="elementConfig.config.destinataire_type">
                    <template v-slot:label>
                         <label class="label theme--light black--text" style="font-size:13px;">{{$t('destinataire')}}</label>
                    </template>
                    <v-radio
                        key="team"
                        :label="$t('team_notif')"
                        value="team"
                    ></v-radio>
                    <v-radio
                        key="personal"
                        :label="$t('personal_notif')"
                        value="personal"
                    ></v-radio>
                </v-radio-group>
            </div>
            <label class="label theme--light" v-show="elementConfig.config.destinataire_type == 'team'"><b>{{$t('notif_recipients')}}</b></label>
            <v-select :items="notifs_recipient" v-show="elementConfig.config.destinataire_type == 'team'" return-object v-model="elementConfig.config.destinataire" :label="$t('notif_recipients')"  solo flat dense hide-details class="mb-3 input-form" :no-data-text="$t('no-data-text')">
                <template v-slot:item="{item, attrs, on}">
                    <v-list-item v-on="on" v-bind="attrs">
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-row no-gutters align="center">
                                        {{$t(item.text)}}
                                </v-row>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <template v-slot:selection="data">
                    {{$t(data.item.text)}}
                </template>
            </v-select>
            <label class="label theme--light" v-show="elementConfig.config.destinataire_type == 'personal'"><b>{{$t('user')}}</b></label>
            <v-select :items="users" v-show="elementConfig.config.destinataire_type == 'personal'" return-object v-model="elementConfig.config.destinataire" :label="$t('user')"  solo flat dense hide-details class="mb-3 input-form" :no-data-text="$t('no-data-text')">
                <template v-slot:selection="data">
                    <span>
                        {{ data.item['to_trad'] ? $t(data.item.text) : data.item.text }}
                    </span>
                </template>
                <template v-slot:item="{item, attrs, on}">
                    <v-list-item v-on="on" v-bind="attrs">
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-row no-gutters align="center">
                                    {{ item['to_trad'] ? $t(item.text) : item.text }}
                                </v-row>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-select>
            <v-checkbox v-model="elementConfig.config.template" :label="$t('useNotifTemplate')" style="background-color:transparent;"></v-checkbox>
            <label class="label theme--light"><b>{{$t('object')}}</b></label>
            <v-text-field v-model="elementConfig.config.objet" hide-details  solo flat dense :label="$t('object')" class="mb-3 input-form"></v-text-field>
            <label class="label theme--light"><b>{{$t('message')}}</b></label>
            <vWysiwyg :model="elementConfig.config" fieldName="message" :field="{props: {variable: true, contexts: workflow.trigger.contexts.concat(additionnalContexts), operationId: operationId}, title: $t('message')}" class="mb-0"/>
            <label class="label theme--light"><b>{{$t('link')}}</b></label>
            <v-text-field v-model="elementConfig.config.link" hide-details  solo flat dense :label="$t('link')" class=" input-form"></v-text-field>
        </div>
        <div v-if="elementConfig.type == 'notification_sms' ||elementConfig.type == 'notification_inapp'">
            <label class="label theme--light"><b>{{$t('name')}}</b></label>
            <v-text-field v-model="elementConfig.name" hide-details solo flat dense :label="$t('name')" class="mb-1 input-form"></v-text-field>
            <div class="mb-0 d-flex" style="align-items:center;">
                <v-radio-group v-model="elementConfig.config.destinataire_type">
                    <template v-slot:label>
                         <label class="label theme--light black--text" style="font-size:13px;">{{$t('destinataire')}}</label>
                    </template>
                    <v-radio
                        key="team"
                        :label="$t('team_notif')"
                        value="team"
                    ></v-radio>
                    <v-radio
                        key="personal"
                        :label="$t('personal_notif')"
                        value="personal"
                    ></v-radio>
                </v-radio-group>
            </div>
            <label class="label theme--light" v-show="elementConfig.config.destinataire_type == 'team'"><b>{{$t('notif_recipients')}}</b></label>
            <v-select :items="notifs_recipient" v-show="elementConfig.config.destinataire_type == 'team'" return-object v-model="elementConfig.config.destinataire" :label="$t('notif_recipients')"  solo flat dense hide-details class="mb-3 input-form" :no-data-text="$t('no-data-text')">
                <template v-slot:item="{item, attrs, on}">
                    <v-list-item v-on="on" v-bind="attrs">
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-row no-gutters align="center">
                                        {{$t(item.text)}}
                                </v-row>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <template v-slot:selection="data">
                    {{$t(data.item.text)}}
                </template>
            </v-select>
            <label class="label theme--light" v-show="elementConfig.config.destinataire_type == 'personal'"><b>{{$t('user')}}</b></label>
            <v-select :items="users" v-show="elementConfig.config.destinataire_type == 'personal'" return-object v-model="elementConfig.config.destinataire" :label="$t('user')"  solo flat dense hide-details class="mb-3 input-form" :no-data-text="$t('no-data-text')">
                <template v-slot:selection="data">
                    <span>
                        {{ data.item['to_trad'] ? $t(data.item.text) : data.item.text }}
                    </span>
                </template>
                <template v-slot:item="{item, attrs, on}">
                    <v-list-item v-on="on" v-bind="attrs">
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-row no-gutters align="center">
                                    {{ item['to_trad'] ? $t(item.text) : item.text }}
                                </v-row>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-select>
            <label class="label theme--light"><b>{{$t('message')}}</b></label>
            <vWysiwyg :model="elementConfig.config" fieldName="message" :field="{props: {variable: true, contexts: workflow.trigger.contexts.concat(additionnalContexts), operationId: operationId}, title: $t('message')}" class="mb-0"/>
            <label class="label theme--light"><b>{{$t('link')}}</b></label>
            <v-text-field v-model="elementConfig.config.link" hide-details  solo flat dense :label="$t('link')" class=" input-form"></v-text-field>
        </div>
        
        <div v-if="elementConfig.type == 'wait_some_time'">
            <label class="label theme--light"><b>{{$t('name')}}</b></label>
             <v-text-field v-model="elementConfig.name" hide-details solo flat dense :label="$t('name')" class="mb-3 input-form"></v-text-field>
            <div style="display:flex;" class=" input-form">
                <div>
                    <b>{{$t('days')}}</b>
                    <v-text-field type="number" solo flat dense depressed v-model="elementConfig.config.days" label="Jours" hide-details></v-text-field>
                </div>
                <div>
                    <b class="mx-2">{{$t('hours')}}</b>
                    <v-text-field type="number" solo flat dense depressed v-model="elementConfig.config.hours" label="Heures" hide-details class="mx-2"></v-text-field>
                </div>
                <div>
                    <b>{{$t('minutes')}}</b>
                    <v-text-field type="number" solo flat dense depressed v-model="elementConfig.config.minutes" label="Minutes" hide-details></v-text-field>
                </div>
            </div>
        </div>
        <div v-if="elementConfig.type == 'wait_date'">
            <label class="label theme--light"><b>{{$t('name')}}</b></label>
             <v-text-field v-model="elementConfig.name" hide-details solo flat dense :label="$t('name')" class="mb-3 input-form"></v-text-field>
             <label class="label theme--light"><b>{{$t('reference')}}</b></label>
            <v-select :items="date_reference" return-object v-model="elementConfig.config.reference" :label="$t('reference')" solo flat dense hide-details class="mb-3 input-form" :no-data-text="$t('no-data-text')">
                <template v-slot:item="{item, attrs, on}">
                    <v-list-item v-on="on" v-bind="attrs">
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-row no-gutters align="center">
                                        {{$t(item.text)}}
                                </v-row>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <template v-slot:selection="data">
                    {{$t(data.item.text)}}
                </template>
            </v-select>
            <v-checkbox v-model="elementConfig.config.active_delai" :label="'Déclencher en amont'"></v-checkbox>
            <div style="display:flex;" v-if="elementConfig.config.active_delai" class="input-form">
                <div>
                    <b>{{$t('days')}}</b>
                    <v-text-field type="number" solo flat dense depressed v-model="elementConfig.config.delai.days" label="Jours" hide-details></v-text-field>
                </div>
                <div>
                    <b class="mx-2">{{$t('hours')}}</b>
                    <v-text-field type="number" solo flat dense depressed v-model="elementConfig.config.delai.hours" label="Heures" hide-details class="mx-2"></v-text-field>
                </div>
                <div>
                    <b>{{$t('minutes')}}</b>
                    <v-text-field type="number" solo flat dense depressed v-model="elementConfig.config.delai.minutes" label="Minutes" hide-details></v-text-field>
                </div>
            </div>
        </div>
        <div v-if="elementConfig.type == 'wait_day_hour'">
            <label class="label theme--light"><b>{{$t('name')}}</b></label>
             <v-text-field v-model="elementConfig.name" hide-details solo flat dense :label="$t('name')" class="mb-3 input-form"></v-text-field>
             <label class="label theme--light"><b>{{$t('day')}}</b></label>
            <v-autocomplete :items="weekdays" multiple v-model="elementConfig.config.day" :placeholder="$t('day')" solo flat dense hide-details class="mb-3" :no-data-text="$t('no-data-text')">
                <template v-slot:item="{item, attrs, on}">
                    <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                        <v-list-item-action>
                            <v-checkbox :ripple="false" :input-value="active"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-row no-gutters align="center">
                                        {{$t(item)}}
                                </v-row>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <template v-slot:selection="data">
                    <span v-if="data.index == 0">
                         {{$t(data.item)}}
                    </span>
                    <span v-else>
                        , {{$t(data.item)}}
                    </span>
                    
                </template>
            </v-autocomplete>
            <label class="label theme--light"><b>{{$t('hour')}}</b></label>
            <v-select :items="hours" v-model="elementConfig.config.hour" placeholder="Heure" solo flat dense hide-details class="mb-3" :no-data-text="$t('no-data-text')">
                <template v-slot:item="{item, attrs, on}">
                    <v-list-item v-on="on" v-bind="attrs">
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-row no-gutters align="center">
                                        {{item == 'same_hour' ? $t(item) : item}}
                                </v-row>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <template v-slot:selection="data">
                    {{data.item == 'same_hour' ? $t(data.item) : data.item}}
                </template>
            </v-select>
        </div>
        <div v-if="elementConfig.type == 'wait_event'">
            <label class="label theme--light"><b>{{$t('name')}}</b></label>
             <v-text-field v-model="elementConfig.name" hide-details  solo flat dense :label="$t('name')" class="mb-3 input-form"></v-text-field>
             <label class="label theme--light"><b>{{$t('event')}}</b></label>
            <v-select :items="waitEventItems" return-object @change="changeWaitEventConfig" v-model="elementConfig.config.event" :label="$t('event')"  solo flat dense hide-details class="mb-3" :no-data-text="$t('no-data-text')">
                <template v-slot:selection="data">
                    {{$t(data.item.text)}}
                </template>
                <template v-slot:item="{item, attrs, on}">
                    <v-list-item v-on="on" v-bind="attrs">
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-row no-gutters align="center">
                                    {{ $t(item.text) }}
                                </v-row>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-select>
            <WorkflowConfigTrigger :hideBtn="true" :workflow="workflow" v-if="waitEventConfig && waitEventConfig.event && waitEventConfig.config" :config="waitEventConfig"/>
        </div>
        <div v-if="elementConfig.type == 'blacklist'">
            <label class="label theme--light"><b>{{$t('name')}}</b></label>
             <v-text-field v-model="elementConfig.name" hide-details  solo flat dense :label="$t('name')" class="mb-3 input-form"></v-text-field>
             <label class="label theme--light"><b>{{$t('canal')}}</b></label>
            <v-autocomplete multiple :items="blacklist_items" return-object v-model="elementConfig.config.canal" :label="$t('canal')"  solo flat dense hide-details class="mb-3" :no-data-text="$t('no-data-text')"></v-autocomplete>
            <label class="label theme--light"><b>{{$t('motif')}}</b></label>
            <v-textarea v-model="elementConfig.config.motif"  solo flat hide-details :label="$t('motif')"></v-textarea>
        </div>
        <div v-if="elementConfig.type == 'add_todo'">
            <label class="label theme--light"><b>{{$t('name')}}</b></label>
            <v-text-field v-model="elementConfig.name" hide-details solo flat dense :label="$t('name')" class="mb-0 input-form"></v-text-field>
            <div class="mb-0 d-flex" style="align-items:center;">
                <v-radio-group v-model="elementConfig.config.destinataire_type">
                    <template v-slot:label>
                         <label class="label theme--light black--text" style="font-size:13px;">{{$t('destinataire')}}</label>
                    </template>
                    <v-radio
                        key="team"
                        :label="$t('team_notif_task')"
                        value="team"
                    ></v-radio>
                    <v-radio
                        key="personal"
                        :label="$t('personal_notif_task')"
                        value="personal"
                    ></v-radio>
                    <v-radio
                        key="to_config"
                        :label="$t('to_config')"
                        value="to_config"
                    ></v-radio>
                </v-radio-group>
            </div>
            <label class="label theme--light" v-show="elementConfig.config.destinataire_type == 'team'"><b>{{$t('notif_recipients')}}</b></label>
            <v-select :items="notifs_recipient" v-if="elementConfig.config.destinataire_type == 'team'" return-object v-model="elementConfig.config.destinataire" :label="$t('notif_recipients')" solo flat dense hide-details class="mb-3 input-form" :no-data-text="$t('no-data-text')">
                <template v-slot:item="{item, attrs, on}">
                    <v-list-item v-on="on" v-bind="attrs">
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-row no-gutters align="center">
                                        {{$t(item.text)}}
                                </v-row>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <template v-slot:selection="data">
                    {{$t(data.item.text)}}
                </template>
            </v-select>
            <label class="label theme--light" v-show="elementConfig.config.destinataire_type == 'personal'"><b>{{$t('user')}}</b></label>
            <v-select :items="users" v-show="elementConfig.config.destinataire_type == 'personal'" return-object v-model="elementConfig.config.destinataire" :label="$t('user')" solo flat dense hide-details class="mb-3 input-form" :no-data-text="$t('no-data-text')">
                <template v-slot:selection="data">
                    <span>
                        {{ data.item['to_trad'] ? $t(data.item.text) : data.item.text }}
                    </span>
                </template>
                <template v-slot:item="{item, attrs, on}">
                    <v-list-item v-on="on" v-bind="attrs">
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-row no-gutters align="center">
                                    {{ item['to_trad'] ? $t(item.text) : item.text }}
                                </v-row>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-select>
            <label class="label theme--light"><b>{{$t('delai')}}</b></label>
            <v-select :items="delais" v-model="elementConfig.config.delai" solo flat dense hide-details  class="mb-3 input-form" :no-data-text="$t('no-data-text')"></v-select>
            <label class="label theme--light"><b>{{$t('comment')}}</b></label>
            <v-textarea v-model="elementConfig.config.comment" solo flat dense hide-details class="mb-3 input-form required" :label="$t('commentTodo')"></v-textarea>
        </div>
        <div v-if="elementConfig.type == 'add_to_list'">
            <label class="label theme--light"><b>{{$t('selectList')}}</b></label>
            <v-select :items="contactListItems" return-object v-model="elementConfig.config.list" :label="$t('selectList')" solo flat dense hide-details class="mb-3 input-form required" :no-data-text="$t('no-data-text')">
                <template v-slot:selection="data">
                    <span>
                        {{ data.item['to_trad'] ? $t(data.item.text) : data.item.text }}
                    </span>
                </template>
                <template v-slot:item="{item, attrs, on}">
                    <v-list-item v-on="on" v-bind="attrs">
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-row no-gutters align="center">
                                    {{ item['to_trad'] ? $t(item.text) : item.text }}
                                </v-row>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-select>
        </div>
        <div v-if="elementConfig.type == 'remove_from_list'">
            <label class="label theme--light"><b>{{$t('selectList')}}</b></label>
            <v-select :items="contactListItems" return-object v-model="elementConfig.config.list" :label="$t('selectList')" solo flat dense hide-details class="mb-3 input-form required" :no-data-text="$t('no-data-text')">
                <template v-slot:selection="data">
                    <span>
                        {{ data.item['to_trad'] ? $t(data.item.text) : data.item.text }}
                    </span>
                </template>
                <template v-slot:item="{item, attrs, on}">
                    <v-list-item v-on="on" v-bind="attrs">
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-row no-gutters align="center">
                                    {{ item['to_trad'] ? $t(item.text) : item.text }}
                                </v-row>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-select>
        </div>

        <div v-if="elementConfig.type == 'external_communication_call'">
            <label class="label theme--light"><b>{{$t('name')}}</b></label>
            <v-text-field v-model="elementConfig.name" hide-details required solo flat dense :label="$t('name')" class="mb-3 input-form required"></v-text-field>
            <label class="label theme--light"><b>{{$t('treatmentTime')}}</b></label>
            <v-select :items="delaiAppeal" return-object v-model="elementConfig.config.delai" :label="$t('treatmentTime')" hide-details required solo flat dense class="mb-3 input-form required">
            </v-select>
            <label class="label theme--light"><b>{{$t('retroplanningconfig.objectivetitle')}}</b></label>
            <v-text-field v-model="elementConfig.config.objective" hide-details required solo flat dense :label="$t('retroplanningconfig.objectivetitle')" class="mb-0 input-form required"></v-text-field>
            <div class="mb-0 d-flex" style="align-items:center;">
                <v-radio-group v-model="elementConfig.config.prestataire_type">
                    <template v-slot:label>
                         <label class="label theme--light black--text" style="font-size:13px;">{{$t('prestataire_type')}}</label>
                    </template>
                    <v-radio
                        key="VENDORS"
                        :label="$t('vendors')"
                        value="VENDORS"
                    ></v-radio>
                    <v-radio
                        key="CALL"
                        :label="$t('call_center')"
                        value="CALL"
                    ></v-radio>
                    <v-radio
                        key="UPYOURLEADS"
                        label="Upyourleads"
                        value="UPYOURLEADS"
                    ></v-radio>
                </v-radio-group>
            </div>
            <label class="label theme--light" v-show="elementConfig.config.prestataire_type == 'UPYOURLEADS'"><b>{{$t('opportunityCampaign')}}</b></label>
            <v-select :items="uyl_campaign" v-show="elementConfig.config.prestataire_type == 'UPYOURLEADS'" return-object v-model="elementConfig.config.uyl_campaign" :label="$t('opportunityCampaign')" solo flat dense hide-details class="mb-3 input-form" :no-data-text="$t('no-data-text')">
                <template v-slot:selection="data">
                    <span>
                        {{ data.item['to_trad'] ? $t(data.item.text) : data.item.text }}
                    </span>
                </template>
                <template v-slot:item="{item, attrs, on}">
                    <v-list-item v-on="on" v-bind="attrs">
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-row no-gutters align="center">
                                    {{ item['to_trad'] ? $t(item.text) : item.text }}
                                </v-row>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-select>
            <label class="label theme--light" v-show="elementConfig.config.prestataire_type == 'CALL'"><b>{{$t('prestataires')}}</b></label>
            <v-select :items="prestataire" v-show="elementConfig.config.prestataire_type == 'CALL'" return-object v-model="elementConfig.config.prestataire" :label="$t('prestataires')" solo flat dense hide-details class="mb-3 input-form" :no-data-text="$t('no-data-text')">
                <template v-slot:selection="data">
                    <span>
                        {{ data.item['to_trad'] ? $t(data.item.text) : data.item.text }}
                    </span>
                </template>
                <template v-slot:item="{item, attrs, on}">
                    <v-list-item v-on="on" v-bind="attrs">
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-row no-gutters align="center">
                                    {{ item['to_trad'] ? $t(item.text) : item.text }}
                                </v-row>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-select>
            <label class="label theme--light" v-show="elementConfig.config.prestataire_type == 'VENDORS'"><b>{{$t('user')}}</b></label>
            <v-select :items="usersCall" return-object v-show="elementConfig.config.prestataire_type == 'VENDORS'" v-model="elementConfig.config.destinataire" :label="$t('user')" solo flat dense hide-details class="mb-3 input-form" :no-data-text="$t('no-data-text')">
                <template v-slot:selection="data">
                    <span>
                        {{ data.item['to_trad'] ? $t(data.item.text) : data.item.text }}
                    </span>
                </template>
                <template v-slot:item="{item, attrs, on}">
                    <v-list-item v-on="on" v-bind="attrs">
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-row no-gutters align="center">
                                    {{ item['to_trad'] ? $t(item.text) : item.text }}
                                </v-row>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-select>
            <label class="label theme--light"><b>{{$t('template')}}</b></label>
            <VCustomLibraryPicker :model="{ 'template': elementConfig.config.template }" fieldName="template" :field="{ 'libType': 'TEL' }" :returnEmit="true" @selectModel="selectModel($event)" :isFromLibraryPage="isFromLibraryPage" />
        </div>

        <div v-if="elementConfig.type == 'external_communication_email' || elementConfig.type == 'external_communication_sms' || elementConfig.type == 'external_communication_mms' || elementConfig.type == 'external_communication_vms'">
            <label class="label theme--light"><b>{{$t('name')}}</b></label>
            <v-text-field v-model="elementConfig.name" hide-details required solo flat dense :label="$t('name')" class="mb-3 input-form required"></v-text-field>
            <label class="label theme--light mb-0"><b>{{$t('template_type')}}</b></label>

            <v-radio-group class="mt-0" v-model="elementConfig.config.template_type">
                <v-radio
                    :label="`Marketing`"
                    value="MARKETING"
                ></v-radio>
                <v-radio
                    :label="`Transactionnel`"
                    value="TRANSACTIONAL"
                ></v-radio>
            </v-radio-group>
            <label class="label theme--light" v-if="!packId || packId != 2"><b>{{$t('exclude')}}</b></label>
            <v-select v-if="!packId || packId != 2" :items="[$t('none'), $t('useralreadybooked'), $t('usernotbooked')]" v-model="elementConfig.config.exclude" solo flat dense hide-details class="mb-3 input-form"></v-select>
            <div v-if="workflow.ope_id || $route.params.operation_id">
                <label class="label theme--light"><b>{{$t('template')}}</b></label>
                <VCustomLibraryPicker :model="{ 'template': elementConfig.config.template }" fieldName="template" :field="{ 'campaign':{'status': 'WORKFLOW'}, 'libType': getLibType(elementConfig), 'libFilters': { 'filters.type': [elementConfig.config.template_type], 'filters.category': elementConfig.config.template_cat ? [elementConfig.config.template_cat.value] : [] } }" :returnEmit="true" @selectModel="selectModel($event)" :isFromLibraryPage="isFromLibraryPage" />
            </div>
            <div v-else>
                <div class="mb-4 mt-4" v-if="!packId || packId != 2">
                    <v-btn-toggle
                    v-model="typeTemplate"
                    color="primary"
                    @change='changeTemplateType'
                    mandatory
                    >
                        <v-btn outlined small style="border-radius: 5px 0 0 5px !important; " :value="'category'">
                            <span style="font-size:14px !important;">{{ $t('templateCategory') }}</span>
                        </v-btn>
                        <v-btn outlined small style="border-radius:  0 5px 5px 0 !important; font-size:12px !important;" :value="'template'">
                            <span style="font-size:14px !important;">{{ $t('template') }}</span>
                        </v-btn>
                    </v-btn-toggle>
                </div>
                <label v-if="typeTemplate == 'category' && (!packId || packId != 2)" class="label theme--light"><b>{{$t('template_category')}}</b></label>
                <v-select :items="template_transac_cat" v-if="typeTemplate == 'category' && elementConfig.config.template_type == 'TRANSACTIONAL' && (!packId || packId != 2)" return-object v-model="elementConfig.config.template_cat" :label="$t('template_category')" solo flat dense hide-details class="mb-3 input-form" :no-data-text="$t('no-data-text')"></v-select>
                <v-select :items="template_market_cat" v-if="typeTemplate == 'category' && elementConfig.config.template_type == 'MARKETING' && (!packId || packId != 2)" return-object v-model="elementConfig.config.template_cat" :label="$t('template_category')" solo flat dense hide-details class="mb-3 input-form" :no-data-text="$t('no-data-text')"></v-select>
                <label v-if="typeTemplate == 'template' || (packId && packId == 2)" class="label theme--light"><b>{{$t('template')}}</b></label>
                <VCustomLibraryPicker v-if="typeTemplate == 'template' || (packId && packId == 2)" :model="{ 'template': elementConfig.config.template }" fieldName="template" :field="{ 'libType': getLibType(elementConfig), 'libFilters': { 'filters.type': [elementConfig.config.template_type], 'filters.category': elementConfig.config.template_cat ? [elementConfig.config.template_cat.value] : [] } }" :returnEmit="true" @selectModel="selectModel($event)" :isFromLibraryPage="isFromLibraryPage" />
            </div>
            
            
        </div>
        <div v-if="elementConfig.type == 'affect_contact'" class=" ">
            <div v-for="(element,index) in affect_contact_items">
                <label class="label theme--light"><b>{{$t('affect_to')}} : </b></label> {{element.entity.social_raison}}
                <v-select :items="element.items" return-object v-model="elementConfig.config.value['entity_'+element.entity.id].user" placeholder="Utilisateur" solo flat dense hide-details class="mb-3" :no-data-text="$t('no-data-text')"></v-select>
            </div>
            
        </div>
        <div v-if="elementConfig.type == 'go_to_step'">
            <label class="label theme--light"><b>{{$t('name')}} </b></label>
            <v-text-field v-model="elementConfig.name" hide-details solo flat dense :label="$t('name')" class="mb-3"></v-text-field>
            <label class="label theme--light"><b>{{$t('branch')}} </b></label>
            <v-select :items="elementConfig.branchAvailable" return-object v-model="elementConfig.config.branch" placeholder="Branche" solo flat dense hide-details class="mb-3" :no-data-text="$t('no-data-text')"></v-select>
        </div>
        <div v-if="elementConfig.type == 'send_lead_touyl'">
            <label class="label theme--light"><b>{{$t('lead_type')}} </b></label>
            <v-select v-model="elementConfig.config.lead_type" placeholder="Type de lead" solo flat dense hide-details class="mb-3" :no-data-text="$t('no-data-text')" :items="['Achat', 'Reprise', 'Atelier']"></v-select>
            <label class="label theme--light"><b>{{$t('univers')}} </b></label>
            <v-select v-model="elementConfig.config.univers" placeholder="Univers" solo flat dense hide-details class="mb-3" :no-data-text="$t('no-data-text')" :items="['Neuf', 'Occasion', 'Atelier', 'Magasin', 'VD', 'Pro', 'Utilitaire']"></v-select>
            <label class="label theme--light"><b>{{$t('description')}} </b></label>
            <v-textarea v-model="elementConfig.config.description" solo flat dense hide-details class="mb-3 input-form required" :label="$t('description')"></v-textarea>
        </div>
        <div v-if="elementConfig.type == 'send_webhook'">
            <label class="label theme--light"><b>{{$t('url')}} </b></label>
            <v-text-field v-model="elementConfig.config.url" hide-details solo flat dense :label="$t('url')" class="mb-3"></v-text-field>
            <label class="label theme--light"><b>{{$t('method')}} </b></label>
            <v-select solo flat depressed return-object dense hide-details :items="['POST', 'GET']" :label="$t('method')" v-model="elementConfig.config.method" class="mb-3" :no-data-text="$t('no-data-text')"></v-select>
            <div class="mb-2">
                <p class="d-flex justify-space-between align-center mb-0"><b>{{$t('headers')}} :</b> <v-btn small text color="primary" class="square" @click="elementConfig.config.headers.push({})"><v-icon small>$plus_l</v-icon></v-btn> </p>
                <div v-for="(header, index) in elementConfig.config.headers" :key="'header_'+index" class="d-flex align-center">
                    <v-text-field v-model="elementConfig.config.headers[index].name" hide-details solo flat dense width="49%" :label="$t('headerName')" class="mb-1 mr-1"></v-text-field>
                    <VCustomVariableTextField class="mb-1 ml-1" :model="elementConfig.config.headers[index]" :operationId="operationId" :contexts="workflow.trigger.contexts.concat(additionnalContexts)" style="width:47%; flex-grow:0;" :fieldName="'value'" :nocols="true" :field="{label :'headerValue' ,props:{solo:true, flat:true, dense:true, 'hide-details':true}}" />
                    <v-btn small text color="primary" class="square" @click="elementConfig.config.headers.splice(index, 1)"><v-icon small>$close</v-icon></v-btn>
                </div>
            </div>
            <div class="mb-2">
                <p class="d-flex justify-space-between align-center mb-0"><b>{{$t('data')}} :</b> <v-btn small text color="primary" class="square" @click="elementConfig.config.data.push({})"><v-icon small>$plus_l</v-icon></v-btn> </p>
                <div v-for="(dat, index) in elementConfig.config.data" :key="'header_'+index" class="d-flex align-center">
                    <v-text-field v-model="elementConfig.config.data[index].name" hide-details solo flat dense style="width:47%; flex-grow:0;" :label="$t('dataName')" class="mb-1 mr-1"></v-text-field> 
                    <VCustomVariableTextField class="mb-1 ml-1" :index="index" :model="elementConfig.config.data[index]" :operationId="operationId" :contexts="workflow.trigger.contexts.concat(additionnalContexts)" style="width:47%; flex-grow:0;" :fieldName="'value'" :nocols="true" :field="{label :'dataValue' ,props:{solo:true, flat:true, dense:true, 'hide-details':true}}" />
                    <v-btn small text color="primary" class="square" @click="elementConfig.config.data.splice(index, 1)"><v-icon small>$close</v-icon></v-btn>
                </div>
            </div>
        </div>
        <div v-if="elementConfig.type == 'manage_points'" class=" input-form">
            <label class="label theme--light"><b>{{$t('name')}} </b></label>
            <v-text-field v-model="elementConfig.name" hide-details solo flat dense :label="$t('name')" class="mb-3"></v-text-field>
            <h2 class="mb-2" style="min-height:18px;">{{$t('ContactPoints')}}</h2>
            <v-text-field type="number" solo flat depressed hide-details dense v-model="elementConfig.config.contact_points"></v-text-field>

            <h2 class="mb-2 mt-6" style="min-height:18px;">{{$t('byInterestPoints')}}</h2>
            <div v-for="(interest, index) in elementConfig.config.interests" :key="'interest_'+index" class="d-flex mb-2">
                <v-select solo flat depressed return-object dense hide-details :items="interests" v-model="elementConfig.config.interests[index].interest" class="mr-2" :no-data-text="$t('no-data-text')"></v-select>
                <v-text-field type="number" solo flat depressed hide-details dense v-model="elementConfig.config.interests[index].points" style="max-width:30%"></v-text-field>
                <v-btn class="square" @click="removeInterest(index, elementConfig.config.interests)" depressed text><v-icon small color="primary">$trashalt_d</v-icon></v-btn>
            </div>
            <v-btn small depressed color="primary" @click="addInterestNode(elementConfig)">{{$t('add')}}</v-btn>
        </div>
        <!-- <div v-if="element.value == 'launchWorkflow'">
            <v-select :items="workflowItem" return-object v-model="element.configElement.config.workflow" placeholder="Workflow" filled dense hide-details class="mb-3"></v-select>
        </div> -->
        <!-- <div v-if="element.value == 'ifpresent'">
            <v-select :items="modulesName" v-model="element.config.module" placeholder="Module" filled dense hide-details class="mb-3"></v-select>
            <v-select :items="getFieldItems(element.config.module)" item-text="value" return-object  v-if="element.config.module != ''" v-model="element.config.field" placeholder="Champ" filled dense hide-details class="mb-3"></v-select>
        </div> -->
        <div v-if="elementConfig.type == 'condition_if'">
            <label class="label theme--light"><b>{{$t('name')}} </b></label>
            <v-text-field solo flat dense  v-model="elementConfig.config.field_01" hide-details :placeholder="'Champ'" class="mb-3"></v-text-field>
            <v-select :items="operators" v-model="elementConfig.config.operator"  placeholder="Opérateur" solo flat dense hide-details class="mb-3" :no-data-text="$t('no-data-text')"></v-select>
            <v-text-field solo flat dense v-model="elementConfig.config.field_02" hide-details :placeholder="'Champ'"></v-text-field>
            <!-- <v-select :items="modulesName" v-model="element.config.module" placeholder="Module" filled dense hide-details class="mb-3"></v-select>
            <v-select :items="getFieldItems(element.config.module)" item-text="value" return-object  v-if="element.config.module != ''" v-model="element.config.field" placeholder="Champ" filled dense hide-details class="mb-3"></v-select>
            <v-select :items="getOperators(element.config.field)" v-model="element.config.operator" v-if="Object.keys(element.config.field).length > 0" placeholder="Opérateur" filled dense hide-details class="mb-3"></v-select>
            <repeaterFieldComponent :type="element.config.field.type" v-if="element.config.operator" v-model="element.config.values"/> -->
        </div>
        <div v-if="elementConfig.type == 'condition_ifelse'">
            <WorkflowElseIfConfigComponent :workflow="workflow" :edit="edit" :element="elementConfig" @addChild="addChild" @updateChild="updateItem" @closeDrawer="$emit('closeDrawer')"/>
        </div>        
         <div class="pa-0 mt-3" v-if="elementConfig.type != 'condition_ifelse'">
            <v-btn small depressed color="primary" @click="$emit('closeDrawer')">Annuler</v-btn>
            <v-btn small depressed color="primary" v-if="elementConfig.id == 0" @click="addChild" class="ml-2">Enregistrer</v-btn>
            <v-btn small depressed color="primary" v-else @click="updateItem" class="ml-2">Mettre à jour</v-btn>
        </div>
        <div v-if="errorText != ''" class="error--text">
            {{$t(errorText)}}
        </div>
    </div>
</template> 
<script>
import VCustomVariableTextField from '@/components/forms/inputs/v-custom-variable-text-field'
import GenericDataService from '@/services/GenericDataService'
import repeaterFieldComponent from '@/components/repeaterFieldComponent'
import WorkflowElseIfConfigComponent from '@/modules/workflow/components/WorkflowElseIfConfigComponent'
import WorkflowSelectTemplateComponent from '@/modules/workflow/components/WorkflowSelectTemplateComponent'
import vCustomVariableTextField from '@/components/forms/inputs/v-custom-variable-text-field'
import vWysiwyg from '@/components/forms/inputs/v-wysiwyg'
import WorkflowConfigTrigger from '@/modules/workflow/components/WorkflowConfigTrigger'
import VCustomLibraryPicker from '../../../components/forms/inputs/v-custom-library-picker.vue';

export default {
    name:"WorkflowConfigElement",
    props: ['element', 'operationId', 'edit', "workflow", "additionnalContexts", "isFromLibraryPage"],
    components: {WorkflowConfigTrigger, repeaterFieldComponent, WorkflowElseIfConfigComponent, vWysiwyg, WorkflowSelectTemplateComponent, VCustomVariableTextField, VCustomLibraryPicker},
    data() { 
        return {
            publicPath: process.env.BASE_URL,
            templateSelectDialog: false,
            typeNotification: ['SMS', 'E-mail', 'InApp'],
            templateAvailable: [],
            typeTodo: ['demandSellAdd', 'demandRepriseAdd', 'demandSavAdd', 'demandOtherAdd', 'demandSubscriptionAdd'],
            waitEventItems: [],
            useTemplate:false,
            workflowItem: [],
            operators: ['>', '<', '=', '!=', 'contient', 'ne contient pas'],
            blacklist_items: ["address", "email", "tel"],
            paramItems: [],
            modules: [],
            fields: [],
            templates: [],
            templateSelect: [],
            notifs_recipient: [],
            template_transac_cat: [], 
            template_market_cat: [],
            users: [],
            prestataire: [],
            message_type:[
                "launchWorkflow",
                "relative"
            ],
            date_reference:[],
            todo_type: ["RDV_TO_CONFIRM", "REMIND", "OPECONF", "PLAN_MISSION", "RESPOND_MISSION", "FEEDBACK_RDV"],
            positionnement:["before", "equal", "after"],
            unite:["minutes", "hour", "days"],
            weekdays:["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"],
            hours:["same_hour", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00"],
            interests: [],
            errorText: "",
            templateThumb: "",
            elementConfig: {},
            typeTemplate:"category",
            delaiAppeal: [
                {
                    text: "30 min",
                    value: "0.5"
                },
                {
                    text: "1h",
                    value: "1"
                },
                {
                    text: "3h",
                    value: "3"
                },
                {
                    text: "1J",
                    value: "24"
                }
            ],
            delais: [
                {
                    text: this.$t("default_delai"),
                    value: "default"
                },
                {
                    text: "15 min",
                    value: "15"
                },
                {
                    text: "30 min",
                    value: "30"
                },
                {
                    text: "45 min",
                    value: "45"
                },
                {
                    text: "1 h",
                    value: "60"
                },
                {
                    text: "1h30",
                    value: "90"
                },
                {
                    text: "2h",
                    value: "120"
                },
                {
                    text: "4h",
                    value: "240"
                },
                {
                    text: "6h",
                    value: "360"
                },
                {
                    text: "1J",
                    value: "1440"
                },
            ],
            contactListItems: [],
            affect_contact_items: [],
            waitEventConfig: {},
            usersCall: [],
            senders: [],
            uyl_campaign: []
        }
    },
    created() {
        GenericDataService.getData('/hard_value/getSelectList?type=senders').then((response) => {
            this.senders = response.data.data
        })
        GenericDataService.getData('/workflow/'+this.workflow.id+'/getSelectListAffectContact').then((response) => {
            this.affect_contact_items = response.data.data
            if(this.affect_contact_items && this.affect_contact_items.length > 0){
                this.affect_contact_items.forEach(element => {
                    if(this.elementConfig && this.elementConfig.config && this.elementConfig.config.value){
                        this.elementConfig.config.value['entity_'+element.entity.id] = {};
                        this.elementConfig.config.value['entity_'+element.entity.id].entity = element.entity
                        this.elementConfig.config.value['entity_'+element.entity.id].user = {}
                    }
                    
                });
            }
        })
        GenericDataService.getData('/workflow/'+this.workflow.id+'/getSelectListContactList').then((response) => {
            this.contactListItems = response.data.data
        })
        GenericDataService.getData('/workflow/'+this.workflow.id+'/getSelectListPrestataireCall').then((response) => {
            this.prestataire = response.data.data
        })
        GenericDataService.getData('/workflow/'+this.workflow.id+'/getCustomWorkflows').then((response)=> {
            this.workflowItem = response.data.data
        })
        GenericDataService.getData('/hard_value/getSelectList?type=notif_recipients').then((response) => {
            this.notifs_recipient = response.data.data
        })
        GenericDataService.getData('/custom_value/getSelectListForTypeAndValue?type=template_cat&value=TRANSACTIONAL').then((response) => {
            this.template_transac_cat = response.data.data
        })
        GenericDataService.getData('/custom_value/getSelectListForTypeAndValue?type=template_cat&value=MARKETING').then((response) => {
            this.template_market_cat = response.data.data
        })
        GenericDataService.getData('/workflow/'+this.workflow.id+'/getUsersList').then((response) => {
            this.users = response.data.data
        })
        GenericDataService.getData('/workflow/'+this.workflow.id+'/getUsersList?type=call').then((response) => {
            this.usersCall = response.data.data
        })
        GenericDataService.getData('/hard_value/getSelectList?type=date_reference').then((response) => {
            this.date_reference = response.data.data
        })
        GenericDataService.getData('/custom_value/getSelectListForType?type=interest').then((response) => {
            this.interests = response.data.data
        })
        GenericDataService.getData('/retroplanning/getSelectUYLOpportunityCampaign').then((response) => {
            this.uyl_campaign = response.data.data
        })
        GenericDataService.getData('/workflow/'+this.workflow.id+'/getTriggerList?is_system=0').then((response) => {
            let events = response.data.data;

            events.forEach((event) => {
                if(event.header) event.header = this.$t(event.header);
            });

            this.waitEventItems = events
        })
        if(this.edit && this.element.config.template && this.element.config.template.hasOwnProperty('id') && (this.element.type == 'external_communication_email' || this.element.type == 'external_communication_sms' || this.element.type == 'external_communication_mms' || this.element.type == 'external_communication_vms')){
            GenericDataService.getData('/template/get?id='+this.element.config.template.id).then((response) => {
                if(this.element.type == 'external_communication_email'){
                    this.templateThumb = response.data.data.template.html
                } else if(this.element.type == 'external_communication_sms' || this.element.type == 'external_communication_mms') {
                    this.templateThumb = response.data.data.template.text
                } else {
                    this.templateThumb = response.data.data.template.mp3.title
                }
                if(this.element.config && !this.element.config.variables){
                    this.element.config.variables = {}
                }
                    
            })
        }
        this.elementConfig = this._.cloneDeep(this.element)
        if(this.elementConfig.config && this.elementConfig.config.template && this.elementConfig.config.template.id)
            this.typeTemplate = 'template'
        if(this.element.type == "wait_event"){
            this.waitEventConfig = this.element.config.event
        }
    },
    computed: {
        packId(){
            if(this.$store.state.auth.currentUser.accountParameters.packId)
                return this.$store.state.auth.currentUser.accountParameters.packId
            else 
                return false
        },
        modulesName() {
            return this._.flatMap(this.modules, (e)=> {
               return e.module
            })
        }
    },
    methods: {
        getLibType(elementConfig) {
            return elementConfig.type == 'external_communication_email' ? 'EMAIL' : (elementConfig.type == 'external_communication_sms' ? 'SMS' : (elementConfig.type == 'external_communication_mms' ?  'MMS' : 'VMS'));
        },
        changeWaitEventConfig(){
            let newWaitEventConfig = {}
                newWaitEventConfig.config = this.elementConfig.config.event.params
                newWaitEventConfig.text = this.elementConfig.config.event.text
                newWaitEventConfig.event = this.elementConfig.config.event.text
                newWaitEventConfig.value = this.elementConfig.config.event.value

            this.waitEventConfig = newWaitEventConfig
            this.elementConfig.config.event = newWaitEventConfig

        },
        // getItems(index, select, element) {
        //     //test si {{workflow_id}}
        //     let url = ""
        //     if(select.hasOwnProperty('depends_of')){
        //         if(element.config[select.depends_of] && element.config[select.depends_of][0]){
        //             url = select.selectUrl.replace('{{depends_id}}', element.config[select.depends_of][0].value)
        //         }else {
        //             url = select.selectUrl.replace('{{depends_id}}', element.config[select.depends_of].value)
        //         }
        //     }
        //     if(select.selectUrl.includes('{{workflow_id}}')){
        //         url = select.selectUrl.replace('{{workflow_id}}', this.$route.params.workflow_id)
        //     } 
        //     GenericDataService.getData(url).then((response) => {
        //         this.paramItems[index] = response.data.data
        //         this.$forceUpdate()
        //     })
        // },
        removeInterest(index, obj){
            obj.splice(index, 1)
        },
        getTemplateHtml(templateId){
            GenericDataService.getData('/template/get?id='+templateId).then((response) => {
                return response.data.data.template.html
            })
        },
        addInterestNode(element){
            let interestNode = {
                                    interest:{},
                                    points:""
                                }
            element.config.interests.push(interestNode)
        },
        selectModel(model){
            let url = '/template/get?id=' + model.id
            if(this.$route.params.operation_id)
                url = '/template/duplicateAndGet?id='+model.id+'&operation_id='+this.$route.params.operation_id
 
            GenericDataService.getData(url).then((response) => {
                let template = response.data.data;

                let templateToRecord = {
                    id: template.id,
                    name: template.name,
                    description: template.description,
                    image: template.image,
                    canal: template.canal
                }

                this.element.config.variables = {}
                // if(template.params.sender){
                //     this.elementConfig.config.variables['sender.sender'] = template.params.sender
                // }

                // if(template.canal == "EMAIL"){
                //     if(template.params.senderName){
                //         this.elementConfig.config.variables['sender.senderName'] = template.params.senderName
                //     }
                //     if(template.params.replyto){
                //         this.elementConfig.config.variables['sender.replyto'] = template.params.replyto
                //     }
                // }
            
                this.element.config.template = templateToRecord
                this.elementConfig.config.template = templateToRecord
                this.templateSelectDialog = false
            });
            
        },
        addChild(){
            switch (this.elementConfig.type) {
                case 'notification_sms':
                case 'notification_inapp':
                case 'notification_email':
                    if(this.elementConfig.config.destinataire == "") {
                        this.errorText = "requiredField"
                        return
                    }
                    break;

                case 'notification':
                    if(this.elementConfig.config.template_cat == "" || this.elementConfig.config.destination == ""){
                        this.errorText = "requiredField"
                        return
                    }
                    break;
                case 'personal_notification':
                    if(this.elementConfig.config.template_cat == "" || this.elementConfig.config.user == ""){
                        this.errorText = "requiredField"
                        return
                    }
                    break;
                case 'external_communication_email':
                case 'external_communication_sms':
                case 'external_communication_vms':
                case 'external_communication_mms':
                case 'external_communication_call':
                    if(this.elementConfig.name == ""){
                        this.errorText = "requiredField"
                        return
                    }
                    break;
                case 'add_to_list':
                case 'remove_from_list':
                    if(this.elementConfig.config.list == ""){
                        this.errorText = "requiredField"
                        return
                    }
                    break;
                case 'addTodo':
                    if(this.elementConfig.config.destination == ""  || this.elementConfig.config.message == "" || this.elementConfig.config.delai.type == ""){
                        this.errorText = "requiredField"
                        return
                    } else {
                        if(this.elementConfig.config.delai.type == "relative"){
                            if(!this.elementConfig.config.delai.reference || !this.elementConfig.config.delai.reference.text || this.elementConfig.config.delai.reference.text == "" || !this.elementConfig.config.delai.position  || this.elementConfig.config.delai.position == ""){
                                this.errorText = "requiredField"
                                return
                            } else if(this.elementConfig.config.delai.position != 'equal') {
                                if(!this.elementConfig.config.delai.number || this.elementConfig.config.delai.number == "" || !this.elementConfig.config.delai.unite || this.elementConfig.config.delai.unite == "")
                                    this.errorText = "requiredField"
                                    return
                            }
                        } 
                    }
                    break;
                case 'wait_date':
                    if(this.elementConfig.config.reference == ""){
                        this.errorText = "requiredField"
                        return
                    }
                    break;
                case 'wait_day_hour':
                    if(this.elementConfig.config.day.length == 0 && (this.elementConfig.config.hour == "" || this.elementConfig.config.hour == "same_hour")){
                        this.errorText = "requiredField"
                        return
                    }
                    break;
                case 'equal':
                    if(this.elementConfig.config.field_01 == ""  || this.elementConfig.config.field_02 == "" || this.elementConfig.config.operator == ""){
                        this.errorText = "requiredField"
                        return
                    }
                    break;
                
            }
            this.$emit('addChild', this.elementConfig)
        },
        updateItem(){
            switch (this.elementConfig.type) {
                case 'notification':
                    if(this.elementConfig.config.template_cat == "" || this.elementConfig.config.destination == ""){
                        this.errorText = "requiredField"
                        return
                    }
                    break;
                case 'personal_notification':
                    if(this.elementConfig.config.template_cat == "" || this.elementConfig.config.user == ""){
                        this.errorText = "requiredField"
                        return
                    }
                    break;
                case 'external_communication_email':
                case 'external_communication_sms':
                case 'external_communication_vms':
                case 'external_communication_mms':
                case 'external_communication_call':
                    if(this.elementConfig.name == ""){
                        this.errorText = "requiredField"
                        return
                    }
                    break;
                case 'add_to_list':
                case 'remove_from_list':
                    if(this.elementConfig.config.list == ""){
                        this.errorText = "requiredField"
                        return
                    }
                    break;
                case 'addTodo':
                    if(this.elementConfig.config.destination == ""  || this.elementConfig.config.message == "" || this.elementConfig.config.delai.type == ""){
                        this.errorText = "requiredField"
                        return
                    } else {
                        if(this.elementConfig.config.delai.type == "relative"){
                            if(!this.elementConfig.config.delai.reference || !this.elementConfig.config.delai.reference.text || this.elementConfig.config.delai.reference.text == "" || !this.elementConfig.config.delai.position  || this.elementConfig.config.delai.position == ""){
                                this.errorText = "requiredField"
                                return
                            } else if(this.elementConfig.config.delai.position != 'equal') {
                                if(!this.elementConfig.config.delai.number || this.elementConfig.config.delai.number == "" || !this.elementConfig.config.delai.unite || this.elementConfig.config.delai.unite == "")
                                    this.errorText = "requiredField"
                                    return
                            }
                        } 
                    }
                    break;
                case 'wait_date':
                    if(this.elementConfig.config.reference == ""){
                        this.errorText = "requiredField"
                        return
                    }
                    break;
                case 'wait_day_hour':
                    if(this.elementConfig.config.day.length == 0 && (this.elementConfig.config.hour == "" || this.elementConfig.config.hour == "same_hour")){
                        this.errorText = "requiredField"
                        return
                    }
                    break;
                case 'equal':
                    if(this.elementConfig.config.field_01 == ""  || this.elementConfig.config.field_02 == "" || this.elementConfig.config.operator == ""){
                        this.errorText = "requiredField"
                        return
                    }
                    break;
                
            }
            this.$emit('updateItem', this.elementConfig)
        },
        loadTemplates(canal){
            if(canal == "E-mail"){
                canal = "EMAIL"
            }
            this.templateSelect = this.templates.filter((e) => e.canal == canal)
            
        },
        getOperators(field){
            let operators = ['=', '!='];
            if(field.type == "string" || field.type == "email" || field.type == "phone"){
                operators.push('contient')
                operators.push('ne contient pas')
            }
            if(field.type == 'number' || field.type == "date"){
                operators.push('>')
                operators.push('<')
            }
            return operators
        },
        getFieldItems(module){
            let moduleFound = this.modules.find((e) => e.module == module)
            return moduleFound.fields
        },
        goToChooseElement(){
            this.$emit('goToChooseElement')
        },
        changeTemplateType($evt){
            if($evt == 'template'){
                this.elementConfig.config.template_cat = ""
            }else {
                this.elementConfig.config.template = {}
            }
        }
    },
}
</script>
<style lang="scss">
    .itemTemplateSelect {
      position: relative;
      height: 200px;
      width: 100%;
      margin-right:1%;
      margin-bottom:15px;
      border: 1px solid rgba(0, 0, 0, 0.13);
      background-color: rgba(0, 0, 0, 0.03);
      cursor: pointer;
    //   &.EMAIL, &.MAILING, &.ENVELOPE {
    //     height:300px;
    //     .itemPreview {
    //       height: 269px;
    //     }
    //   }
      &.create {
        border: 1px solid rgba(9, 87, 125, 0.2);
        background-color: rgba(9, 87, 125, 0.1);
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p {
          margin-bottom:0;
          text-transform: uppercase;
          margin-top:10px;
          font-weight:bold;
        }
      }
      .itemPreview {
        height: 169px ;
        padding:30px;
        display:flex;
        justify-content:center;
        align-items:center;
        position:relative;
        overflow: hidden;
        &.email {
          padding:0;
          align-items: flex-start;
          overflow:hidden;
          embed {
            overflow: hidden;
          }
        }
        &.MMS {
          padding:0px;
          overflow:hidden;
          img {
            width:100%;
            height:auto;
          }
        }
        .template-span {
            top: 0;
            position: absolute;
        }
        
      }
      .itemName {
            height:30px;
            padding: 5px;
            border-top: 1px solid rgba(0, 0, 0, 0.13);
            border-bottom: 1px solid rgba(0, 0, 0, 0.13);
            background-color: white ;
        }
    }
</style>