<template>
  <div class="col col-12 pa-0 input-form v-custom-combobox">
    <span v-if="field.title" class="label"><b>{{$t(field.title)}}</b><span v-if="field.props && field.props.required" style="color:red">&nbsp;*</span></span>
    <v-menu v-if="type === 'date'" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          flat
          solo
          hide-details
          dense
          :label="field.title ? $t(field.title) : ''"
          required
          readonly
          v-model="value"
          class="input-form"
          append-icon="$calendar_alt_l"
          v-bind="attrs"
          @focus="loadData"
          v-on="on"
        >
        </v-text-field>
      </template>
      <v-date-picker v-model="value" no-title :first-day-of-week="1"  :locale="$i18n.locale" @input="menu = false"></v-date-picker>
    </v-menu>

    <v-autocomplete 
      v-else-if="field.props.disable_typing"
      :label="field.title ? $t(field.title) : ''"
      :class="'input-form'"
      v-model="value"
      v-bind="field.props"
      :items="this.items"
      @focus="loadData"
      :loading="loadingData"
      :rules=[validate]
      :type="type"
      solo flat
      :clearable="field.props.clearable == false ? false : true"
      validate-on-blur
      :no-data-text="$t('no-data-text')"
    >
    <template v-slot:selection="data">
          <span>
             {{ data.item['to_trad']  ? $t(data.item.text) : data.item.text }}
          </span>
      </template>
      <template v-slot:item="{item, attrs, on}">
          <v-list-item v-on="on" v-bind="attrs">
              <v-list-item-content>
                  <v-list-item-title>
                      <v-row no-gutters align="center">
                        {{ item['to_trad'] ? $t(item.text) : item.text }}
                      </v-row>
                  </v-list-item-title>
              </v-list-item-content>
          </v-list-item>
      </template>
  
  </v-autocomplete>

    <v-combobox 
      v-else
      :label="field.title ? $t(field.title) : ''"
      :value="value"
      v-bind="field.props"
      :items="this.items"
      @focus="loadData"
      @input="value=$event"
      :loading="loadingData"
      solo flat
      :rules=[validate]
      :type="type"
      validate-on-blur
    >
      <template v-slot:prepend-inner v-if="field.prepend_inner">
        {{ field.prepend_inner.to_trad ? $t(field.prepend_inner.text) : field.prepend_inner.text }}
      </template>
    </v-combobox>
  </div>
</template>

<script>
import axiosHttp from "@/http-common";
import validate from '../functions/validations';


export default {
  components: {},
  props: ["model", "fieldName", "field"],
  mixins: [validate],
  data() {
    return {
      loadingData: null,
      items: [],
      type: 'text',
      value: '',
      menu: 0
    };
  },
  mounted() {

    let inputs = document.getElementsByTagName('input')
    inputs.forEach((input) => {
        input.setAttribute('autocomplete', 'nope');
    })

    if (this.field.props && this.field.props.items) this.items = this.field.props.items;
    this.value = this.model[this.fieldName];

    if(this.value)
        this.loadData();
  },
  watch: {
    value (newVal) {
        // this.model[this.fieldName] = newVal;
        this.$set(this.model, this.fieldName, newVal);
    },
    menu(newVal){
      //if(newVal)
        //this.loadData()
    }
  },
  methods: {
    loadData() {
      if (this.field.selectDataUrl && (this.loadingData === null || this.field.selectDataUrl.match(/{{(.*?)}}/g))) {
        this.items = [];
        this.loadingData = true;

        axiosHttp.http
          .get(this.field.selectDataUrl.replace(/{{(.*?)}}/g, this.replaceFilter))
          .then((response) => {
            this.loadingData = false;
            if (response.data && response.data.data) {
              if(this.field.customBrand || this.fieldName == 'cc') { // J'ai mis ça uniquement pour le selecteur de marques dans l'ajout / édition d'un véhicule d'un contact
                const vm = this;
                this.items = response.data.data
                
              } else {
                if(response.data.data.data) {
                  const vm = this;
                  this.items = response.data.data.data
                }
                if(response.data.data.type) {
                  this.type = response.data.data.type;
                  this.$forceUpdate();
                }
              }
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    // Permet de remplacer les filtres dans l'URL Ajax par les valeurs du model
    replaceFilter(match, fieldName) {
      if(this.model[fieldName])
        return this.model[fieldName]
      return '0';
    },
  }
};
</script>

<style lang="scss">
  .v-custom-combobox {
    .v-input__prepend-inner {
      min-width: fit-content;
    } 
  }
</style>