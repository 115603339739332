<template>
    <div class="pb-4 entrypointsAdd">
        <div class="d-flex align-center">
            <h5 class="mb-0 pb-0 mr-4">{{ $t('retroplanning') }}</h5>
            <v-menu offset-y left v-if="$func.hasRight('operationtype/update')">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn small depressed class="bg-gradient" v-bind="attrs" v-on="on"><v-icon small class="mr-2">$plus_l</v-icon>  {{$t('addStep')}}</v-btn>
                </template>
                <v-list style="z-index:100000" v-for="(subTypes, type) in retroplanning_types" :key="type">
                    <div class="pa-2 pl-4 listTitle">{{ $t(type) }}</div>
                    <v-list-item v-for="(subType) in subTypes" :key="subType" link dense @click="openForm(type, subType)">
                        <v-list-item-title>{{ $t(subType) }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
        <v-row class="mt-2" :key="'rpContainer_'+retroplanningKey">
            <v-col cols="4" v-for="(retroplanning, index) in this.model[this.fieldName]" :key="index" v-if="retroplanning_types[retroplanning.config.type].includes(retroplanning.config.sousType)">
                <v-card class="cardRP withRP">
                    <v-card-title>
                        <div>
                            <h5>
                                {{retroplanning.config.name}}
                                <v-chip v-if="retroplanning.config.reference" class="ml-3" color="pink" dark small>{{$t('referenceStep')}}</v-chip>
                            </h5>
                        </div>
                        <v-spacer></v-spacer>
                        <v-btn text class="square" small v-if="$func.hasRight('operationtype/update')" @click="editItem(retroplanning.id)">
                            <v-icon small>$pencil_d</v-icon>
                        </v-btn>
                        <v-btn v-if="!retroplanning.config.reference && $func.hasRight('operationtype/update')" text class="square" small @click="openConfirm(retroplanning.id)">
                            <v-icon small>$trash_d</v-icon>
                        </v-btn>
                        <v-menu offset-y v-else-if="retroplanning.config.reference && $func.hasRight('operationtype/update')">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn text class="square" small v-bind="attrs" v-on="on"><v-icon small>$redo_d</v-icon></v-btn>
                            </template>
                            <v-list v-for="(subTypes, type) in retroplanning_types" :key="type">
                                <div class="pa-2 pl-4 listTitle">{{ $t(type) }}</div>
                                <v-list-item v-for="(subType) in subTypes" :key="subType" link dense @click="openForm(type, subType, retroplanning.id)">
                                    <v-list-item-title>{{ $t(subType) }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-card-title>
                    <v-card-text>
                        <div class="configretro black--text">
                            <p class="mb-1" v-if="retroplanning.config.compute_date_start">
                                {{$t('start')}} :
                                <span v-if="retroplanning.config.compute_date_start.placement != 'equals'">
                                    <span v-if="retroplanning.config.compute_date_start.type == 'date'">{{retroplanning.config.compute_date_start.days}} {{$t('day_s')}} </span>
                                    <span v-if="retroplanning.config.compute_date_start.type == 'time'">{{getChipsTime(retroplanning.config.compute_date_start.seconds, true)}} </span>
                                    {{$t(retroplanning.config.compute_date_start.placement).toLowerCase()}} {{$t((model['type'] === 'MARKETING' ? 'ref_step_' : 'operation_')+retroplanning.config.compute_date_start.reference).toLowerCase()}} 
                                </span>
                                <span v-else>
                                    {{$t(retroplanning.config.compute_date_start.placement)}} {{$t((model['type'] === 'MARKETING' ? 'ref_step_' : 'operation_')+retroplanning.config.compute_date_start.reference).toLowerCase()}} 
                                </span>
                                <span v-if="retroplanning.config.compute_date_start.placement != 'equals'">
                                    <span v-if="retroplanning.config.compute_date_start.type == 'date'">{{$t('at_time').toLowerCase()}} {{retroplanning.config.compute_date_start.time}} </span>
                                </span>
                                <span v-if="retroplanning.config.compute_date_start.with_off_days">({{$t('with_off_days')}})</span>
                            </p>
                            <p class="mb-1" v-if="retroplanning.config.compute_date_end && retroplanning.config.compute_date_end.placement">
                                {{$t('end')}} :
                                <span v-if="retroplanning.config.compute_date_end.placement != 'equals'">
                                    <span v-if="retroplanning.config.compute_date_end.type == 'date'">{{retroplanning.config.compute_date_end.days}} {{$t('day_s')}} </span>
                                    <span v-if="retroplanning.config.compute_date_end.type == 'time'">{{getChipsTime(retroplanning.config.compute_date_end.seconds, true)}} </span>
                                    {{$t(retroplanning.config.compute_date_end.placement).toLowerCase()}} {{$t((model['type'] === 'MARKETING' ? 'ref_step_' : 'operation_')+retroplanning.config.compute_date_end.reference).toLowerCase()}} 
                                </span>
                                <span v-else>
                                    {{$t(retroplanning.config.compute_date_end.placement)}} {{$t((model['type'] === 'MARKETING' ? 'ref_step_' : 'operation_')+retroplanning.config.compute_date_end.reference).toLowerCase()}} 
                                </span>
                                <span v-if="retroplanning.config.compute_date_end.placement != 'equals'">
                                    <span v-if="retroplanning.config.compute_date_end.type == 'date'">{{$t('at_time').toLowerCase()}} {{retroplanning.config.compute_date_end.time}} </span>
                                </span>
                                <span v-if="retroplanning.config.compute_date_end.with_off_days">({{$t('with_off_days')}})</span>
                            </p>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <FormDialogComponent v-if="form" ref="componentForm" :form="form" :action="'SET_DATA'" :isFromLibraryPage="isFromLibraryPage"/>

        <v-dialog width="600" v-model="dialogConfirm">
            <v-card>
                <v-toolbar flat dark color="primary" class="toolbar bg-gradient" :style="'height:63px;'">
                    <v-toolbar-title>{{$t('confirmDeleteLabel')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn depressed outlined color="white" class="mr-3" @click="closeDialog">
                        {{$t('cancelLabel')}}
                    </v-btn>
                    <v-btn depressed outlined color="white" @click="deleteItem">
                        {{$t('confirmLabel')}}
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pt-3">
                    {{$t('confirmDeleteRetroplanningStepText')}}
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import getForm from "@/mixins/mixins";
import GenericDataService from '@/services/GenericDataService';

export default {
    mixins: [getForm],
    components: {
        FormDialogComponent: () => import("@/components/dialogs/FormDialogComponent"),
    },
    props:['model', 'fieldName', 'field', 'isFromLibraryPage'],
    data() {
        return {
            publicPath: process.env.BASE_URL,
            form: null,
            retroplanning_types: [],
            type: "",
            subType: "",
            showLibraryDialog: false,
            idToDelete: 0,
            dialogConfirm: false,
            retroplanningKey:0
        }
    },
    created() {
        GenericDataService.getData("/operation/getRetroplanningTypes").then((response) => {
            this.retroplanning_types = response.data.data;
        });
    },
    methods: {
        editItem(itemId){
            this.getForm('/operationtype/' + this.model.id + '/getFormRetroplanning?id=' + itemId);
        },
        openForm(type, subType, replaceRefId = null) {
            let url = '/operationtype/' + this.model.id + '/getFormRetroplanning?type=' + type + '&subType=' + subType;

            if(replaceRefId)
                url += '&replaceRefId=' + replaceRefId;

            this.getForm(url);
        },
        openConfirm(itemId){
            this.idToDelete = itemId;
            this.dialogConfirm = true;
        },
        closeDialog(){
            this.idToDelete = 0;
            this.dialogConfirm = false;
        },
        deleteItem(){
            GenericDataService.getData('/operationtype/' + this.model.id + '/deleteRetroplanning?id=' + this.idToDelete).then((response) => {
                this.idToDelete = 0;
                this.dialogConfirm = false;
                this.model[this.fieldName] = response.data.data;
                this.retroplanningKey++;
            })
        },
        customRefresh(response) {
            this.model[this.fieldName] = response.data.data;
            this.retroplanningKey++;
        }
    }
}
</script>

<style lang="scss">
    .entrypointsAdd {
        width:100%;
    }
    .cardRP {
        &::before, &::after {
            display:none;
        }
        h5 {
            font-size:16px;
            padding-bottom:0;
        }
        &.withRP {
            border-left:6px solid var(--v-pink-base)
        }
    }
</style>