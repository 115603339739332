<template>
    <div class="declencheur">
        <div class="itemWorkflow" :id="'item_'+item.id+'_'+uniqueId">
            <div class="headerItem" 
                :class="((item.type == 'trigger' && item.config && item.config.hasOwnProperty('is_config') && !item.config.is_config) || checkIfConfig()) ? 'error lighten-1 white--text' : ((item.type == 'condition_if' || item.type == 'condition_if_child' || item.type == 'condition_ifelse' || item.type == 'condition_ifelse_child' || checkIfConfig()) ? 'warning lighten-1' : 'primary lighten-1 white--text text-center')">
                <span class="ml-3 titleHeader">
                    <v-icon small class="mr-2" v-if="item.icon" :color="(item.type == 'condition_if' || item.type == 'condition_if_child' || item.type == 'condition_ifelse' || item.type == 'condition_ifelse_child') ? 'black' : 'white'">{{item.icon}}</v-icon>
                    <span v-if="item.name && item.name != item.type">{{(item.name)}}</span>
                    <span v-if="!item.event && (item.name == item.type || !item.name ) && item.type!='condition_ifelse_child'">{{$t(item.type)}}</span>
                     <span v-if="!item.event && item.type =='condition_ifelse_child'">{{$t(item.rule)}}</span>
                    <span v-else-if="item.type == 'trigger' && item.declencheur_id == null">
                        <v-icon small color="white" class="mr-2">$leads_d</v-icon>
                        {{$t(item.event)}}
                    </span>
                    <span v-else>{{$t(item.event)}}</span>
                </span>
                <span>
                    <v-btn @click="openTemplateDialog(item)" class="square" small dark text v-if="item.config && item.config.template && item.config.template.id &&(item.type == 'external_communication_mms' || item.type == 'external_communication_vms' || item.type == 'external_communication_sms' || item.type == 'external_communication_email') ">
                        <v-icon small color="white">$eye_d</v-icon>
                    </v-btn>
                    <v-menu offset-y v-if="((item.type != 'trigger' && item.type != 'condition_ifelse_child' && item.type != 'condition_if_child') || (item.type == 'trigger' && item.config && item.config.hasOwnProperty('is_config'))) && !preview">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            :color="(item.type == 'Action') ? 'white' : 'darkgrey'"
                            class="square"
                            dark
                            small
                            text
                            v-bind="attrs"
                            v-on="on"
                            >
                                <v-icon small color="white">$dots_l</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="editItem(item)" link dense>
                                <v-list-item-title dense>
                                    <v-icon small class="mr-2">$pencil_l</v-icon>
                                    <span>Modifier</span>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="moveItem(item)" link dense>
                                <v-list-item-title dense>
                                    <v-icon small class="mr-2">$externallink_d</v-icon>
                                    <span>Déplacer le blocs et ses enfants</span>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="deleteNodeWithoutChild(item.id)" v-if="item.type != 'trigger' && item.type != 'condition_if' && item.type != 'condition_ifelse'" link dense>
                                <v-list-item-title dense>
                                    <v-icon small color="error" class="mr-2">$clear</v-icon>
                                    <span>Supprimer sans ses enfants</span>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item link @click="deleteNodeWithChild(item.id)" v-if="item.type != 'trigger'" dense>
                                <v-list-item-title dense>
                                    <v-icon small color="error" class="mr-2">$clear</v-icon>
                                    <span>Supprimer avec ses enfants</span>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </span>
            </div>
            <div class="content pb-6 pt-2">
                <!-- TRIGGERS -->
                <div v-if="item.type == 'trigger' && item.config && (item.config.is_config || (!item.config.is_config && item.event == 'advert_auto'))">
                    <span v-for="(field, index) in item.config" :key="'property_'+index" >
                        <p class="mb-0" v-if="index != 'is_config' && index != 'km' && index != 'prix' && index != 'distance' && index != 'postal_code' && index != 'department' && index != 'anciennete'">
                            <b v-if="field.value && (field.value != '' || field.value.length > 0)">{{$t(index)}} :</b> 
                            <span v-if="field.value && field.value.hasOwnProperty('text')">
                                {{ field.value.to_trad ? $t(field.value.text) : field.value.text }} 
                            </span>
                            <span v-else-if='field.value && typeof(field.value) == "array" || typeof(field.value) == "object"'>
                                <ul style="list-style:none;">
                                    <li v-for="(fieldValue, index) in field.value" :key="'item_'+index">
                                        {{fieldValue.to_trad ? $t(fieldValue.text) : (fieldValue.text ? fieldValue.text : fieldValue)}}
                                    </li>
                                </ul>
                            </span>
                            <span v-else-if="field.value">
                                {{ field.to_trad ? $t(field.value) : field.value }}
                            </span>
                        </p>
                        
                         <p class="mb-0" v-if="index == 'postal_code' && item.config.localisation.value == 'postal_code'">
                            <b>{{$t(index)}} :</b> 
                            <span v-if="field.value && field.value.hasOwnProperty('text')">
                                {{ field.value.text }} 
                            </span>
                        </p>
                        <p class="mb-0" v-if="index == 'department' && item.config.localisation.value == 'department'">
                            <b>{{$t(index)}} :</b> 
                            <span v-if="field.value && field.value.hasOwnProperty('text')">
                                {{ field.value.text }} 
                            </span>
                            <span v-else-if='field.value && typeof(field.value) == "array" || typeof(field.value) == "object"'>
                                <ul style="list-style:none;">
                                    <li v-for="(fieldValue, index) in field.value" :key="'item_'+index">
                                        {{fieldValue.text ? fieldValue.text : fieldValue}}
                                    </li>
                                </ul>
                            </span>
                        </p>
                        <p class="mb-0" v-if="field.value && index == 'km'">
                            <b>{{$t(index)}} :</b> 
                            <span v-for="(fieldValue, index) in field.value" :key="'item_'+index">
                                {{fieldValue.text ? fieldValue.text : fieldValue}} {{index < (field.value.length - 1) ? '-' : ' km'}}
                            </span>
                        </p>
                        <p class="mb-0" v-if="field.value && index == 'prix'">
                            <b>{{$t(index)}} :</b> 
                            <span v-for="(fieldValue, index) in field.value" :key="'item_'+index">
                                {{fieldValue.text ? fieldValue.text : fieldValue}} {{index < (field.value.length - 1) ? '-' : ' €'}}
                            </span>
                        </p>
                        <p class="mb-0" v-if="field.value && index == 'distance' && item.config.localisation.value == 'distance'">
                            <b>{{$t(index)}} :</b> 
                            <span v-for="(fieldValue, index) in field.value" :key="'item_'+index">
                                {{fieldValue.text ? fieldValue.text : fieldValue}} {{index < (field.value.length - 1) ? '-' : ' km'}}
                            </span>
                        </p>
                        <p class="mb-0" v-if="field.value && index == 'anciennete'">
                            <b>{{$t(index)}} :</b> 
                            <span v-for="(fieldValue, index) in field.value" :key="'item_'+index">
                                {{fieldValue.text ? fieldValue.text : fieldValue}} {{index < (field.value.length - 1) ? '-' : ''}}
                            </span>
                        </p>
                    </span>
                </div>


                <!-- CONDITIONS -->
                <div v-if="item.type == 'condition_if'">
                    <p class="mb-0"><b>{{$t('field01')}} :</b> {{item.config.field_01}} </p>
                    <p class="mb-0"><b>{{$t('operator')}} :</b> {{item.config.operator}} </p>
                    <p class="mb-0"><b>{{$t('field02')}} :</b> {{item.config.field_02}} </p>
                </div>
                <div v-if="item.type == 'condition_if_child'">
                    <span v-for="rule,index in item.rule" :key="'values_'+index">
                        <span v-if="index > 0"> et</span><b> {{rule}}</b>
                    </span>
                </div>
                <div v-if="item.type == 'condition_ifelse_child'">
                    {{item.branchName}}
                </div>
               
                <!-- NOTIFICATIONS INTERNE -->
                <div v-if="item.type == 'notification_email'">
                    <p class="mb-0"><b>{{$t('use_notification_template')}} :</b> {{item.config.template ? $t('yes') : $t('no')}} </p>
                    <p class="mb-0"><b>{{$t('destinataire')}} :</b> {{item.config.destinataire_type == 'team' ? $t(item.config.destinataire.text) : (['currentuser', 'himself, affectedUserOrRandom', 'affectedUserOrLessBusy'].includes(item.config.destinataire.text) ? $t(item.config.destinataire.text) : item.config.destinataire.text)}} </p>
                    <p class="mb-0"><b>{{$t('object')}} :</b> {{item.config.objet}} </p>
                    <p class="mb-0"><b>{{$t('link')}} :</b> {{item.config.link}} </p>
                </div>
                <div v-if="item.type == 'notification_sms' || item.type == 'notification_inapp'">
                    <p class="mb-0"><b>{{$t('destinataire')}} :</b> {{item.config.destinataire_type == 'team' ? $t(item.config.destinataire.text) : (['currentuser', 'himself, affectedUserOrRandom', 'affectedUserOrLessBusy'].includes(item.config.destinataire.text) ? $t(item.config.destinataire.text) : item.config.destinataire.text)}} </p>
                    <p class="mb-0"><b>{{$t('link')}} :</b> {{item.config.link}} </p>
                </div>
                <div v-if="item.type == 'message'">
                    <p class="mb-0"><b>{{$t('template_category')}} :</b> {{item.config.template_cat.text}} </p>
                    <p class="mb-0"><b>{{$t('canal')}} :</b> {{(item.config.canal)}} </p>
                    <p class="mb-0"><b>{{$t('sending_type')}} :</b> {{$t(item.config.delai.type)}} </p>
                    <p class="mb-0" v-if="item.config.delai.type == 'relative'"><b>{{$t('reference')}} :</b> {{$t(item.config.delai.reference.text)}} </p>
                    <p class="mb-0" v-if="item.config.delai.type == 'relative'"><b>{{$t('position')}} :</b> {{$t(item.config.delai.position)}} </p>
                    <p class="mb-0" v-if="item.config.delai.type == 'relative' && item.config.delai.position!='equal'">{{(item.config.delai.number)}} {{$t(item.config.delai.unite)}}</p>
                </div>

                <!-- COMMUNICATION EXTERNES -->
                <div v-if="item.type == 'external_communication_mms' || item.type == 'external_communication_vms' || item.type == 'external_communication_sms' || item.type == 'external_communication_email'">
                    <p class="mb-0" v-if="item.config.template_type != ''"><b>{{$t('template_type')}} :</b> {{$t(item.config.template_type)}} </p>
                    <p class="mb-0" v-if="item.config.template_cat != ''"><b>{{$t('template_cat')}} :</b> {{ item.config.template_cat.text }} </p>
                    <p class="mb-0" v-if="item.config.template && item.config.template.id"><b>{{$t('template')}} :</b> {{ (item.config.template.name)}} </p>
                </div>
                <div v-if="item.type == 'external_communication_call'">
                    <p class="mb-0" v-if="item.config.delai"><b>{{$t('treatmentdelai')}} :</b> {{ item.config.delai.text }} </p>
                    <p class="mb-0" v-if="item.config.objective"><b>{{$t('retroplanningconfig.objectivetitle')}} :</b> {{item.config.objective}} </p>
                    <p class="mb-0" v-if="item.config.prestataire_type != ''"><b>{{$t('prestataire_type')}} :</b> {{$t(item.config.prestataire_type)}} </p>
                    <p class="mb-0" v-if="item.config.prestataire_type == 'CALL'"><b>{{$t('prestataire')}} :</b> {{ item.config.prestataire.text }} </p>
                    <p class="mb-0" v-if="item.config.prestataire_type == 'VENDORS'"><b>{{$t('vendor')}} :</b> {{ (["affectedUserOrLessBusy", "affectedUserOrRandom", "affectedUserOrPool"].includes(item.config.destinataire.text) ? $t(item.config.destinataire.text) : item.config.destinataire.text) }} </p>
                </div>




                <!-- DELAIS -->
                <div v-if="item.type == 'wait_some_time'">
                    <p class="mb-0">{{item.config.days}} {{$t('days')}}</p>
                    <p class="mb-0">{{(item.config.hours)}} {{$t('hours')}}</p>
                    <p class="mb-0">{{(item.config.minutes)}} {{$t('minutes')}}</p>
                </div>
                <div v-if="item.type == 'wait_day_hour'">
                    <p class="mb-0" v-if="item.config.day != ''"><b>{{$t('day')}} :</b> {{$t(item.config.day)}} </p>
                    <p class="mb-0" v-if="item.config.hour != ''"><b>{{$t('hour')}} :</b> {{(item.config.hour)}} </p>
                </div>
                <div v-if="item.type == 'wait_date'">
                    <p class="mb-0"><b>{{$t('reference')}} :</b> {{$t(item.config.reference.text)}} </p>
                    <p class="mb-0" v-if="item.config.active_delai"><b>{{$t('En amont de')}} :</b></p>
                    <p class="mb-0" v-if="item.config.active_delai">{{item.config.delai.days}} {{$t('days')}}</p>
                    <p class="mb-0" v-if="item.config.active_delai">{{(item.config.delai.hours)}} {{$t('hours')}}</p>
                    <p class="mb-0" v-if="item.config.active_delai">{{(item.config.delai.minutes)}} {{$t('minutes')}}</p>
                </div>
                <div v-if="item.type == 'wait_event'">
                    <p class="mb-0" v-for='(property, index) in item.config' :key="'property_'+index"><b>{{$t(index)}} :</b> {{$t(property.text)}} </p>
                </div>

                <!-- ACTIONS -->
                <div v-if="item.type == 'add_todo'">
                    <p class="mb-0"><b>{{$t('type')}} :</b> {{item.config.todo_type}} </p>
                    <p class="mb-0"><b>{{$t('destination')}} :</b> {{item.config.destinataire_type != 'to_config' ? (item.config.destinataire_type == 'team' ? $t(item.config.destinataire) : item.config.destinataire.text) : $t('to_config')}} </p>
                    <p class="mb-0" v-if="item.config.delai.type == 'relative'"><b>{{$t('reference')}} :</b>{{$t(item.config.delai.reference.text)}}</p>
                    <p class="mb-0" v-if="item.config.delai.type == 'relative'"><b>{{$t('position')}} :</b>{{$t(item.config.delai.position)}} </p>
                    <p class="mb-0" v-if="item.config.delai.type == 'relative' && item.config.delai.position!='equal'">{{(item.config.delai.number)}} {{$t(item.config.delai.unite)}}</p>
                </div>
                <div v-if="item.type == 'add_to_list' || item.type == 'remove_from_list'">
                    <p class="mb-0"><b>{{$t('list')}} :</b> {{item.config.list.to_trad ? $t(item.config.list.text) : item.config.list.text}} </p>
                </div>
               <div v-if="item.type == 'blacklist'">
                    <p class="mb-0"><b>{{$t('canal')}} :</b> {{item.config.canal.join(',')}} </p>
                    <p class="mb-0"><b>{{$t('motive')}} :</b> {{item.config.motif}} </p>
                </div>
                <div v-if="item.type == 'go_to_step'">
                    <p class="mb-0"><b>{{$t('branchSelected')}} :</b> {{item.config.branch.text}} </p>
                </div>
                <div v-if="item.type == 'affect_contact'">
                    <p class="mb-0" v-for="(element, index) in item.config.value"><b>{{element.entity.social_raison}} :</b> {{element.user.text}} </p>
                </div>
                <div v-if="item.type == 'send_lead_touyl'">
                    <p class="mb-0"><b>{{$t('univers')}} :</b> {{item.config.univers}} </p>
                    <p class="mb-0"><b>{{$t('description')}} :</b> {{item.config.description}} </p>
                </div>
                <!-- Property CONTACT -->
                <div v-if="item.type == 'manage_points'">
                    <p class="mb-0" v-if="item.config.contact_points"><b>{{$t('contact_points')}} :</b> {{item.config.contact_points}} </p>
                    <p class="mb-0 mt-2" v-if="item.config.interests.length > 0"><b>{{$t('byInterest')}}</b></p>
                    <p class="mb-0" v-for="(interest, index) in item.config.interests" :key="'interest_'+index"><b>{{interest.interest.text}} :</b> {{interest.points}} </p>
                </div>

                <!-- <div class="mt-2" v-if="stats[item.id] && stats[item.id].waiting" style="position:absolute; right:20px; bottom:3px;" v-bind="attrs" v-on="on">
                    <v-badge
                    color="pink"
                    :content="stats[item.id].waiting"
                    
                    >
                    </v-badge>                           
                </div> -->
                <!-- Gestion contacts en attente via stats -->
                 <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <div v-if="stats[item.id] && stats[item.id].waiting" class="mt-2" style="position:absolute; right:20px; bottom:3px;" v-bind="attrs" v-on="on">
                                <v-badge
                                color="pink"
                                :content="stats[item.id].waiting"
                                
                                >
                                </v-badge>                           
                            </div>
                        </template>
                    <span>
                        {{$t('waitingContactWf')}}
                    </span>
                </v-tooltip>  

            </div>
            <v-btn fab x-small depressed color="primary" class="btnAddItem" v-if="(item.type != 'condition_if' && item.type != 'condition_ifelse' && !preview)" @click="addChild(item.id)"><v-icon small>$plus_r</v-icon></v-btn>
            <!-- <v-btn fab x-small depressed color="primary" class="btnAddItem" v-if="(item.type == 'trigger' && item.config && item.config.is_config) || (item.type == 'trigger' && !item.config)" @click="addChild(item.id)"><v-icon small>$plus_r</v-icon></v-btn> -->
        </div>
        <div class="children" v-if="item.children && item.children.length > 0">
            <WorkflowItemComponent :uniqueId="uniqueId" :preview="preview" v-for="(child, index) in item.children" :key="index" :stats="stats" :workflow="workflow" :item="child" @editItem="editItem" @moveItem="moveItem" @addChild="addChild" @deleteNodeWithoutChild="deleteNodeWithoutChild" @deleteNodeWithChild="deleteNodeWithChild" />
        </div>
        <div class="children" v-else>
            <div class="declencheur">
                <div :id="'item_'+item.id+'_final_'+uniqueId" class="itemFinalWorkflow d-flex align-center justify-center">
                    <v-icon class="white--text">$flag_l</v-icon>
                    <b class="white--text ml-3">{{$t('end')}}</b>
                </div>
            </div>
        </div>
    
        <FormDialogComponent
            v-if="form"
            ref="componentForm"
            :form="form"
            :action="'SET_DATA'" />
        <LibraryPreviewComponent v-if="previewModel" :libType="libType" :model="previewModel" :openPreviewDialog="openPreviewDialog" @close="openPreviewDialog = false"/>
    </div>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
import WorkflowItemComponent from '@/modules/workflow/components/WorkflowItemComponent'
import templateDetailComponent from '@/components/templates/templateDetailComponent'
import FormDialogComponent from '@/components/dialogs/FormDialogComponent'
import getForm from '@/mixins/mixins';
import refreshData from '@/mixins/mixins';
export default {
    name:"WorkflowItemComponent",
    components: {
        WorkflowItemComponent, 
        LibraryPreviewComponent: () => import("@/components/LibraryPreviewComponent"),
        FormDialogComponent
    },
    props:['item', 'workflow', 'stats','preview', 'uniqueId'],
    mixins: [ getForm , refreshData],
    data() {
        return {
            templateDialog : false,
            publicPath: process.env.BASE_URL,
            templateSelected: {},
            clickedCampaign: {},
            templateKey:0,
            form: null,
            libType:"",
            previewModel:false,
            openPreviewDialog:false
        }
    },
    methods: {
        openTemplateDialog(wf){
            this.libType = wf.config.template.canal
            this.previewModel = wf.config.template
            this.openPreviewDialog = true;
            // GenericDataService.getData('/retroplanning/' + wf.retroplanning_id + '/getCompiledTemplate?id=' + wf.config.template.id).then((response) => {
            //     this.templateSelected = response.data.data;
            //     GenericDataService.getData('/retroplanning/'+wf.retroplanning_id+'/get').then((response) => {
            //         this.clickedCampaign = response.data.data;
            //         this.templateKey++
            //         this.templateDialog = true;
            //     })
            // });
        },
        customRefresh(){
            this.refreshTemplate()
        },
        refreshTemplate(){
            GenericDataService.getData('/retroplanning/' +  this.item.retroplanning_id + '/getCompiledTemplate?id=' + this.item.config.template.id).then((response) => {
                if(response.data.data){
                    this.templateSelected = response.data.data;
                    //this.loader = false;
                }
            });
        },
        addEntryPoint($evt){
            this.getForm($evt);
        },
        closeTemplateDetailDialog(){
            this.templateDialog = false;
        },
        editItem($event){
            this.$emit('editItem', $event)
        }, 
        deleteNodeWithChild($event){
            this.$emit('deleteNodeWithChild', $event)
        },
        deleteNodeWithoutChild($event){
            this.$emit('deleteNodeWithoutChild', $event)
        },
        addChild($event){
            this.$emit('addChild', $event)
        },
        moveItem($event){
            this.$emit('moveItem', $event)
        },
        checkIfConfig(){
            let to_config = false
            if(this.item.config && this.workflow.ope_id){
                if(this.item.event == "advert_auto" && this.workflow.tobeconfig == '1'){
                    to_config = true
                }
                for (const key in this.item.config) {
                    if(key != "is_config"){
                        const element = this.item.config[key];
                        if(element.value){
                            if(element.value.text && element.value.text == 'to_config'){
                                to_config = true
                            } else if( element.value && element.value == 'to_config'){
                                to_config = true
                            }
                        }
                    }
                }
                if (this.item.type.includes('external_communication') && this.item.config && (!this.item.config.template || this.item.config.template == '')){
                    to_config = true
                }
            } else if (this.item.values && this.item.type == "condition_ifelse_child" && JSON.stringify(this.item.values).includes("to_config") && this.workflow.ope_id){
                to_config = true
            } else if (this.item.type.includes('external_communication') && this.item.config && (!this.item.config.template || this.item.config.template == '')){
                to_config = true
            } 

            if(this.item && this.item.config) {
                for (const key in this.item.config) {
                    if(key != "is_config"){
                        const element = this.item.config[key];
                        if(element.value){
                            if(element.value.text && element.value.text == 'to_config'){
                                to_config = true
                            } else if( element.value && element.value == 'to_config'){
                                to_config = true
                            }
                        }
                        if(element.config){
                            for (const key in element.config){
                                const element2 = element.config[key];
                                if(element2.value){
                                    if(element2.value.text && element2.value.text == 'to_config'){
                                        to_config = true
                                    } else if( element2.value && element2.value == 'to_config'){
                                        to_config = true
                                    }
                                }
                            }
                        }
                    }
                }
            }

            return to_config
        }
    },
}
</script>
<style lang="scss">
    .itemWorkflow, .itemFinalWorkflow {
        width:300px;
        display:inline-block;
        min-height:40px;
        text-align:center;
        position:relative;
        background-color:#FFF;
        border-radius:5px;
        margin-top:50px;
        border: 1px solid #DDD;
        z-index: 5;
        .headerItem {
            background-color: #EEE;
            border-radius: 5px 5px 0 0;
            display:flex;
            align-items: center;
            min-height:28px;
            justify-content: space-between;
            .titleHeader {
                display:flex;
                align-items: center;
            }
        }
        .btnAddItem {
            position:absolute;
            top: calc( 100% - 15px);
            left:50%;
            transform: translateX(-50%);
            width:25px !important;
            height:25px !important;
        }
    }

    .itemFinalWorkflow {
        width: 150px;
        background-color:#777;
        border: 1px solid #777;
    }

    .children {
        
        display:flex;
        justify-content: center;
    }
</style>