<template>
    <div >
        <v-menu :value="shown" allow-overflow offset-y min-width="500" :attach="(attach ? attach : false)" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
                <span  v-bind="attrs" v-on="on" @click="shown = true">
                <v-text-field hide-details v-bind="props" v-model="textModel" class="pt-0 mt-0" :label="$t('selectDateRange')">
                    <template v-slot:append>
                        <div>
                            <v-icon @click="clearField" small v-if="value.start">
                                $close
                            </v-icon>
                            <v-icon small class="ml-1 calendarIcon">
                                $calendar_d
                            </v-icon>
                        </div>
                    </template>
                </v-text-field>
                </span>
            </template>
        <div class="menuPeriod">
                <v-row dense>
                    <v-col>
                        <vc-date-picker update-on-input @input="inputTest" :value='value' :model-config="modelConfig" is-range ref="rangePicker" class="pa-2 calendarInput"/>
                    </v-col>
                    <v-col>
                        <v-list class="presetDiv">
                            <v-list-item-group class="presetDivGroup">
                                <v-list-item @click="changeDayDate(90, true)">
                                    {{$t('nextNinetyDays')}}
                                </v-list-item>
                                <v-list-item @click="getEndMonth()">
                                    {{$t('endMonth')}}
                                </v-list-item>
                                <v-list-item @click="getNextWeek()">
                                    {{$t('nextWeek')}}
                                </v-list-item>
                                <v-list-item @click="changeDayDate(0)">
                                    {{$t('today')}}
                                </v-list-item>
                                <v-list-item @click="getYesterday()">
                                    {{$t('yesterday')}}
                                </v-list-item>
                                <v-list-item @click="getThisWeek()">
                                    {{$t('thisWeekFromMonday')}}
                                </v-list-item>
                                <v-list-item @click="changeDayDate(7)">
                                    {{$t('lastSevenDays')}}
                                </v-list-item>
                                <v-list-item @click="getLastWeek()">
                                    {{$t('lastWeek')}}
                                </v-list-item>
                                <v-list-item @click="changeDayDate(14)">
                                    {{$t('lastFourteenDays')}}
                                </v-list-item>
                                <v-list-item @click="getThisMonth()">
                                    {{$t('thisMonth')}}
                                </v-list-item>
                                <v-list-item @click="getLastMonth()">
                                    {{$t('lastMonth')}}
                                </v-list-item>
                                <v-list-item @click="changeDayDate(30)">
                                    {{$t('lastThirtyDays')}}
                                </v-list-item>
                                <v-list-item @click="changeDayDate(90)">
                                    {{$t('lastNinetyDays')}}
                                </v-list-item>
                                <v-list-item @click="changeDayDate(180)">
                                    {{$t('lastOneEightyDays')}}
                                </v-list-item>
                                <v-list-item @click="getThisYear()">
                                    {{$t('thisYear')}}
                                </v-list-item>
                                 <v-list-item @click="changeDayDate(365)">
                                    {{$t('slidingYear')}}
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-col>
                </v-row>
        </div>
        </v-menu>
    </div>
</template>

<script>
import validate from '../functions/validations'
import dateManipulation from '@/mixins/dateManipulation'
export default {
  props: [ "value", "attach", "props"],
  mixins: [validate, dateManipulation],
  data() {
    return {
      datetime: "",
      shown:false,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD', // Uses 'iso' if missing
      },
      textFieldProps: {},
    };
  },
  computed: {
      textModel(){
          if(this.value && this.value.start && this.value.end){
              return this.parseUsDateToFr(this.value.start) +" - "+ this.parseUsDateToFr(this.value.end)
          }

      }
  },
  watch: {
    shown(val){
    },
    value(val){
        //this.$emit('change')
    }
  },
  methods: {
    getNextWeek(){
        let start = this.getMonday(new Date())
         new Date(start.setDate(start.getDate() + 7));
        let end = this.getMonday(new Date())
        new Date(end.setDate(end.getDate() + 13));

        //let end = start.setDate(start.getDate() + 6)
        this.refreshCalendar(start, end)
    },
    getYesterday(){
        let yesterday = new Date()
        yesterday.setDate(yesterday.getDate() - 1);
        this.refreshCalendar(yesterday, yesterday)
    },
    changeDayDate(days, future = false){
        let end = new Date()
        let start = new Date()
        if(future){
            end.setDate(end.getDate() + days);
        } else {
            start.setDate(start.getDate() - days);
        }
        this.refreshCalendar(start, end)
    },
    getThisWeek(){
        let end = this.getMonday(new Date())
        end.setDate(end.getDate() + 6);
        let start = this.getMonday(new Date());
        this.refreshCalendar(start, end)
    },
    getThisMonth(){
        let end = new Date()
        //et end = new Date(today.getFullYear(), today.getMonth(), 32);
        let start = new Date()
        start.setDate(1)
        end.setMonth(end.getMonth() + 1)
        end.setDate(0)
        //end.setDate(31)
        this.refreshCalendar(start, end)
    },
    getEndMonth(){
        let end = new Date()
        end.setDate(31)
        let start = new Date()
        this.refreshCalendar(start, end)
    },
    getLastMonth(){
        let end = new Date()
        end.setDate(1)
        end.setDate(end.getDate() - 1);

        let start = new Date()
        start.setDate(1)
        start.setDate(start.getDate() - 1);
        start.setDate(1)
        this.refreshCalendar(start, end)
    },
    getThisYear(){
        let end = new Date()
        let start = new Date()
        start.setMonth(0)
        start.setDate(1)

        end.setMonth(11)
        end.setDate(31) 

        this.refreshCalendar(start, end)
    },
    getLastWeek(){
        let end = this.getMonday(new Date())
        end.setDate(end.getDate() - 1);
        let start = this.getMonday(end)
        this.refreshCalendar(start, end)
    },
    getMonday(d) {
        d = new Date(d);
        var day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    },
    refreshCalendar(start, end){
        this.$refs.rangePicker.value_ = {}
        this.$refs.rangePicker.value_.start = start
        this.$refs.rangePicker.value_.end = end
        this.$refs.rangePicker.move(start)

        let val = {}
        val.start = start.toISOString().split('T')[0]
        val.end = end.toISOString().split('T')[0]
        this.$emit('input', val)
        this.$emit('change')

        this.$refs.rangePicker.$forceUpdate()
        this.shown = false
    },
    inputTest($event){
        this.$emit('input', $event)
        this.$emit('change')
        this.shown = false
    },
    clearField(){
        this.$emit('input', {})
        this.$emit('change')
    }
  }
};
</script>

<style lang="scss">
    .btnRangeDate {
        height:24px !important;
        text-transform: none !important;
    }
    .calendarIcon {
        cursor: pointer;
    }
    .menuPeriod {
        overflow:hidden; 
        margin-top:1px; 
        background-color:#FFF;
        .calendarInput {
            background-color:transparent; 
            border:none;
        }
    }
    .presetDiv{
        overflow:hidden; 
        overflow-y:scroll; 
        width:100%; 
        height:100%; 
        position:relative; 
        border-left:1px solid rgba(0,0,0,0.2);
        .presetDivGroup {
            position:absolute; 
            display:flex; 
            flex-direction:column; 
            width:100%;
        }
        &::-webkit-scrollbar-track
        {
            -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar
        {
            width: 10px;
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar-thumb
        {
            background-color: var(--v-primary-base);
        }
        .v-list-item--link:before{
            opacity:0;
        }
        .v-list-item--link:hover, .v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover{
            background-color: var(--v-primary-base);
            color:white !important;
            opacity:1;
            // &:hover{
            //     background-color: var(--v-primary-base);
            //     opacity:1;
            // }
        }
    }
</style>
