<template>
  <div class="v-array-of-objects col col-12 pa-0 mb-6  input-form">
    <div class="v-input">
      <label class="label theme--light">{{field.title ? $t(field.title) : ''}}</label>
      <v-spacer></v-spacer>
      <v-btn small depressed color="primary" class="square" @click="openCreateEditModal()">
        <v-icon dark small> $plus_l </v-icon>
      </v-btn>
    </div>
    <div class="v-row" v-if="model.phones && model.phones.length > 0">
      <div class="pt-2" v-for="(phone, index) in model.phones" :key="index">
        <div @click="openCreateEditModal(index)">
          <v-card elevation="0" height="38" class="no-shadow">
            <v-card-text style="height:38px;" class="pa-2 d-flex align-center">
              <div class="row">
                <div class="col-10 py-0 v-card--disabled ma-auto">
                  <div class="row">
                    <div class="col-4">
                      <v-chip color="info" small outlined class="chips" v-html="getSelectTxt(phone.type)"></v-chip>
                    </div>
                    <div class="col-8 d-flex align-center">
                      {{phoneNumberToClean(phone.number)}}
                    </div>
                  </div>
                </div>
                <div class="col-2 pa-0 ma-auto text-right">
                  <v-btn depressed icon @click.stop="openConfirmModal(index)">
                    <v-icon color="error" small> $trash_l </v-icon>
                  </v-btn>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
    <div v-else>
      <v-alert class="mt-2 mb-0" border="left" color="info" text dense>
        {{$t('emptyPhoneArrayInfoMessage')}}
      </v-alert>
    </div>
    <ConfirmDialogComponent ref="confirmDelete" />
    <VPhoneArrayModal ref="createEditModal" />
  </div>
</template>

<script>
import ConfirmDialogComponent from '@/components/dialogs/ConfirmDialogComponent';
import VPhoneArrayModal from "@/components/forms/inputs/v-phone-array-modal";
import dateManipulation from '@/mixins/dateManipulation'

export default {
  components: {
    ConfirmDialogComponent,
    VPhoneArrayModal,
  },
  props: ["model", "fieldName", "field"],
  mixins: [dateManipulation],
  data() {
    return {
      options: {
        showDialCode: true,
        tabIndex: 0,
      },
    };
  },
  created() {
    if(!this.model.phones){
      this.model.phones = [];
    }
  },
  methods: {
    //ouverture de la modale de confirmation de delete
    async openConfirmModal(index){
      if(await this.$refs.confirmDelete.open(this.$t("confirmDelete"), this.$t("confirmDeleteMessage"), {deletable:true})) {
        this.model.phones.splice(index, 1);
        this.$forceUpdate(); 
      }
    },
    //ouverture de la modale de création / édition
    async openCreateEditModal(index = null){
      let model = {};
      if(index !== null && this.model.phones && this.model.phones[index]) {
        model = {
          "type": this.model.phones[index].type ? this.model.phones[index].type : '',
          "number": this.model.phones[index].number ? this.model.phones[index].number : '',
        };
      }

      if(await this.$refs.createEditModal.open(model, this.fieldName, this.field.fields.phone)) {
        if(index !== null && this.model.phones && this.model.phones[index]) {
          this.model.phones[index].type = model.type ? model.type : this.model.phones[index].type;
          this.model.phones[index].number = model.number ? model.number : this.model.phones[index].number;
          this.$forceUpdate(); 
        } else {
          if(model.type && model.number) {
            this.model.phones.push(model);
            this.$forceUpdate(); 
          }
        }
      }
    },
    getSelectTxt(value) {
      var txt = "";
      var item = this.field.fields.phone.fields.type.props.items.filter(function (ele) {
        return ele.value == value;
      });
      if (item.length) txt = this.$t(item[0].text);
      return txt;
    }
  }
};
</script>

<style lang="scss">
.v-input {
      .label{
        font-weight: 700 !important;
      }
    }
.v-array-of-objects {
  .v-card {
    background-color: rgba(0, 0, 0, 0.06) !important;
    // border-bottom-left-radius: 0px;
    // border-bottom-right-radius: 0px;
    &:hover {
      background: rgba(0, 0, 0, 0.12);
      cursor: pointer;
      &::before {
        //border-color: rgba(0, 0, 0, 0.87);
      }
    }
    // &::before {
    //   bottom: -1px;
    //   content: "";
    //   left: 0;
    //   position: absolute;
    //   transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    //   width: 100%;
    //   border-style: solid;
    //   border-width: thin 0 0 0;
    //   border-color: rgba(0, 0, 0, 0.42);
    // }
    
    .v-card--disabled {
      .v-text-field__details {
        display: none;
      }
    }
  }
}
</style>