<template>
  <div class="v-array col col-12 pa-0 mb-6 input-form">
    <div class="d-flex align-center pb-4">
      <span v-if="field.title" class="label mb-0"><b>{{field.title_not_to_trad ? field.title : $t(field.title)}}</b><span v-if="field.props && field.props.required" style="color:red">&nbsp;*</span></span>
      <div class="ml-4">
        <v-btn small depressed color="primary"  @click="addElemToArray()">
          <v-icon left dark small> $plus_l </v-icon> {{ $t(field.buttonTitle ? field.buttonTitle : "add") }}
        </v-btn>
      </div>
    </div>
    <div class="v-row" v-if="model[fieldName] && model[fieldName].length > 0" :key="key">
      <div v-for="(item, index) in model[fieldName]" :key="index" class="mb-2">
          <div class="row d-flex align-center">
            <div class="col py-2 ma-auto">
              <div class="row">
                <div :class="'col-12'" >
                  <v-text-field
                    :label="field.title ? $t(field.title) : ''"
                    v-model="model[fieldName][index]"
                    v-bind="field.props"
                    hide-details
                    class="input-form"
                    ></v-text-field>
                </div>
              </div>
            </div>
            <div class="">
              <v-btn depressed icon @click.stop="openConfirmModal(index)">
                <v-icon color="error" small> $trash_l </v-icon>
              </v-btn>
            </div>
          </div>
      </div>
    </div>

    <div v-else>
      <v-alert class="mt-2 mb-0" border="left" color="info" text dense>
        {{ $t("emptyArrayInfoMessage") }}
      </v-alert>
    </div>

    <ConfirmDialogComponent ref="confirmDelete" />
  </div>
</template>

<script>
import ConfirmDialogComponent from "@/components/dialogs/ConfirmDialogComponent"

export default {
  name: "VArrayText",
  components: {
    ConfirmDialogComponent,
    FormComponentList: () => import('@/components/forms/FormComponentList')
  },
  props: ["model", "fieldName", "field"],
  data() {
    return {
      key: 0
    };
  },
  created() {
    //if (!this.model[this.fieldName] || typeof(this.model[this.fieldName]) === "object") {
    if (!this.model[this.fieldName]) {
      this.model[this.fieldName] = [];
    }
  },
  methods: {
    //ouverture de la modale de confirmation de delete
    async openConfirmModal(index) {
      if (await this.$refs.confirmDelete.open(this.$t("confirmDelete"), this.$t("confirmDeleteMessage"))) {
        this.model[this.fieldName].splice(index, 1);
        this.key++;
      }
    },
    addElemToArray() {
       //if (!this.model[this.fieldName] || typeof(this.model[this.fieldName]) === "object") {
    if (!this.model[this.fieldName]) {
        this.model[this.fieldName] = [];
      }
      this.model[this.fieldName].push("");
      this.$forceUpdate();
    },
  },
};
</script>
