<template>
    <v-dialog scrollable class="rounded-lg" persistent fullscreen v-model="displayUpload">
        <v-card >
            <v-toolbar tile flat dark  class="bg-gradient" height="64" style="height:80px;">
                <div style="display:flex; width:100%; height:64px; align-items:center; justify-content:space-between;">
                    <div class="d-flex align-center">
                        <v-btn depressed color="transparent" class="square mr-2" v-if="stepper > 1" @click.native="goBack"><v-icon dark small>$prev</v-icon></v-btn>
                        <v-toolbar-title class="d-flex align-center">{{$t(titles[stepper - 1])}}</v-toolbar-title>
                    </div>
                    <div>
                        <v-btn outlined dark depressed @click.native="cancelUpload">ANNULER</v-btn>
                        <v-btn outlined dark depressed v-if="stepper > 1" @click="nextTab" class="ml-3">SUIVANT</v-btn>
                    </div>
                </div> 
            </v-toolbar>
            <v-card-text class="pa-0">
                <v-stepper v-model="stepper" flat tile alt-labels class="no-shadow" style="height:100%; min-height:100%;">
                    <v-stepper-header class="no-shadow headerStepper" style="height:100px;">
                        <v-stepper-step
                            :complete="stepper > 1"
                            step="1"
                        >
                            {{$t(titles[0])}}
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step
                            :complete="stepper > 2"
                            step="2"
                            class="text-center"
                        >
                           {{$t(titles[1])}}
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step :complete="stepper > 3"
                            step="3"
                            class="text-center">
                            {{$t(titles[2])}}
                        </v-stepper-step>
                       
                    </v-stepper-header>
                
                    <v-stepper-items style="height: calc( 100% - 100px) !important;">
                        <v-stepper-content step="1" class="pt-0" style="height:100%;">
                            <v-card
                            class="mb-12 no-shadow rounded-lg no-shadow"
                            color="grey lighten-4"
                            height="100%"
                            style="height:100%;"
                            flat
                            tile
                            >
                            <v-card-text 
                                class="pa-0 cardUpload"
                                @drop.prevent="onDrop($event)"
                                @dragenter.prevent="dragover = true"
                                @dragover.prevent="dragover = true"
                                @dragleave.prevent="dragover = false">
                               <div class="uploadContent">
                                   <div>
                                        <v-icon x-large :color="fileToUpload.name ? 'success' : ''" class="cloudIcon mb-2">$cloud_s</v-icon>
                                        <v-progress-circular
                                        v-if="loading"
                                        :size="50"
                                        :width="7"
                                        color="success"
                                        class="ml-4"
                                        indeterminate
                                        ></v-progress-circular>
                                   </div>
                                   <h1 v-if="fileToUpload.name" class="success--text">Fichier sélectionné : {{fileToUpload.name}}</h1>
                                   
                                   <h1>Déposer votre fichier ici</h1>
                                   <p>ou</p>
                                   <v-btn depressed color="primary" @click="selectFile">Choisir un fichier</v-btn>
                                   <input
                                        ref="uploader"
                                        class="d-none"
                                        type="file"
                                        accept=".xlsx, .xls, .csv, .ods, .gz, .gzip"
                                        @change="onFileChange"
                                    >
                                    <p class="mt-4 mb-0">Formats autorisés: CSV, XLS, XLSX, GZ, GZIP</p>
                                    <p class="mb-0">Taille maximum : 5Mo</p>
                                    <p class="mb-0">Restrictions: sans mot de passe, aucun filtres, uniquement 1 onglet</p>
                                    <p class="error--text errorFile mt-2" v-if="errorFile != ''">{{$t(errorFile)}}</p>
                               </div>
                            </v-card-text>
                            </v-card>
                        </v-stepper-content>
                        <v-stepper-content step="2" class="pt-0">
                            <div>
                                <v-alert color="primary" text border="left" icon="$info_s">
                                    <template v-slot:prepend>
                                        <v-icon  color="primary" class="ml-2 mr-4">
                                            $info_s
                                        </v-icon>
                                    </template>
                                    Faites correspondre les entêtes de votre fichier avec les champs correspondants
                                </v-alert>
                               <v-divider class="pb-2"></v-divider>
                                <div v-if="moreThanOneEntity" class="entityChoice px-4">
                                    <div>
                                        <p class="my-0 mr-2">Dans quel point de vente souhaitez-vous insérer ces contacts ?<span class="required ml-1">*</span></p>
                                        <div v-if="requiredError != ''" class="error--text">{{requiredError}}</div>
                                    </div>
                                    <v-autocomplete required hide-details :items="entities" class="required input-form" solo flat return-object v-model="entitiesSelected" :placeholder="$t('entityLabel')" filled dense style="max-width:50% !important;" :disabled="this.entities.length === 1 || this.entityId ? true : false" :no-data-text="$t('no-data-text')"></v-autocomplete>
                                </div>
                                <v-expansion-panels class="no-shadow">
                                    <v-expansion-panel class="no-shadow">
                                        <v-expansion-panel-header class="pl-4" style="text-decoration:underline">
                                            <p class="mb-0">+ {{$t('advancedOptions')}}</p>
                                            <template v-slot:actions>
                                                <v-icon color="darkgrey" class="mr-2">
                                                    $dropdown
                                                </v-icon>
                                            </template>    
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-checkbox v-model='mergeDoublons' dense >
                                                <template v-slot:label>
                                                    <p class="mb-0">
                                                        {{$t('mergeDoublonsFromBaseLabel')}}
                                                    </p>
                                                </template>
                                            </v-checkbox>
                                            <span class="label" v-if="mergeDoublons"><b>{{$t('DedoublonnageKeys')}}</b></span>
                                            <v-autocomplete multiple dense solo flat class="input-form" :items="select.contact" v-model="dedoublonnageKey" v-if="mergeDoublons">
                                                
                                            </v-autocomplete>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                                <v-divider class="mt-2"></v-divider>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead class="tableHead">
                                            <tr>
                                                <th class="text-left">
                                                    Extrait du fichier
                                                </th>
                                                <th class="text-left">
                                                    Champ correspondant
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                            v-for="(item, index) in extract"
                                            :key="index"
                                            class="trValues"
                                            >
                                                <td class="exampleValues">
                                                    <b>{{ index }}</b>
                                                    <p v-for="exemple in extract[index]" :key="exemple">{{ exemple }}</p>
                                                </td>
                                                <td class="selectValues input-form">
                                                    <div class="selectContent">
                                                        <v-autocomplete v-show="selectCategories.length > 1" class="mr-2" dense solo flat :value="mappingCat[index]" @input="changeFirstInput($event, index)" clearable placeholder="Catégorie de la propriété" :items="selectCategories" :no-data-text="$t('no-data-text')"></v-autocomplete>
                                                        <v-autocomplete :disabled="!mappingCat[index]" dense solo flat v-model="mapping[index]" clearable placeholder="Choix du champ (non importé si non sélectionné)" :items="select[mappingCat[index]]" :no-data-text="$t('no-data-text')" @change="modifyList">
                                                            <template v-slot:item="{ item, attrs, on }">
                                                                <v-list-item v-on="on" v-bind="attrs" v-show="item.display || item.value == mapping[index]">
                                                                    <v-list-item-content>
                                                                        <v-list-item-title>
                                                                            {{ item.text }}
                                                                        </v-list-item-title>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </template>
                                                        </v-autocomplete>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </div>
                        </v-stepper-content>
                        <v-stepper-content step="3" class="pt-0">
                            
                            <v-container fluid>
                                <v-row dense class="mx-10" >
                                    <v-col cols="10" class="reportContent pt-6">
                                        <v-alert color="primary" text border="left" icon="$info_s">
                                            <template v-slot:prepend>
                                                <v-icon  color="primary" class="ml-2 mr-4">
                                                    $info_s
                                                </v-icon>
                                            </template>
                                            Merci de bien vouloir valider les informations détectées par l'intelligence artificielle avant import des contacts dans le point de vente : <b>{{ this.entitiesSelected.text }}</b>
                                        </v-alert>
                                        <div style="display:none;">
                                            <v-checkbox v-model='createList' :label="$t('createListFromImportLabel')"></v-checkbox>
                                        </div>
                                        <v-tabs class="reportTabs" v-model="toRemapTab" ref="tabs">
                                            <v-tab
                                                v-for="(item, index) in toRemap"
                                                :key="index"
                                                class="tabheader py-4 text-left"
                                                :ref="'tab'+index"
                                            >
                                                <h2>{{ $t(index) }}</h2>
                                                <div v-if="index != 'vendors'">
                                                    <span v-for="(candidate, index) in item.candidates" v-show="getNbCandidate((typeof candidate === 'object' ? candidate.value : candidate), item) > 0" :key="index">
                                                        <div class="example"><span class="coloredNb">{{getNbCandidate((typeof candidate === 'object' ? candidate.value : candidate), item)}}</span> {{(typeof candidate === 'object' ? candidate.value : candidate)}}</div>
                                                    </span>
                                                </div>
                                                <div v-else>
                                                    <span v-for="(candidate, index) in vendorMappingNumbers" v-show="vendorMappingNumbers.length > 0" :key="'candidate_'+index">
                                                        <div class="example"><span class="coloredNb">{{candidate.nb}}</span> {{candidate.name}}</div>
                                                    </span>
                                                </div>
                                            </v-tab>
                                        </v-tabs>
                                        <v-tabs-items v-model="toRemapTab" class="pa-4 tabItem">
                                            <v-tab-item
                                                v-for="(item, index) in toRemap"
                                                :key="index"
                                            >
                                                <div v-if="index == 'vendors'" style="width: max-content;">
                                                    <span class="label"><b>{{ $t('repartitionMode') }}</b></span>
                                                    <v-select :items="['normal', 'smooth', 'force_smooth']" filled dense v-model="vendorRepartition" @change="recalculateVendorRepartition">
                                                        <template v-slot:selection="data">
                                                            <span>
                                                                {{ $t(data.item) }}
                                                            </span>
                                                        </template>
                                                        <template v-slot:item="{item, attrs, on}">
                                                            <v-list-item v-on="on" v-bind="attrs">
                                                                <v-list-item-content>
                                                                    <v-list-item-title>
                                                                        <v-row no-gutters align="center">
                                                                            {{  $t(item) }}
                                                                        </v-row>
                                                                    </v-list-item-title>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </template>
                                                    </v-select>
                                                </div>
                                                <v-simple-table v-if="index != 'vendors'">
                                                    <template v-slot:default>
                                                        <thead>
                                                            <tr>
                                                                <th>Présent dans le fichier</th>
                                                                <th>Nombre de fois</th>
                                                                <th v-for="(candidate, index) in item.candidates" :key="index">
                                                                    {{typeof candidate === 'object' ? candidate.text : candidate}}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                            v-for="(content, contentIndex) in item.values"
                                                            :key="content.text_value"
                                                            >
                                                                <td>
                                                                    <span v-if="content.text_value != ''">{{ content.text_value }}</span>
                                                                    <span v-else>{{$t("NCLabel")}}</span>
                                                                </td>
                                                                <td>
                                                                    {{ content.nb }}
                                                                </td>
                                                                <td v-for="(candidate, index) in item.candidates" :key="index">
                                                                    <div class="radio-item">
                                                                        <input type="radio" class="radioButton" :id="(typeof candidate === 'object' ? candidate.value : candidate)+'_'+contentIndex+'_'+index" :value="typeof candidate === 'object' ? candidate.value : candidate" v-model="content.mapped" :name="content.text_value+'_'+contentIndex"/>
                                                                        <label :for="(typeof candidate === 'object' ? candidate.value : candidate)+'_'+contentIndex+'_'+index"></label>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                                <v-simple-table v-else>
                                                    <template v-slot:default>
                                                        <thead>
                                                            <tr>
                                                                <th>Présent dans le fichier</th>
                                                                <th>Nombre de fois</th>
                                                                <th>
                                                                    {{ $t('repartitionVendorsLabel') }}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                            v-for="(content) in item.values"
                                                            :key="content.text_value"
                                                            >
                                                                <td>
                                                                    <span v-if="content.text_value != '' && content.text_value != null">{{ content.text_value }}</span>
                                                                    <span v-else>{{$t("NCLabel")}}</span>
                                                                </td>
                                                                <td>
                                                                    {{ content.nb }}
                                                                </td>
                                                                <td style="width:60%;">
                                                                    <v-autocomplete class="py-2" @blur="recalculateVendorRepartition" chips deletable-chips filled multiple hide-details dense v-model="content.mapped" :items="item.candidates" :no-data-text="$t('no-data-text')"></v-autocomplete>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                            </v-tab-item>
                                        </v-tabs-items>
                                        
                                    </v-col>
                                    <v-col cols="2" class="pt-0 pl-4 py-8 genericReport">
                                        <a @click="dialogDetail = true" class="mb-2">{{$t('displayCleaningImportLabel')}}</a>
                                        <div class="numberReport first mt-1">
                                            <v-icon color="white" large class="mb-1">$users_r</v-icon>
                                            <div class="number">{{ report.nbNewContacts }}</div>
                                            <span class="text">{{ $t('contactsToImport') }}</span>
                                        </div>
                                        <div class="numberReport">
                                            <v-icon color="primary" large class="mb-1">$useredit_r</v-icon>
                                            <div class="number">{{ report.nbUpdatedContacts ? report.nbUpdatedContacts : 0 }}</div>
                                            <span class="text">{{ $t('contactsUpdated') }}</span>
                                        </div>
                                        <div class="numberReport">
                                            <v-icon large color="primary" class="mb-1">$map_marker_alt_l</v-icon>
                                            <div class="number">{{ report.nbPostalAddress }}</div>
                                            <span class="text text-center">{{ $t('contactsReachableByMailing') }}</span>
                                            <span v-if="report.nbPostalAddressBlacklisted" class="text red--text">{{ $t('including_blacklisted_with_e', { nb: report.nbPostalAddressBlacklisted }) }}</span>
                                        </div>
                                        <div class="numberReport">
                                            <v-icon large color="primary" class="mb-1">$at_l</v-icon>
                                            <div class="number">{{ report.nbEmails }}</div>
                                            <span class="text text-center">{{ $t('contactsReachableByEmail') }}</span>
                                            <span v-if="report.nbEmailsBlacklisted" class="text red--text">{{ $t('including_blacklisted_with_e', { nb: report.nbEmailsBlacklisted }) }}</span>
                                        </div>
                                        <div class="numberReport">
                                            <v-icon large color="primary" class="mb-1">$phoneoffice_l</v-icon>
                                            <div class="number">{{ report.nbPhonesFixe }}</div>
                                            <span class="text">{{ $t('contactsWithFixe') }}</span>
                                        </div>
                                        <div class="numberReport">
                                            <v-icon large color="primary" class="mb-1">$mobile_l</v-icon>
                                            <div class="number">{{ report.nbPhonesMobile }}</div>
                                            <span class="text">{{ $t('contactsWithMobile') }}</span>
                                            <span v-if="report.nbPhonesMobileBlacklisted" class="text red--text">{{ $t('including_blacklisted', { nb: report.nbPhonesMobileBlacklisted }) }}</span>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-dialog width="700" v-model="dialogDetail">
                                    <v-card>
                                        <v-card-text class="pt-4 detailCard">
                                            <h3 class="primary--text">{{$t('displayCleaningImportTitle')}}</h3>
                                            <div class="mt-6 mb-2 detailNumber"><span>{{ report.nbDoublonsExistings }}</span> {{$t('nbDoublonsBDDLabel')}}</div>
                                            <div class="detailNumber mb-2"><span>{{ report.nbDoublonsUpdated }}</span> {{$t('nbDoublonsBDDUpdatedLabel')}}</div>
                                            <div class="detailNumber mb-2"><span>{{ report.nbDoublonsInFile }}</span> {{$t('nbDoublonsFileLabel')}}</div>
                                            <div class="detailNumber mb-2"><span>{{ report.nbDuplicatedLines }}</span> {{$t('nbDuplicatedLinesAndSupressedLabel')}}</div>

                                            <v-expansion-panels :disabled="report.postalAdressIncorrect == 0" :class="(report.postalAdressIncorrect == 0) ? 'mb-2' : 'mb-2 active'">
                                                <v-expansion-panel class="no-shadow px-0">
                                                    <v-expansion-panel-header :class="(report.postalAdressIncorrect == 0) ? 'px-0 py-0 pr-2 black--text' : 'px-0 pr-2 py-1 black--text'">
                                                       <div class="detailNumber"><span>{{ report.postalAdressIncorrect }}</span> {{$t('postalAdressIncorrectLabel')}}</div>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        <v-row dense class="pt-3">
                                                            <v-col cols="6" v-for="(postalAdressError, index) in postalAdressesError" :key="index">
                                                                 <span class="error--text">{{ postalAdressError.from }}</span>
                                                            </v-col>
                                                        </v-row>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                            </v-expansion-panels>

                                            <v-expansion-panels :disabled="report.emailCorrection == 0" :class="(report.emailCorrection == 0) ? 'mb-2' : 'mb-2 active'">
                                                <v-expansion-panel class="no-shadow px-0">
                                                    <v-expansion-panel-header :class="(report.emailCorrection == 0) ? 'px-0 py-0 pr-2 black--text' : 'px-0 pr-2 py-1 black--text'">
                                                       <div class="detailNumber"><span>{{ report.emailCorrection }}</span> {{$t('emailCorrectionLabel')}}</div>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        <v-row dense class="pt-3">
                                                            <v-col cols="6" v-for="(emailCorrection, index) in emailsCorrect" :key="index">
                                                                 <span class="error--text">{{ emailCorrection.from }}</span> <v-icon small>$arrowright_s</v-icon> <span class="success--text">{{ emailCorrection.to }}</span>
                                                            </v-col>
                                                        </v-row>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                            </v-expansion-panels>

                                            <v-expansion-panels :disabled="report.emailIncorrect == 0" :class="(report.emailIncorrect == 0) ? 'mb-2' : 'mb-2 active'">
                                                <v-expansion-panel class="no-shadow px-0">
                                                    <v-expansion-panel-header :class="(report.emailIncorrect == 0) ? 'px-0 pr-2 py-0 black--text' : 'px-0 pr-2 py-1 black--text'">
                                                       <div class="detailNumber"><span>{{ report.emailIncorrect }}</span> {{$t('emailIncorrectLabel')}}</div>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        <v-row dense class="pt-3">
                                                            <v-col cols="6" v-for="(emailError, index) in emailsError" :key="index">
                                                                 <span class="error--text">{{ emailError.from }}</span> 
                                                            </v-col>
                                                        </v-row>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                            </v-expansion-panels>

                                            <v-expansion-panels :disabled="report.phoneCorrection == 0" :class="(report.phoneCorrection == 0) ? 'mb-2' : 'mb-2 active'">
                                                <v-expansion-panel class="no-shadow px-0">
                                                    <v-expansion-panel-header :class="(report.phoneCorrection == 0) ? 'px-0 pr-2 py-0 black--text' : 'px-0 pr-2 py-1 black--text'" >
                                                       <div class="detailNumber"><span>{{ report.phoneCorrection }}</span> {{$t('phoneCorrectionLabel')}}</div>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        <v-row dense class="pt-3">
                                                            <v-col cols="6" v-for="(phoneCorrection, index) in phonesCorrect" :key="index">
                                                                 <span class="error--text">{{ phoneCorrection.from }}</span> <v-icon small>$arrowright_s</v-icon> <span class="success--text">{{ phoneCorrection.to }}</span>
                                                            </v-col>
                                                        </v-row>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                            </v-expansion-panels>

                                            <v-expansion-panels :disabled="report.phoneIncorrect == 0" :class="(report.phoneIncorrect == 0) ? 'mb-2' : 'mb-2 active'">
                                                <v-expansion-panel class="no-shadow px-0">
                                                    <v-expansion-panel-header :class="(report.phoneIncorrect == 0) ? 'px-0 py-0 pr-2 black--text' : 'px-0 pr-2 py-1 black--text'">
                                                       <div class="detailNumber"><span>{{ report.phoneIncorrect }}</span> {{$t('phoneIncorrectLabel')}}</div>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        <v-row dense class="pt-3">
                                                            <v-col cols="6" v-for="(phoneError, index) in phonesError" :key="index">
                                                                 <span class="error--text">{{ phoneError.from }}</span> <v-icon small>$arrowright_s</v-icon> <span class="success--text">{{ phoneError.to }}</span>
                                                            </v-col>
                                                        </v-row>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                            </v-expansion-panels>
                                        </v-card-text>
                                    </v-card>
                                </v-dialog>
                            </v-container>
                            
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-card-text>
        </v-card>

        <Loader v-if="showLoader"></Loader>
    </v-dialog>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
import vCustomSelect from '@/components/forms/inputs/v-custom-select'
import Loader from '@/components/Loader.vue'
export default {
    name: "importContactComponent",
    props: ["displayUpload", "operationId", "entityId", "returnData"],
    components: {vCustomSelect, Loader},
    data() {
        return {
            publicPath: process.env.BASE_URL,
            titles: ['fileImportContactTitle', 'columnsConnectionTitle', 'validationTitle'],
            title: "IMPORT DU FICHIER",
            stepper: 1,
            dragover:false,
            moreThanOneEntity: true,
            entities: [],
            entitiesSelected: "",
            contactTypeSpecified:false,
            contactType:"",
            toRemapTab: 0,
            requiredError: "",
            errorFile: "",
            valid:false,
            loading: false,
            fileToUpload: {},
            toRemap:{},
            report: {},
            select: {},
            fields:[],
            extract: {},
            mappingCat: {},
            mapping: {},
            token:"",
            dialogDetail: false,
            vendorsValuesClone: [],
            createList: true,
            showLoader: false,
            mergeDoublons:true,
            dedoublonnageKey:['contact.email'],
            vendorRepartition:'normal',
            vendorMappingNumbers:[],
        }
    },
    mounted(){
        if(this.$refs.tab0){
            const initial = this.$refs.tab0[0].$el.offsetWidth;
            this.interval = setInterval(() => {
            if (this.$refs.tab0[0]) {
                if (this.$refs.tab0[0].$el.offsetWidth !== initial) {
                    clearInterval(this.interval)
                    this.$refs.tabs.callSlider()
                }
            } else {
                clearInterval(this.interval)
            }
            }, 1000)
        } else {
            clearInterval(this.interval)
        }

        if(this.entityId)
            this.entitiesSelected = {'value': this.entityId};
    },
    computed: {
        selectCategories() {
            return Object.keys(this.select).map(function (item) { return {value: item, text: this.$t(item)} }, this);
        },
        vendorsValues() {
            if(this.toRemap && this.toRemap.vendors && this.toRemap.vendors.values){
                return this.toRemap.vendors.values
            }
        },
        postalAdressesError() {
            if(this.report.postalAdressesError){
                return this.report.postalAdressesError.slice(0, 9)
            }
        },
        emailsCorrect() {
            if(this.report.emailsCorrect){
                return this.report.emailsCorrect.slice(0, 9)
            }
        },
        emailsError() {
            if(this.report.emailsError){
                return this.report.emailsError.slice(0, 9)
            }
        },
        phonesError() {
            if(this.report.phonesError){
                return this.report.phonesError.slice(0, 9)
            }
        },
        phonesCorrect()  {
            if(this.report.phonesCorrect){
                return this.report.phonesCorrect.slice(0, 9)
            }
           
        },
        fieldVendors() {
            let field = {
                addForm: {
                    formTitle: 'userCreateNew',
                    formUrl: "entity/{{entity_id}}/getUserForm",
                },
                component:"VCustomSelect",
                props: {
                    dense:true,
                    filled:true,
                    multiple:true,
                    required:true,
                    items:false
                },
                selectDataUrl:"/contact/_getSelectList?forField=assignment_user_id&filter[entity_id]={{entity_id}}",
                title:"contactAssignment_user_idTitle",

            }
            return field
        },
        fieldName() {
            return "assignment_user_id"
        },
        model(){
            let model = {
                assignment_user_id: [],
                entity_id: this.entitiesSelected
            }
            return model
        },
        getNbVendors(id, item){
            return function (id, item) {
                var finalNb = 0
                if(id != 0){
                    item.values.forEach((element, index) => {
                        if(element.mapped.includes(0)){
                            // if(index == 0 && parseInt(element.nb)%(item.candidates.length - 1) > 0){
                            //     finalNb += parseInt(element.nb)%(item.candidates.length - 1)
                            // }
                            let number = parseInt(element.nb) / (item.candidates.length - 1)
                            finalNb += Math.floor(number)
                        } else if(element.mapped.includes(id)) {
                            let number = parseInt(element.nb) / element.mapped.length
                            finalNb += number
                        }
                    });
                }
                
                return Math.ceil(finalNb);
                
            };
        },
        getNbCandidate(candidate, item){
            return function (candidate, item) {
                let nb = 0
                item.values.forEach((value) => {
                    if(value.mapped == candidate){
                        nb += parseInt(value.nb)
                    }
                })
                return Math.ceil(nb);  
            };
        }
    },
    watch: {
        displayUpload(val) {
            if(!val){
                this.fileToUpload = {}
                this.loading = false
            }
        },
        display2B(newVal){
            if(newVal){
                this.contactType = "CLIENTS"
            }
        },
        vendorsValues: {
            // This will let Vue know to look inside the array
            deep: true,
            // We have to move our method to a handler field
            handler(val){
                if(val && val.length){
                    for (let index = 0; index < val.length; index++) {
                        let newElement = val[index];
                        let oldElement = this.vendorsValuesClone[index];
                        this.checkForAll(oldElement, newElement)
                    }
                    this.vendorsValuesClone = this._.cloneDeep(this.vendorsValues)
                }
            }
        }
    },  
    methods: {
        changeFirstInput(value, index) {
            let tmpMapp = this._.cloneDeep(this.mappingCat);
            tmpMapp[index] = value;
            this.mappingCat = tmpMapp;
            if(value == null)
                this.mapping[index] = false;
            this.modifyList();
        },
        checkForAll(oldElement, newElement){
            if((oldElement && newElement) && (oldElement.mapped && newElement.mapped) && !this._.isEqual(oldElement.mapped, newElement.mapped)){
                if(oldElement.mapped.includes(0)){
                    newElement.mapped = newElement.mapped.filter((e) => e != 0)
                } else {
                    if(newElement.mapped.includes(0)){
                        newElement.mapped = newElement.mapped.filter((e) => e == 0)
                    }
                }
            }
        },
        modifyList() {
            for(const property in this.select) {
                this.select[property].forEach(element => {
                    element.display = true;

                    if(Object.values(this.mapping).includes(element.value))
                        element.display = false;
                });
            }
        },
        getValue: function(number, total){
            return Math.round((number*100)/ total)
        },
        onDrop(e){
            this.dragover = false;
            this.errorFile = ""
            this.fileToUpload = {}
            if (e.dataTransfer.files.length == 1) {
                e.dataTransfer.files.forEach(element => {
                        this.uploadFileForMapping(element);
                   }
                );
            }
        },
        onFileChange() {
            this.errorFile = ""
            this.fileToUpload = {}
            let file = this.$refs.uploader.files[0]
            if (!file)
                return;
            
            this.uploadFileForMapping(file);
        },
        uploadFileForMapping(file){
            if(file.type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                && file.type != "application/vnd.ms-excel"
                && file.type != 'text/csv'
                && file.type != 'text/plain'
                && file.type != 'text/tab-separated-values'
                && file.type != 'application/gzip'
                && file.type != 'application/x-gzip'
                && file.type != 'application/vnd.oasis.opendocument.spreadsheet'){
                    this.errorFile = 'wrongFormatError'
                } else if(file.size > (5 * 1024 * 1024)) {
                    this.errorFile = 'fileTooBigError'
                }else {
                    this.loading = true
                    this.fileToUpload = file;
                    const reader = new FileReader
                    reader.onload = e => {
                        let jsonData = {
                            'name': this.fileToUpload.name,
                            'base64': e.target.result
                        };
                        GenericDataService.postData("/fileimport/upload", jsonData).then((response) => {
                            this.mapping = response.data.data.mapping

                            for (const [key, value] of Object.entries(this.mapping)) {
                                this.mappingCat[key] = value ? value.split(/\.(.*)/s)[0] : false;
                            }

                            this.extract = response.data.data.extract
                            this.fields = response.data.data.fields
                            //remapping select
                            this.fields.forEach((element) => {
                                let cat = element.split(/\.(.*)/s)[0];

                                let item = {
                                    value:element,
                                    text: element.includes('custom') ? ('Perso : ' + this.$t(element.split('.')[element.split('.').length - 1])) : this.$t(element),
                                    display:true
                                }

                                if(!this.select[cat])
                                    this.select[cat] = [];
                                this.select[cat].push(item)
                            });

                            if(this.selectCategories.length === 1) {
                                for (const [key, value] of Object.entries(this.mappingCat)) {
                                    this.mappingCat[key] = this.selectCategories[0].value;
                                }
                            }

                            this.modifyList();
                            this.token = response.data.data.token
                            let url = "";
                            if(this.operationId){
                                url = 'operation/' + this.operationId + '/getEntitySelectList2'
                            } else {
                                url = "entity/getSelectList2"
                            }
                            GenericDataService.getData(url).then((response) => {
                                this.entities = response.data.data;
                                this.loading = false;
                                this.stepper++;
                                this.showLoader = false;

                                if(this.entities.length === 1)
                                    this.entitiesSelected = this.entities[0];

                                //this.$forceUpdate()
                            }, (raison) => {
                                this.showLoader = false;
                            })
                        });
                    }
                    reader.readAsDataURL(this.fileToUpload)
                }
        },
        nextTab() {
            this.showLoader = true;
            if(this.stepper == 2){
                if(this.entitiesSelected != ''){
                    this.processMapping();
                } else  {
                    this.requiredError = this.$t("requiredFieldError")
                    this.showLoader = false;
                    //this.$store.dispatch('ADD_ERROR', {errorMessage : "entityFieldIsRequired"});
                }
            } else if(this.stepper == 3){
                this.remapFile()
            } else {
                this.stepper++;
                this.showLoader = false;
            }
        },
        remapFile(){
            let payload = {
                token: this.token,
                entity_id: this.entitiesSelected.value,
                toRemap: this.toRemap,
                method: this.vendorRepartition,
                createList : this.createList
            }
            if(this.$route.params.operation_id)
                payload.operation_id = this.$route.params.operation_id

            GenericDataService.postData("/fileimport/remap", payload).then((response) => {
                this.stepper = 1;
                this.showLoader = false;
                this.dragover = false
                this.fileToUpload = {}
                this.errorFile = ""
                this.toRemap = {}
                this.report = {}
                this.select = []
                this.fields = []
                this.extract = {}
                this.mapping = {}
                this.token = "",
                this.vendorsValuesClone = [],
                this.$refs.uploader.value = null
                if(this.$route.params.operation_id || this.returnData)
                   this.$emit('closeModal', response.data.data )
                else
                   this.$emit('closeModal', true )
            }, (reject) => {
                this.showLoader = false;
            })
        },
        processMapping(){
            let mappingToSend = {};
            for(const property in this.mapping){
                if(this.mapping[property] !== false){
                    mappingToSend[property] = this.mapping[property]
                }
            }
            let payload = {
                mapping: mappingToSend,
                token: this.token,
                entity_id: this.entitiesSelected.value,
                operation_id: this.operationId,
                type: this.contactType,
                merge: this.mergeDoublons,
            }
            if(this.mergeDoublons){
                payload.mergeKeys = this.dedoublonnageKey
            }
            GenericDataService.postData("/fileimport/map", payload).then((response) => {
                this.toRemap = response.data.data.toRemap;
                this.report = response.data.data.rapport;
                if(this.toRemap.vendors) {
                    if(this.toRemap.vendors.candidates && this.toRemap.vendors.candidates.length) {
                        this.toRemap.vendors.candidates[0].text = this.$t(this.toRemap.vendors.candidates[0].text);
                    }
                    this.vendorsValuesClone = this._.cloneDeep(this.toRemap.vendors.values);
                    let payloadVendors = {
                        token: this.token,
                        entity_id: this.entitiesSelected.value,
                        method: this.vendorRepartition,
                        vendors: this.toRemap.vendors.values,
                        operation_id: this.operationId
                    }
                    GenericDataService.postData("/fileimport/getRemapVendors", payloadVendors).then((response)=> {
                        this.stepper++;
                        this.vendorMappingNumbers = response.data.data;
                        this.showLoader = false;
                    });
                } else {
                    this.stepper++;
                    this.showLoader = false;
                }
            }, (reject) => {
                this.showLoader = false;
            })
        },
        recalculateVendorRepartition(){
            this.showLoader = true
            let payloadVendors = {
                token: this.token,
                entity_id: this.entitiesSelected.value,
                method: this.vendorRepartition,
                vendors: this.toRemap.vendors.values,
                operation_id: this.operationId
            }
            GenericDataService.postData("/fileimport/getRemapVendors", payloadVendors).then((response)=> {
                this.vendorMappingNumbers = response.data.data
                this.showLoader = false;
            })
        },
        goBack(){
            this.stepper--;
            this.showLoader = false;
        },
        selectFile() {
            this.$refs.uploader.click()
        },
        
        cancelUpload(){
            this.stepper = 1;
            this.showLoader = false;
            this.dragover = false
            this.fileToUpload = {}
            this.errorFile = ""
            this.toRemap = {}
            this.report = {}
            this.select = []
            this.fields = []
            this.extract = {}
            this.mapping = {}
            this.token = "",
            this.vendorsValuesClone = [],
            this.$refs.uploader.value = null
            this.$emit('closeModal', false)
        }
    },
}
</script>
<style lang="scss">
    .headerStepper {
        .v-stepper__label {
            text-align: center;
        }
    }
    .v-stepper__items {
        overflow:auto !important;
    }
    .v-stepper__content {
        min-height:100% !important;
    }
    .v-stepper__wrapper {
        height:100% !important;
    }
    .entityChoice {
        display:flex;
        justify-content:space-between;
        align-items: center;
    }
    .required {
        color:red;
    }
    .cardUpload {
        border: 2px dashed #999;
        height:100%;
        display:flex;
        align-items: center;
        justify-content: center;
        .uploadContent {
            display:flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .cloudIcon {
                font-size: 70px !important;
                height: 70px !important;
                width: 70px !important;
                svg {
                    font-size: 70px !important;
                    height: 70px !important;
                    width: 70px !important;
                }
            }
            h1 {
                font-size:20px;
            }
             .errorFile {
                 font-size:18px;
                color:red;
            }
        }
       
        
    }
    .realTimeNumbers {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        padding:20px;
        .number {
            font-size:55px;
            line-height:55px;
            font-weight:bold;
        }
    }
    .headingDetail {
            display:flex;
            justify-content: space-between;
            align-items: center;
        }
    .reportContent {
        // border-radius:5px;
        // border: 1px solid #CCC;
        padding:20px !important;
        h2 {
            min-height: 18px;
        }
        tr {
            td {
                height:45px !important;
                font-size:13px !important;
            }
        }
    }
    .exampleValues {
        b {
            margin-bottom:4px !important;
        }
        p {
            margin-bottom: 0 !important;
            font-size: 12px !important;
            line-height: 15px !important;
        }
    }
    .trValues {
        margin-top:20px !important;
        height:75px;
    }
    .selectValues {
        .selectContent {
            display:flex;
            align-items: center;
        }
        
    }
    .tab2B {
        border-radius:5px;
        border: 1px solid #CCC;
        min-height:200px; 
        padding:50px;
        display:flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        p {
            margin-bottom:5px;
        }
    }
    .reportTabs {
        .v-tabs-bar {
             height:unset !important;
            //min-height: 110px;
        }
        .v-tabs-bar__content {
            min-height:40px;
            height: unset !important;
        }
        .v-tabs-slider-wrapper {
            bottom:unset !important;
            top:0px !important;
        }
        .tabheader {
            flex-direction: column;
            align-items:flex-start;
            justify-content: space-between;
            width:100%;
            border: 1px solid #DDD;
            text-transform: unset;
            border-right-width: 0px;
            border-bottom-width: 0px;
            &:last-child{
                border-right-width: 1px;
            }
            background-color: #EEE !important;
            &.v-tab--active{
                background-color: #FFF !important;
            }
            .example {
                color: rgba(0, 0, 0, 0.54)
            }
            .coloredNb {
                 color: var(--v-primary-base) !important;
                 margin-right:5px;
            }
        }
        
    }
    .tabItem {
        .v-tabs-items {
            border: 1px solid #DDD;
            .radio-item {
                //display: block;
                position: relative;
                padding: 0 6px;
                margin: 0;
                height:20px;
            }

            .radio-item input[type="radio"] {
                display: none;
            }

            .radio-item label {
                color: #666;
                font-weight: normal;
                height: 20px;
            }

            .radio-item label:before {
                content: " ";
                display: inline-block;
                position: relative;
                top: 0px;
                margin: 0 ;
                width: 20px;
                height: 20px;
                border-radius: 11px;
                border: 2px solid #004c97;
                background-color: transparent;
            }

            .radio-item input[type="radio"]:checked + label:after {
                border-radius: 11px;
                width: 12px;
                height: 12px;
                position: absolute;
                top: 4px;
                left: 10px;
                content: " ";
                display: block;
                background: #004c97;
            }
        }
    }
    
    .tableHead {
        tr {
            th {
                border-bottom-width:2px !important;
            }
        }
    }
    .detailCard {
        .detailNumber {
            color: black;
            font-size: 15px;
            span {
                margin-left:5px;
                color: var(--v-primary-base) !important;
                font-weight: bold;
            }
        }
        .v-expansion-panel-header {
            min-height:18px !important;
        }
    }
    .genericReport {
        a {
            text-decoration:underline;        
        }
        .numberReport {
            height:150px;
            border: 1px solid #DDD;
            border-bottom-width:0px;
            display:flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .number {
                font-size:32px;
                line-height: normal;
                font-weight: bold;
            }
            .text {
                text-decoration: uppercase;
            }
            &:last-child {
                border-bottom-width:1px;
            }
            &.first {
                color:white;
                background-color: var(--v-primary-base) !important;
            }
            
        }
    }
    .v-expansion-panels.active {
        .v-expansion-panel {
            .v-expansion-panel-header {
                border-radius:0;
                background-color: var(--v-lightgrey-base) !important;
                border-radius:5px;

            }
        }
    }
</style>