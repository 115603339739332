<template>
    <span class="mx-2">
        <v-tooltip right content-class="white--tooltip" max-width="400px">
            <template v-slot:activator="{ on, attrs }">
                <v-icon dense color="info" v-bind="attrs" v-on="on">$circleinfo_d</v-icon> 
            </template>
            <span>
                <span><b>{{ $t(tooltip.title) }}</b></span><br>
                <span v-html="$t(tooltip.description)"></span>
            </span>
        </v-tooltip>
    </span>
</template>

<script>
export default {
    name: "infoTooltip",
    props: ["tooltip"],
    data() {
        return {}
    },
}
</script>

<style lang="scss">
</style>